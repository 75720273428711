@if (img && showImg) {
  <app-mi-image-container class="img-preview" [miImage]="img"  miWidth="170" id="preview"></app-mi-image-container>
}

@if (docUrl) {
  <a [href]="docUrl" target="myIframe"><label class="view-doc" translate>view_document</label></a>
}

<form [formGroup]="formName">
  <p-fileUpload
    class="cursor-pointer"
    mode="advanced"
    [chooseLabel]="chooseLabel"
    (onSelect)="onUpload($event)"
    customUpload="true"
    [accept]="accept"
    [maxFileSize]="maxFileSize"
    [multiple]="isMultipleFilesUpload"
    [showCancelButton]="false"
    [showUploadButton]="false"
    [chooseIcon]="chooseIcon"
  >
    <ng-template pTemplate="content" let-files>
      <div class="drop-area">
        {{ isDocumentModule ? dropAreaMsgForDocument : dropAreaMsg }}
      </div>
    </ng-template>
  </p-fileUpload>
</form>

<div>
  <label class="file-upload-instruction" translate>
    {{ isDocumentModule ? 'lbl_img_pdf_upload_instruction' : 'lbl_img_upload_instruction' }}
  </label>
</div>

@if (uploadedFiles.length > 0) {
  <div class="col-{{field.uiColumns === 12 ? 6 : 12}} file-list-div pl-0">
    <div class="card list">
      <div class="col-12 p-0 file-list px-2 py-2">
        <label>Uploaded Files</label>
      </div>
      <div class="grid flex ml-2 mr-2">
        @for (file of uploadedFiles; track file) {
          <div [class]="{'col-12': moduleName === entityType.GENERAL_SETTINGS, 'col-6': moduleName !== entityType.GENERAL_SETTINGS}" class="inline-flex mt-3 mb-2">
            <div class="flex justify-content-between image-details w-full">
              <div class="flex justify-content-start">
                <div class="flex align-items-center ml-2">
                  <img [src]="'../../../../../../assets/images/mi-theme/light/basic/' + (file?.file?.type === 'application/pdf' ? 'pdf-logo.svg' : 'image.svg')">
                </div>
                <div class="flex align-items-center ml-2">
                  <label>{{ file.file.name }}{{ !isDocumentModule ? ' - ' + file.file.size + ' bytes' : '' }}</label>
                </div>
              </div>
              <div class="flex justify-content-between align-items-center ml-2">
                <img (mouseover)="showCloseImageIcon()" (mouseout)="showCheckImageIcon()" src="../../../../../../assets/images/mi-theme/light/basic/basic_circle_check.svg">
                <img (mouseover)="showCloseImageIcon()" (mouseout)="showCheckImageIcon()" (click)="removeImage(file)" class="close-icon" src="../../../../../../assets/images/mi-theme/light/basic/off_close.svg">
                @if (isDocumentModule) {
                  <div class="cursor-pointer mr-1 mt-1" (click)="downloadDocument(file)">
                    <i class="{{miIcons.DOWNLOAD}} wh-18 inline-block bg-default-text-blue"></i>
                  </div>
                }
              </div>
            </div>
          </div>
        }
      </div>
    </div>
  </div>
}

<p-toast key="fileupload" position="top-right"></p-toast>
<input style="display: none;" type="text" pInputText [formControlName]="field?.isOneToMultiple ? field?.attributeCodeOneToMultiple : field.attributeCode" class="w-full"/>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';
import { AppIcons } from '../../app.icons';
import { DatePipe } from '@angular/common';
import { AppSettings } from '../../app.settings';
import { MetaDataService } from '../../services/meta-data.service';

@Component({
  selector: 'app-module-header',
  templateUrl: './module-header.component.html',
  styleUrls: ['./module-header.component.scss'],
  standalone: true,
  imports: [ButtonModule, MenuModule, DatePipe],
  providers: [DatePipe]
})
export class ModuleHeaderComponent implements OnInit {
  miIcons = AppIcons;
  @Input() label: string;
  @Input() lastSync: Date = new Date();
  @Input() moduleHeaderSecondaryBtnMenuItems: MenuItem[] = [];
  @Input() showImportExportBtn: boolean = true;
  @Input() showLastSync: boolean = false;
  @Input() secondaryBtnLabel: any;
  @Input() showSecondaryBtn: boolean = false;
  @Input() isBtnDisabled: boolean = false;
  lastSyncTime: any;
  HOUR_MINUTE_FORMAT = this.metaDataService.timeHourFormat;

  @Output() onBtnClick: EventEmitter<any> = new EventEmitter();
  @Output() onSecondaryBtnClickEvent: EventEmitter<any> = new EventEmitter();

  constructor(private router: Router, private metaDataService: MetaDataService,) {

  }

  ngOnInit() {
    this.lastSyncTime = this.lastSync.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  }

  onAdd() {
    this.onBtnClick.emit();
  }

  onSecondaryBtnClick() {
    this.onSecondaryBtnClickEvent.emit();
  }
}

<app-mi-table
    [collectionOfColumns]="allColumns"
    [attributeLabels]="attributeLabels"
    [data]="tableData"
    [isLoading]="isLoading"
    entityName="booking"
    [totalRecords]="totalRecords"
    (onSearchValueChange)="onSearchValueChanges($event)"
    (onPageChange)="onPageChange($event)"
    (itemPerPageChange)="itemPerPageChange($event)"
    [label]="addBtnLabel"
    (onBtnClick)="onAddAssignment()">
</app-mi-table>

import { AsyncPipe } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import * as _ from 'lodash';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TabViewModule } from 'primeng/tabview';
import { forkJoin } from 'rxjs';
import { FilterResponseModal } from '../../../manage-groups/models/filterResponse.models';
import { AppIcons } from '../../../shared/app.icons';
import { AppSettings } from '../../../shared/app.settings';
import { MITableComponent } from '../../../shared/components/mi-table/mi-table.component';
import { ModuleHeaderComponent } from '../../../shared/components/module-header/module-header.component';
import { Country } from '../../../shared/models/country';
import { Language } from '../../../shared/models/language';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { ConfigService } from '../../../shared/services/config.service';
import { EntityService } from '../../../shared/services/entity.service';
import { EntityList } from '../../../vehicles/models/entity.models';
import { ListResponse, Values } from '../../../vehicles/models/listResponse.models';

@Component({
  selector: 'app-queue-list',
  standalone: true,
  imports: [BreadcrumbModule, ModuleHeaderComponent, TabViewModule, TranslateModule, MITableComponent, AsyncPipe],
  templateUrl: './queue-list.component.html',
  styleUrl: './queue-list.component.scss'
})
export class QueueListComponent implements OnInit {
  @ViewChild(MITableComponent) tableRef: MITableComponent;
  activeIndex: number = 0;
  isLoading = true;
  filterAvailableColumns = [];
  @Input() moduleName: string;
  filterList: {};
  btnLabel: string;
  attributeLabels = {};
  filterAttributeLabels = {};
  listColumns: Values;
  allColumns: any;
  pagination: any;
  tableData: any = [];
  country: Country;
  language: Language;
  showImportExportBtn: boolean = false;
  entityData: EntityList = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: "",
    filters: [],
    countryCode: '',
    deleted: AppSettings.DELETED_TYPE.ONLY_NON_DELETED,
    forTenantCode: this.configService.getForTenantId(),
    actionStatus: ''
  };
  routePath: MenuItem[] = [];
  deleteQueueMsg: string;
  deleteQueueHeaderMsg: string;
  removeLabel: string = this.cs.getLabel('queue.remove_driver');
  removeDriversLabel: string = this.cs.getLabel('queue.remove_drivers');
  filterFieldsArray: any[];
  attributeData: any;
  data: any;
  rowItems: MenuItem[] | undefined;
  currentRow: any;
  visible: boolean = false;
  totalRecords: number = 0;
  attributeIdOfGroupStatus: string;
  bulkSelectionsItems: MenuItem[] | undefined;
  queueLocationList;
  queueLocations;
  locationFilters;
  locationEntityAttributeId;
  categoryAttributeIdInLocationFilter;
  entityDataForQueueLocation = {
    limit: 2000,
    offset: 0,
    searchStr: '',
    filters: [],
    forTenantCode: this.configService.getForTenantCode(),
    deleted: AppSettings.DELETED_TYPE.ONLY_NON_DELETED,
    countryCode: ''
  }

  constructor(private queueManagementService: EntityService,
    private configService: ConfigService,
    private cs: CommonBindingDataService, private confirmationService: ConfirmationService, private cd: ChangeDetectorRef,
    private router: Router, private messageService: MessageService) { }

  ngOnInit() {
    this.setBreadcrumb();
    this.setRowItems();
    this.bulkUpdateItems();
    this.setLabels();
    this.getTableView();
    this.getFilterViewForLocation();
  }

  private setBreadcrumb() {
    this.routePath = [{ label: this.cs.getLabel('queue.lbl_outbound_queue') }];
  }

  public setRowItems() {
    this.rowItems = [
      {
        label: this.removeLabel,
        icon: AppIcons.TRASH_FULL + " wh-16",
        iconClass: "bulk-update-icon",
        command: () => {
          this.tableRef.delete(AppSettings.DELETE_TYPE.ROW);
        },
      },
    ];
  }

  private bulkUpdateItems() {
    this.bulkSelectionsItems = [
      {
        label: this.removeDriversLabel,
        icon: AppIcons.BASIC_TRAY + " wh-122",
        iconClass: "bulk-update-icon",
        styleClass: "bulk-update-list",
        command: (e) => {
          const event = _.cloneDeep(e);
          this.tableRef.delete(AppSettings.DELETE_TYPE.BULK);
        },
      },
    ];
  }

  public setLabels() {
    this.btnLabel = this.cs.getLabel('queue.lbl_add_queue');
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    this.deleteQueueMsg = this.cs.getLabel('queue.message.confirm_delete_msg');
    this.deleteQueueHeaderMsg = this.cs.getLabel('queue.message.confirm_delete_header');
  }

  delete(event: Event, rowData: any, deleteType: string) {
    this.confirmationService.confirm({
      header: this.deleteQueueHeaderMsg,
      message: this.deleteQueueMsg,
      rejectButtonStyleClass: 'bg-white text-color',
      acceptButtonStyleClass: 'bg-red-500',
      accept: ($event) => {
        if (deleteType === 'row') {
          this.onQueueDelete(this.currentRow.id);
        } else {
          this.onBulkDataDeleteEvent(
            this.currentRow.map((ele) => {
              return ele.id;
            })
          );
        }
      },
      reject: () => {
      }
    });
  }

  onBulkDataDeleteEvent(event) {
    const requestBody = {
      forTenantCode: this.configService.getForTenantId(),
      countryCode: this.country[0].countryCode,
      entityCode: AppSettings.ENTITY_CODE.QUEUE,
      entityIds: event
    };
    this.queueManagementService.deleteEntityActionStatus(requestBody).subscribe((response) => {
      this.searchEntity();
    });
  }

  getTableView() {
    this.queueManagementService.getAttributeDefinition(AppSettings.ENTITY_CODE.QUEUE, AppSettings.VIEW_CODE.DEFAULT_TABLE_VIEW).subscribe(res => {
      if (res) {
        this.isLoading = false;
        this.data = res;
        this.locationEntityAttributeId = this.cs.getAttributeId(AppSettings.LOCATION_FILTER_ATTRIBUTES.LOCATION_ENTITY_ID, this.data);
        this.attributeIdOfGroupStatus = res?.tabs[0]?.groups[0]?.fields?.find(element => element.attributeCode === 'group_status')?.attributeId;
        this.attributeLabels = this.cs.getAttributeLabels(this.data);
        this.allColumns = this.cs.getColumns(this.data);
        this.filterAvailableColumns = _.clone(this.allColumns);
        this.allColumns?.forEach((key, index) => {
          this.allColumns[index] = key;
          this.allColumns[index].field = key.attributeCode;
          this.allColumns[index].header = this.cs.getLabelValue(
            "queue" + ".fields." + key.attributeCode + ".label",
            this.attributeLabels,
            key.attributeCode
          );
        });
        this.filterAvailableColumns = _.clone(this.allColumns);
      }
    });
    this.searchEntity();
  }

  getFilterViewForLocation() {
    this.queueManagementService.getAttributeDefinition(AppSettings.ENTITY_CODE.LOCATION, AppSettings.VIEW_CODE.ADVANCED_FILTER_VIEW).subscribe(filterResponse => {
      if (filterResponse) {
       this.locationFilters = filterResponse;
       const fields = this.locationFilters?.tabs[0]?.groups[0]?.fields;
       const categoryField = fields ? fields.find(ele => ele.attributeCode === AppSettings.LOCATION_FILTER_ATTRIBUTES.LOCATION_CATEGORY) : [];
       this.categoryAttributeIdInLocationFilter = categoryField ? categoryField.attributeId : null;
       if(this.categoryAttributeIdInLocationFilter) {
         this.searchQueueLocation();
       }
      }
    })
  }

  searchQueueLocation(){
    this.entityDataForQueueLocation.countryCode = this.country[0].countryCode;
    this.entityDataForQueueLocation.filters = [{
      attributeId: this.categoryAttributeIdInLocationFilter,
      attributeValue: [AppSettings.ATTRIBUTE_VALUES_FOR_LOCATION_CATEGORY.QUEUE]
    }];

    this.queueManagementService.searchEntity(AppSettings.ENTITY_CODE.LOCATION, this.entityDataForQueueLocation).subscribe((result: any) => {
      this.queueLocations = result.data;
      this.filterQueueLocation();
    })
  }

  filterQueueLocation() {
    this.queueLocationList = [];
    this.queueLocations.forEach(element => {
      this.queueLocationList.push({
        labelKey: element.values.location_display_name_for_booker,
        labelValue: element.id
      });
      this.getFilterView();
    });
  }

  getFilterView() {
    this.queueManagementService.getAttributeDefinition(AppSettings.ENTITY_CODE.QUEUE, AppSettings.VIEW_CODE.ADVANCED_FILTER_VIEW).subscribe((filterResponse: FilterResponseModal) => {
      if (filterResponse) {
        this.filterList = [
          {
            "attributeCode": "Queue Location",
            "attributeId": this.locationEntityAttributeId,
            "attributeIndex": 0,
            "presetValues": this.queueLocationList,
          }
        ]
      }
    })
  }

  searchEntity() {
    this.entityData.countryCode = this.country[0].countryCode;
    this.queueManagementService.searchEntity(AppSettings.ENTITY_CODE.QUEUE, this.entityData).subscribe((res: ListResponse) => {
      this.listColumns = res?.data[0]?.values;
      this.pagination = res?.pagination;
      this.totalRecords = res?.count;
      this.tableData = [];
      this.tableData = res.data?.map(data => {
        const relatedData = data?.relatedData || [];
        const groupedData = _.groupBy(relatedData, 'entityCode');

        const relatedDriver = (groupedData['driver'] || []).map(related => ({
          id: related.id,
          ...related.values
        }));

        const relatedVehicle = (groupedData['vehicle'] || []).map(related => ({
          id: related.id,
          ...related.values
        }));

        const relatedDriverGroup = (groupedData['driver_group'] || []).map(related => ({
          id: related.id,
          ...related.values
        }));

        const relatedLocation = (groupedData['location'] || []).map(related => ({
          id: related.id,
          ...related.values
        }));

        return {
          id: data.id,
          ...data.values,
          driver: relatedDriver,
          vehicle: relatedVehicle,
          driverGroup: relatedDriverGroup,
          location: relatedLocation
        };
      }) || [];
    })
  }

  onAddQueue() {
    this.router.navigate(['app/queue/add',]);
  }

  onPageChange(event) {
    this.entityData.offset = event?.first;
    this.entityData.limit = event?.rows;
    this.searchEntity();
  }

  itemPerPageChange(event) {
    this.entityData.limit = event;
    this.searchEntity();
  }

  onQueueDelete(event) {
    const country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    const requestBody = {
      forTenantCode: this.configService.getForTenantCode(),
      countryCode: country[0].countryCode,
      entityCode: AppSettings.ENTITY_CODE.QUEUE,
      entityIds: [
        event
      ]
    };
    this.queueManagementService.deleteEntityActionStatus(requestBody).subscribe(response => {
      this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel('queue.message.queue_delete_success') });
      this.searchEntity();
    })
  }

  onFilterValueChange(event) {
    const queueData = event;
    this.entityData.filters = [];
    for (const [key, value] of Object.entries(queueData)) {
      const attributeValue = value;
      if (attributeValue) {
        this.entityData.filters.push({
          attributeCode: key,
          attributeValue
        });
      }
    }
    this.entityData.filters = _.filter(_.uniq(this.entityData.filters, function(item, key, a) {
      if (item.attributeValue.length > 0) {
        return item.attributeCode;

      }
    }), function(element) {
      if (element.attributeValue.length > 0) {
        return true;
      }
      return false;
    });
    this.entityData.offset = event.first;
    this.entityData.filters = this.cs.mapAttributeIds(this.entityData.filters, this.filterList, 'filter');
    this.searchEntity();
  }


  onSearchValueChanges(event) {
    this.entityData.searchStr = event;
    this.searchEntity();
  }

  tabViewChange(event) {
    switch (event.index) {
      case 0:
        this.entityData.actionStatus = '';
        this.entityData.deleted = AppSettings.DELETED_TYPE.ONLY_NON_DELETED;
        this.entityData.filters = [];
        this.searchEntity();
        break;
      default:
        break;
    }
  }
}

@if(isStandardGroup(group)) {
  @if(isSelectedPeople()) {
    <label class="people-count"></label>
  }
  @else if(isDefaultValues()) {
    <label class="w-full mt-0 uppercase text-lg font-semibold">{{cs.getLabelValue(moduleName+'.groups.'+group.code+'.label',attributeLabels,group.code)}}</label>
    <p class="font-14 default-text-light-gray" translate>settings.passenger.default_values_placeholder</p>
  }
  @else {
    <label class="w-full mt-0 uppercase text-lg font-semibold">{{cs.getLabelValue(moduleName+'.groups.'+group.code+'.label',attributeLabels,group.code)}}</label>
  }
}

@if(isDownloadAccountData()) {
  <label class="note-text block w-full mt-2" translate>lbl_text_for_download_data</label>
  <div class="mt-3 mb-2 pb-2 note-div align-content-center">
    <div class="p-2">
      <img class="note-img" src="../../../../../assets/images/mi-theme/light/basic/error_outline.svg">
    </div>
    <div class="align-content-center">
      <label class="text-sm opacity-70" translate>lbl_note_for_download_date</label>
    </div>
  </div>
}

@if(isPasswordRequirements()) {
  <label class="note-text w-full mt-3" translate>lbl_text_for_password_requirement</label>
  <div class="mt-3 flex mb-2 note2-div align-content-center">
    <div class="p-2">
      <img class="note-img" src="../../../../../assets/images/mi-theme/light/basic/error_outline.svg">
    </div>
    <div class="align-content-center">
      <label class="text-sm opacity-70" translate>lbl_note_for_password_requirement</label>
    </div>
  </div>
}

@if(isSessionTimeout()) {
  <label class="note-text w-full mt-3" translate>lbl_text_for_session_timeout</label>
} 
import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateModule } from "@ngx-translate/core";
import * as _ from 'lodash';
import { MenuItem, MessageService } from "primeng/api";
import { BreadcrumbModule } from "primeng/breadcrumb";
import { ButtonModule } from "primeng/button";
import { DialogModule } from "primeng/dialog";
import { TabViewModule } from "primeng/tabview";
import { AppSettings } from "../../../shared/app.settings";
import { BasicTableComponent } from "../../../shared/components/basic-table/basic-table.component";
import { MiFormComponent } from "../../../shared/components/mi-form/mi-form.component";
import type { Country } from "../../../shared/models/country";
import type { Language } from "../../../shared/models/language";
import { CommonBindingDataService } from "../../../shared/services/common-binding-data.service";
import { EntityService } from "../../../shared/services/entity.service";
import type { AttributeData, entityResponse } from "../../models/attribute.models";

import dayjs from "dayjs";
import type { EntityResponse } from "../../models/entityResponse.models";
import { VehicleDocumentEntityComponent } from "../vehicle-document-entity/vehicle-document-entity.component";
import { ConfigService } from "app/modules/shared/services/config.service";
import { AccessProviderDirective } from "app/modules/shared/directives/access-provider.directive";

@Component({
  selector: "app-add-vehicle",
  templateUrl: "./add-vehicle.component.html",
  styleUrls: ["./add-vehicle.component.scss"],
  standalone: true,
  imports: [
    BreadcrumbModule,
    TabViewModule,
    MiFormComponent,
    DialogModule,
    BasicTableComponent,
    ButtonModule,
    TranslateModule,
    VehicleDocumentEntityComponent,
    AccessProviderDirective
  ],
})
export class AddVehicleComponent implements OnInit {
  @ViewChild(MiFormComponent) miFormComponent: MiFormComponent;
  activeIndex: number = 0;
  data: AttributeData;
  vehicleAttributeData: any;
  routePath: MenuItem[] = [];
  attributeLabels = {};
  moduleName: string;
  country: Country;
  language: Language;
  relation: string = "oneToOne";
  cols: any = [];
  vehicleAttributeLength;
  vehicleId: string;
  vehicleIdForDocument: string;
  vehicleDocumentId;
  driverRelation: any;
  attributeValues = [];
  nextBtnLabel;
  previousBtnLabel;
  label: string;
  initialLabel: string = this.cs.getLabel("lbl_details");
  saveVehicleBtnLabel: string;
  isFirstTabSaved: boolean = false;
  isNew: boolean = true;

  constructor(
    private vehicleService: EntityService,
    private cs: CommonBindingDataService,
    private messageService: MessageService,
    private router: Router,
    private route: ActivatedRoute,
    private configService: ConfigService
  ) { }

  ngOnInit() {
    this.initializeLabels();
    this.retrieveVehicleId();
    this.initializeVehicleService();
    this.loadVehicleData();
    this.setRoutePath();
    this.updateBreadcrumb(this.initialLabel);
    this.isFirstTabSaved = false;
  }

  private initializeLabels() {
    this.nextBtnLabel = this.cs.getLabel("lbl_next");
    this.previousBtnLabel = this.cs.getLabel("lbl_previous");
    this.saveVehicleBtnLabel = this.vehicleId ?
      this.cs.getLabel("lbl_update_vehicle") :
      this.cs.getLabel("lbl_save_vehicle");
  }

  private retrieveVehicleId() {
    this.vehicleId = this.route.snapshot.paramMap.get("id");
    if (this.vehicleId) {
      this.vehicleIdForDocument = this.vehicleId;
    }
  }

  private initializeVehicleService() {
    this.moduleName = AppSettings.ENTITY_TYPE.VEHICLE;
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
    this.vehicleService.setRelatedData([]);
  }

  private loadVehicleData() {
    if (this.vehicleId) {
      this.vehicleService
        .getEntity(this.vehicleId, AppSettings.ENTITY_CODE.VEHICLE, AppSettings.VIEW_CODE.ADD_EDIT_VIEW)
        .subscribe((res: EntityResponse) => {
          if (res) {
            this.processVehicleResponse(res);
            this.isNew = false;
          }
        });
    } else {
      this.getAttributes();
    }
  }

  private processVehicleResponse(data: EntityResponse) {
    this.attributeValues = this.cs.getOrgAttributeValues(data);
    this.normalizeManufacturingYear();
    this.processRelatedData(data.relatedData);
    this.getAttributes();
    // this.isFirstTabSaved = true;
  }

  private normalizeManufacturingYear() {
    const yearOfMnfgAttributeCode = "year_of_manufacturing";
    if (this.attributeValues[yearOfMnfgAttributeCode]) {
      this.attributeValues[yearOfMnfgAttributeCode] = new Date(
        this.attributeValues[yearOfMnfgAttributeCode], 0
      );
    }
  }

  private processRelatedData(relatedData: any[]) {
    if (relatedData.length > 0) {
      const organizedData: { [key: string]: any[] } = {};
      relatedData.forEach(item => {
        if (item.actionStatus === "DELETED") {
          return;
        }
        const entityCode = item.entityCode;
        if (!organizedData[entityCode]) {
          organizedData[entityCode] = [];
        }
        const entityData = this.cs.getOrgAttributeValues(item);
        entityData['entityId'] = item.entityId;
        organizedData[entityCode].push(entityData);
      });
      this.vehicleService.setRelatedData(organizedData);
    }
  }


  onTabChange(event: any) {
    if(event.index === 0){
      this.isFirstTabSaved = false;
    }
    if (!this.isFirstTabSaved && event.index > 0) {
      this.activeIndex = 0;
      this.messageService.add({
        severity: 'warn',
        summary: 'Warning',
        detail: 'Please save details in first tab before proceeding'
      });
      return;
    }

    this.label = event.originalEvent.target.innerText.trim();
    this.updateBreadcrumb(this.label);
  }

  updateBreadcrumb(label: string) {
    this.routePath = [
      {
        label: this.vehicleId ? this.cs.getLabel("lbl_edit_vehicles") : this.cs.getLabel("lbl_add_new_vehicles"),
        routerLink: this.vehicleId ? '../../list' : '../list',
        icon: "pi pi-arrow-left",
        iconStyle: { "font-weight": "bold", "margin-right": "10px" },
      },
      {
        label,
        styleClass: 'breadcrumb-child forward-slash breadcrumb-text',
        style: { 'display': 'flex', 'top': '2px', 'position': 'relative' }
      }
    ];
  }

  getAttributes() {
    const entityType = AppSettings.ENTITY_TYPE.VEHICLE;
    const viewCode = AppSettings.VIEW_CODE.ADD_EDIT_VIEW;
    this.vehicleService.getAttributeDefinition(entityType, viewCode).subscribe(res => {
      if (!res) return;
      this.setVehicleView(res);
    });

  }

  setVehicleView(res) {
    this.attributeLabels = this.cs.getAttributeLabels(res);
    this.vehicleAttributeData = this.cs.getOrganizedAttribute(res);
    this.vehicleAttributeData.tabs = this.cs.setDependsOnAttributeCode(this.vehicleAttributeData?.tabs);
    this.setDateFieldValidations();
    this.vehicleAttributeLength = this.vehicleAttributeData.tabs.length;
    this.driverRelation = _.find(res?.relations, { otherEntityCode: 'driver', relation: "oneToMany" });
  }

  private setDateFieldValidation(tabCode, groupCode, attributeCode, isMax) {
    const tab = this.vehicleAttributeData.tabs.find(ele => ele.tabCode === tabCode);
    const group = tab?.groups.find(ele => ele.code === groupCode);
    const field = group?.fields.find(ele => ele.attributeCode === attributeCode);

    if (field) {
      field.validation[isMax ? 'max' : 'min'] = new Date();
    }
  }

  private setDateFieldValidations() {
    this.setDateFieldValidation('documents', 'documents', AppSettings.DATE_ATTRIBUTE_IDS.VEHICLE_DOCUMENT_ISSUED_DATE, true);
    this.setDateFieldValidation('documents', 'documents', AppSettings.DATE_ATTRIBUTE_IDS.VEHICLE_DOCUMENT_EXPIRY_DATE, false);
    this.setDateFieldValidation('details', 'additional_details', AppSettings.DATE_ATTRIBUTE_IDS.VEHICLE_DETAIL_YEAR_OF_MFG, true);
  }


  setRoutePath() {
    this.routePath = [
      {
        label: this.cs.getLabel("lbl_add_new_vehicles"),
        routerLink: this.vehicleId ? '../../list' : '../list',
        icon: "pi pi-arrow-left",
        iconStyle: { "font-weight": "bold", "margin-right": "10px" },
      },
      {
        label: this.vehicleId
          ? this.cs.getLabel("vehicle.edit")
          : this.cs.getLabel("vehicle.add"),

        styleClass: "breadcrumb-child forward-slash breadcrumb-text",
        style: { display: "flex", top: "2px", position: "relative" },
      },
    ];
  }

  onSaveVehicle(event, documents?) {

    const vehicleData = {
      forTenantCode: this.configService.getForTenantCode(),
      entityCode: this.moduleName,
      countryCode: this.country[0].countryCode,
      languageCode: this.language[0].langCode,
      data: [],
      relationshipData: []
    };

    const vData = event;
    const dateIds = ["year_of_manufacturing", "issued_date", "expiry_date", "in_service_date", "out_of_service_date"];
    for (const [key, value] of Object.entries(vData)) {
      let attributeValue = <any>value;
      if (dateIds.includes(key) && key !== "year_of_manufacturing") {
        attributeValue = new Date(<string>value).getTime();
      } else if (key == "year_of_manufacturing") {
        attributeValue = Number(dayjs(<number>value).format('YYYY'))
      } else if (key === 'driver') {
        attributeValue?.map(driverId => {
          vehicleData?.relationshipData?.push({
            entityRelationshipConfigId: this.driverRelation.entityRelationshipConfigId,
            otherEntityId: driverId
          });
        })
      }
      if (attributeValue) {
        const obj = typeof attributeValue;
        if (obj == "object") {
          if (key === 'driver') {
            vehicleData.data.push({
              attributeCode: key,
              attributeValue,
            });
          } else if (attributeValue?.length > 0) {
            vehicleData.data.push({
              attributeCode: key,
              attributeValue,
            });
          }
        } else {
          vehicleData.data.push({
            attributeCode: key,
            attributeValue,
          });
        }
      }
    }
    const entitiesData = {
      countryCode: this.country[0].countryCode,
      tenantCode: this.configService.getForTenantCode(),
    };

    if (!this.vehicleId) {
      this.vehicleService
        .createEntities(this.moduleName, entitiesData)
        .subscribe((res: entityResponse) => {
          this.vehicleId = res.entityId;
          if (this.vehicleId) {
            this.vehicleIdForDocument = this.vehicleId;
          }
          this.saveVehicleData(vehicleData, true);
        });
    } else {
      this.saveVehicleData(vehicleData, false);
    }
  }

  saveVehicleData(vehicleData, isNew: boolean) {
    this.isNew = isNew;
    vehicleData.data = this.cs.mapAttributeIds(vehicleData.data, this.vehicleAttributeData.tabs)
    this.vehicleService
      .saveAttributeData(vehicleData.entityCode, this.vehicleId, vehicleData).subscribe({
        next: (res: any) => {
          this.isFirstTabSaved = true;
          
            if (this.isNew) {
              this.cs.onDisplayMessage(this.vehicleAttributeData, AppSettings.ENTITY_TYPE.VEHICLE, AppSettings.MSG_ACTION.ADD, 'success');
            } else {
              this.cs.onDisplayMessage(this.vehicleAttributeData, AppSettings.ENTITY_TYPE.VEHICLE, AppSettings.MSG_ACTION.UPDATE, 'success');
            }

            setTimeout(() => {
              if (this.isLastIndex()) {
                this.router.navigate(["app/vehicles/list"]);
              } else {
                this.onNextClick();
              }
            }, 500);
        },
        error: (error) => {
          if (this.activeIndex === 0) {
            this.isFirstTabSaved = false;
          }
          if (isNew) {
            this.cs.onDisplayMessage(this.data, 'vehicle', AppSettings.MSG_ACTION.ADD, 'error');
          } else {
            this.cs.onDisplayMessage(this.data, 'vehicle', AppSettings.MSG_ACTION.UPDATE, 'error');
          }
        }
      });
  }

  onPreviousClick() {
    if (this.activeIndex > 0) {
      this.activeIndex--;
    }
    if (this.isLastIndex() === false) {
      this.nextBtnLabel = this.cs.getLabel("lbl_next");
    }
  }

  onNextBtnClick() {
    if (this.vehicleAttributeData?.tabs && this.vehicleAttributeData.tabs.length > 0) {
      const currentTab = this.vehicleAttributeData.tabs[this.activeIndex];
      if (currentTab) {
        const btn = document.getElementById(currentTab.tabCode);

        if (btn) {
          btn.click();
        } else {
          console.log(`Button with ID ${currentTab.tabCode} not found.`);
        }
      } else {
        console.log(`Tab at index ${this.activeIndex} is not valid.`);
      }
    } else {
      console.log('Tabs data is not available.');
    }
  }

  onNextClick() {
    if (this.activeIndex < this.vehicleAttributeLength - 1) {
      this.activeIndex++;
    }
    if (this.isLastIndex() === true) {
      this.nextBtnLabel = this.saveVehicleBtnLabel;
    }
  }

  getLastIndex(): number {
    return this.vehicleAttributeLength - 1;
  }

  isLastIndex(): boolean {
    return this.activeIndex === this.getLastIndex();
  }


  onCancel() {
    this.isFirstTabSaved = false;
    this.miFormComponent.resetForm();
    this.router.navigate(["app/vehicles/list"]);
  }
}

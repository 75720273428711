<!-- @if (showTable) { -->
    <app-mi-table
        [collectionOfColumns]="allColumns"
        [attributeLabels]="filterAttributeLabels"
        [data]="tableDataUpdated"
        entityName="vehicle_assignment_history"
        [isLoading]="isLoading"
        [totalRecords]="totalRecords"
        [filterList]="filterList"
        (onSearchValueChange)="onSearchValueChanges($event)"
        (onPageChange)="onPageChange($event)"
        (itemPerPageChange)="itemPerPageChange($event)"
        [label]="addBtnLabel"
        (onBtnClick)="onAddAssignment()">
    </app-mi-table>
<!-- } -->
import { Directive, ElementRef, EventEmitter, Output, AfterViewInit } from '@angular/core';
import { AppSettings } from './../app.settings';
import { CommonBindingDataService } from '../services/common-binding-data.service';

declare const google: any;

@Directive({
  selector: '[appSearchLocation]',
  standalone: true
})
export class SearchLocationDirective implements AfterViewInit {
  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  private _el: HTMLElement;
  autocomplete: any;

  constructor(private el: ElementRef, private cs: CommonBindingDataService) {
    this._el = el.nativeElement;
  }

  ngAfterViewInit() {
    if (typeof google !== 'undefined' && google.maps && google.maps.places) {
      this.initAutocomplete();
      this._el.addEventListener('blur', this.handleBlur.bind(this));
      this._el.addEventListener('input', this.handleInput.bind(this));
    } else {
      console.error('Google Maps API not loaded.');
    }
  }

  private initAutocomplete() {
    const center = { lat: 50.064192, lng: -130.605469 };
    // Create a bounding box with sides ~10km away from the center point
    const defaultBounds = {
      north: center.lat + 0.1,
      south: center.lat - 0.1,
      east: center.lng + 0.1,
      west: center.lng - 0.1,
    };

    const options = {
      componentRestrictions: { country: AppSettings.SUPPORTED_COUNTRY },
      fields: ["formatted_address", "geometry", "name"],
      strictBounds: false,
    };

    const input = this._el;
    this.autocomplete = new google.maps.places.Autocomplete(input, options);
    this.autocomplete.setComponentRestrictions({ country: AppSettings.SUPPORTED_COUNTRY });
    // this.autocomplete.setFields(["place_id", "geometry", "name"]);
    google.maps.event.addListener(this.autocomplete, 'place_changed', () => {
      const place = this.autocomplete.getPlace();
      const placeObj = {
        name: place.name,
        address: place.formatted_address,
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
        id: input.id
      };
      this.invokeEvent(placeObj);
    });
  }

  private invokeEvent(place: Object) {
    this.setAddress.emit(place);
  }

  private handleBlur() {
    const inputElement = this._el as HTMLInputElement;
    setTimeout(() => {
      if (!this.autocomplete.getPlace() && inputElement.value.trim().length > 0) {
        this.setAddress.emit({ error: this.cs.getLabel('manage_locations.location_error') });
      }
    }, 100);
  }

  private handleInput() {
    const inputElement = this._el as HTMLInputElement;
    if (!this.autocomplete.getPlace() && inputElement.value.trim().length > 0) {
      this.setAddress.emit({ error: this.cs.getLabel('manage_locations.location_error') });
    } else if (inputElement.value.trim().length === 0) {
      this.setAddress.emit({ error: this.cs.getLabel('manage_locations.location_error') });
    }
  }

}
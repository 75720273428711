import { NgClass } from "@angular/common";
import { Component, Inject, OnInit } from "@angular/core";
import { RouterOutlet } from "@angular/router";
import { ToastModule } from "primeng/toast";
import { AppSettings } from "../../app.settings";
import { Country } from "../../models/country";
import { Language } from "../../models/language";
import { CommonBindingDataService } from "../../services/common-binding-data.service";
import { HeaderComponent } from "../header/header.component";
import { LoaderComponent } from "../loader/loader.component";
import { SidebarComponent } from "../sidebar/sidebar.component";
import { FullScreenService } from "../../../dashboard/services/full-screen-service";
import { GoogleMapsService } from "../../services/google-map.services";
import { ConfigService } from "../../services/config.service";
import { RestApiService } from "../../services/rest-api.service";
import { MetaDataService } from "../../services/meta-data.service";
import { MenuAccess } from "../sidebar/mdoal/sidebarModal";
import { MenuItem } from "primeng/api";
import { AppIcons } from "../../app.icons";
import * as _ from 'lodash';
import { EntityService } from "../../services/entity.service";
import { EventService } from "app/modules/events/services/event.service";
import { Router } from "@angular/router";
import { SkeletonModule } from "primeng/skeleton";

@Component({
  selector: "app-layout",
  templateUrl: "./layout.component.html",
  styleUrls: ["./layout.component.scss"],
  standalone: true,
  imports: [
    LoaderComponent,
    ToastModule,
    NgClass,
    SidebarComponent,
    HeaderComponent,
    RouterOutlet,
    SkeletonModule
  ],
})
export class LayoutComponent implements OnInit {
  miIcons = AppIcons;
  isSidebarFull: boolean = false;
  metaData: Country;
  languageDetails: Language;
  isFullScreen: boolean = false;
  isSidebarReady: boolean = false;
  isMapLoaded: boolean = false;
  items: MenuItem[] = [];
  eventResponse;
  entityId;
  languageCountryPayload = {
    "limit": 20,
    "offset": 0
  }
  entityData: any = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: "",
    defaultSortColumn: 'updatedAt',
    defaultSortType: 'asc',
    status: 'Both',
    startDate: 1704067200000,
    endDate: 0
  };

  eventListRequestBody = {
    forTenantCode: this.configService.getForTenantCode(),
    status: 'active'
  }

  appConfigPayload ={
    limit: 0,
    offset: 0,
    searchStr: "WEB",
    filters: [
      
    ],
    forTenantCode: this.configService.getForTenantCode(),
    countryCode: "IN",
    eventCode: "1",
    deleted: "only_non_deleted",
    tableViewCode: AppSettings.VIEW_CODE.ADD_EDIT_VIEW,
    advanceFilters: [
    ]
  }
  

  constructor(
    private commonService: CommonBindingDataService,
    private fullScreenService: FullScreenService,
    private googleMapsService: GoogleMapsService,
    private configService: ConfigService,
    private restApiService: RestApiService,
    private metaDataService: MetaDataService,
    private entityService: EntityService,
    private eventService: EventService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.entityData.forTenantCode = this.configService.getForTenantCode();
    this.getGeneralSettings();
    // this.getMetaData();

  
  

    this.fullScreenService.isFullScreen$.subscribe((isFullScreen) => {
      this.isFullScreen = isFullScreen;
    });
  }

  isReadySidebar(event) {
    this.isSidebarReady = event;
  }

  getMetaData() {
    const payload = this.getMultiApiPayload();
    this.metaDataService.metaDataMultipleApi(payload).subscribe((res) => {
      console.log(res)
      const setCountry = JSON.parse(res.setCountry.responseBody);
      localStorage.setItem(AppSettings.COUNTRY, JSON.stringify(setCountry.data));
      this.metaData = setCountry;
      const setLanguage = JSON.parse(res.setLanguage.responseBody);
      localStorage.setItem(AppSettings.LANGUAGE, JSON.stringify(setLanguage.data));
      this.languageDetails = setLanguage;
      // const menuList = JSON.parse(res.menuList.responseBody);
      const menus = JSON.parse(res.menus.responseBody);
      this.items = _.sortBy(menus, (menu) => parseInt(menu.orderIndex));
      const setEvent = JSON.parse(res.setEvent.responseBody);
      this.eventResponse = setEvent;
      const generalSettings = JSON.parse(res.generalSettings.responseBody);
      const generalSettingsData = this.commonService.getOrgAttributeValues(generalSettings);
      
      if (setEvent?.length > 0) {
        this.metaDataService.updateMetaData(setCountry, setLanguage, menus, setEvent, generalSettingsData);
        this.metaDataService.countrySubject.next(setCountry);
        this.metaDataService.languageSubject.next(setLanguage);
        this.metaDataService.menusSubject.next(menus);
        this.metaDataService.eventResponseSubject.next(setEvent);
        this.metaDataService.generalSettingsSubject.next(generalSettingsData);
        
        this.appConfigPayload.countryCode = setCountry.data[0].countryCode;
        this.metaDataService.appConfig(AppSettings.ENTITY_CODE.APP_CONFIG, this.appConfigPayload).subscribe(res => {
          if (res?.data?.[0]?.values?.google_web_key) {
            const googleApiKey = res.data[0].values.google_web_key;
            const mapId = res.data[0].values.web_google_map_id;
            this.googleMapsService.loadScript(googleApiKey, mapId).then((res) => {
              this.isMapLoaded=true;

            }).catch(error => {
              console.error('Failed to load Google Maps:', error);
            });
          }
        });
      }
    });
  }

  getGeneralSettings() {
    this.entityService.getEntityDetailsByView(AppSettings.ENTITY_TYPE.GENERAL_SETTINGS, {}).subscribe({
      next: (response: any) => {
        this.entityId = response.entityId;
        this.getMetaData();
      },
      error: () => {
        this.router.navigate(["/auth/login"]);
      }
    });
  }

  private getMultiApiPayload() {
    return [
      {
        url: `${AppSettings.BASE_URL}${this.configService.getLoggedInTenantCode()}/api/v1/countries/search?forTenantCode=${this.configService.getForTenantCode()}`,
        inputBody: this.languageCountryPayload,
        method: 'POST',
        outputKey: AppSettings.META_OUTPUT_KEY.SET_COUNTRY,
        headers: this.restApiService.getHeaderForMultipleAPI()
      },
      {
        url: `${AppSettings.BASE_URL}${this.configService.getLoggedInTenantCode()}/api/v1/languages/search?forTenantCode=${this.configService.getForTenantCode()}`,
        inputBody: this.languageCountryPayload,
        method: 'POST',
        outputKey: AppSettings.META_OUTPUT_KEY.SET_LANGUAGE,
        headers: this.restApiService.getHeaderForMultipleAPI()
      },
      {
        url: `${AppSettings.BASE_URL}${this.configService.getLoggedInTenantCode()}/api/v1/events/user-events`,
        inputBody: this.eventListRequestBody,
        method: 'POST',
        outputKey: AppSettings.META_OUTPUT_KEY.SET_EVENT,
        headers: this.restApiService.getHeaderForMultipleAPI()
      },
      // {
      //   url: `${AppSettings.BASE_URL}${this.configService.getLoggedInTenantCode()}/api/v1/menus/menu-list?forTenantCode=${this.configService.getForTenantCode()}`,
      //   inputBody: '',
      //   method: 'GET',
      //   outputKey: AppSettings.META_OUTPUT_KEY.MENU_LIST,
      //   headers: this.restApiService.getHeaderForMultipleAPI()
      // },
      {
        url: `${AppSettings.BASE_URL}${this.configService.getLoggedInTenantCode()}/api/v1/menus?forTenantCode=${this.configService.getForTenantCode()}`,
        inputBody: '',
        method: 'GET',
        outputKey: AppSettings.META_OUTPUT_KEY.MENUS,
        headers: this.restApiService.getHeaderForMultipleAPI()
      },
      {
        url: `${AppSettings.BASE_URL}${this.configService.getLoggedInTenantCode()}/api/v1/entity-conf/${AppSettings.ENTITY_TYPE.GENERAL_SETTINGS}/entities/${this.entityId}?forTenantCode=${this.configService.getForTenantCode()}&viewCode=${AppSettings.VIEW_CODE.ADD_EDIT_VIEW}&eventCode=${this.eventService.getSelectedEventcode()}`,
        inputBody: '',
        method: 'GET',
        outputKey: AppSettings.META_OUTPUT_KEY.GENERAL_SETTINGS,
        headers: this.restApiService.getHeaderForMultipleAPI()
      }
    ]
  }
}

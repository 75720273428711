import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, input } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { InputNumberModule } from 'primeng/inputnumber';
import { PanelModule } from 'primeng/panel';
import { AppSettings } from '../../../../shared/app.settings';
import { MiErrorComponent } from '../../../../shared/components/mi-error/mi-error.component';
import { MiFieldsComponent } from '../../../../shared/components/mi-fields/mi-fields.component';
import { MiTooltipComponent } from '../../../../shared/components/mi-fields/mi-tooltip/mi-tooltip.component';
import { CommonBindingDataService } from '../../../../shared/services/common-binding-data.service';
import { AttributeLabelComponent } from '../attribute-label/attribute-label.component';

import { JsonPipe, NgClass } from '@angular/common';
import { Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import type { EntityList } from 'app/modules/vehicles/models/entity.models';
import type { ListResponse } from 'app/modules/vehicles/models/listResponse.models';
import * as _ from 'lodash';
import { AccordionModule } from 'primeng/accordion';
import { AutoComplete, AutoCompleteCompleteEvent, AutoCompleteModule } from 'primeng/autocomplete';
import { AvatarModule } from 'primeng/avatar';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TooltipModule } from 'primeng/tooltip';
import { Subscription, forkJoin } from 'rxjs';
import { skip, tap } from 'rxjs/operators';
import { AppIcons } from '../../../../shared/app.icons';
import { Countries } from '../../../../shared/countries';
import type { Language } from '../../../../shared/models/language';
import { ConfigService } from '../../../../shared/services/config.service';
import { EntityService } from '../../../../shared/services/entity.service';
import { MiValidationsService } from '../../../../shared/services/mi-validations.service';
import { AssignVehicleFromBookingsComponent } from '../assign-vehicle-from-bookings/assign-vehicle-from-bookings.component';
import { StopLocationsComponent } from '../stop-locations/stop-locations.component';
import { ManualDispatchComponent } from '../../manual-dispatch/manual-dispatch.component';
import { PassengerAutocompleteComponent } from '../passenger-autocomplete/passenger-autocomplete.component';
import { BookingService } from 'app/modules/bookings/services/booking.service';
import { MessageService } from 'primeng/api';
import { ManualDispatchDetailsComponent } from '../../manual-dispatch-details/manual-dispatch-details.component';

@Component({
  selector: 'app-booking-form',
  standalone: true,
  imports: [AccordionModule, AvatarModule, NgClass, DropdownModule, FormsModule, ReactiveFormsModule, JsonPipe, MiFieldsComponent, MiErrorComponent, StopLocationsComponent, ButtonModule, MiTooltipComponent, PanelModule, InputNumberModule, AttributeLabelComponent, AvatarModule, TranslateModule, AssignVehicleFromBookingsComponent, AutoCompleteModule, ButtonModule, InputSwitchModule, TooltipModule, ManualDispatchComponent, PassengerAutocompleteComponent, ManualDispatchDetailsComponent],
  templateUrl: './booking-form.component.html',
  styleUrl: './booking-form.component.scss'
})
export class BookingFormComponent implements OnInit, OnDestroy {
  @ViewChild('stopLocationsComponent') stopLocationsComponent!: StopLocationsComponent;
  items: { label?: string; icon?: string; separator?: boolean }[] = [];
  miIcons = AppIcons;
  @Input() attributeLabels: [];
  @Input() moduleName: string;
  @Input() groups: any;
  @Input() petGroup: any;
  @Input() submitBtnText: string;
  @Input() attributeValues: [];
  @Output() saveData: EventEmitter<any> = new EventEmitter();
  @Output() transportationType: EventEmitter<any> = new EventEmitter();
  @Output() switchChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() isAssignNowClicked: boolean = false;
  @Input() isEdit: boolean;
  @Input() ownerPassDetails;
  @Input() coPassengerDetails;
  @Input() petInfoDetails;
  @Input() stopLocations;
  @Input() saveBtnId: string;
  @Input() transportationTypeView: any;
  @Input() bookingStatus: string;
  miFormGroup: FormGroup;
  formGroupFields = [];
  public fields = [];
  formGroup: FormGroup;
  entityCodes = AppSettings.ENTITY_CODE;
  currentSelectedCode: string;
  country: Countries;
  language: Language;
  excludedCodesFromAccordion = [
    'transportation',
    'vehicle_preference',
    'transportation_mode_group',
    'booking_location_details',
    'booking_for',
    'transportation_type',
    'vehicle_dispatch_preference',
    'booking_details',
    'others',
  ];

  vehiclePreference = 'vehicle_preference';
  includeSpacer = [
    "child_seat",
    "luggage",
    "no_music",
    "music",
    "journey_preference"

  ];
  excludeToggle = [
    'wheelchair',
    'additional_vehicle_requirement',
    'number_of_passenger',
    'luggage',
    'luggage_vehicle',
    'passenger_vehicle',
    'pet_accommodation',
    'total_passenger',
    'pet'
  ];
  toggleForPassenger = [
    'additional_vehicle_requirement',
    'child_seat',
    'wheelchair',
    'luggage',
  ];
  toggleForOther = [
    'journey_preference',
    'pet_accomodation',
    'driver_instructions'
  ];
  // transportation_type
  skipGroup: string[] = ['booking_pass', 'passenger', 'admin_note', 'transportation_type'];
  bookingDetailsCard = ['passenger_vehicle', 'child_seat', 'wheelchair', 'luggage', 'luggage_vehicle'];
  otherDetailsCard = ['journey_preference', 'pet', 'instruction'];
  excludeFields = ['booking_pickup_location_address',
    'booking_pickup_location_geolocation',
    'booking_stops_location_address',
    'booking_stops_location_geolocation',
    'booking_dropoff_location_address',
    'booking_dropoff_location_geolocation',
    // 'transportation_mode',
    'trip',
    'dispatch_preference',
    'booking_passenger_section',
    'pet_accommodation',
    'owner_pass_id',
    'admin_note',
    'passenger_booking_ids',
    'luggage_booking_ids',
    'owner_trip_notification',
    'co_passenger_ids',
    'estimated_trip',
    'booking_pickup_location_id', 'booking_pickup_location_category', 'booking_stops_location_ids', 'booking_dropoff_location_id', 'booking_dropoff_location_category',
    'booking_status', 'is_applicable_for_associated_bookings', 'is_special_request'
  ]
  bookingIcons = {
    'total_passenger': AppIcons.BOOKING_TOTAL_PASSENGER,
    'child_seat': AppIcons.CHILD_SEAT_REQUIRED,
    'luggage': AppIcons.BOOKING_LUGGAGE_COUNT,
    'wheelchair': AppIcons.BOOKING_CARRYING_WHEELCHAIR,
    'passenger_vehicle': AppIcons.BOOKING_ADDITIONAL_PASSENGER_VEHICLE,
    'ride_preference': AppIcons.BOOKING_ADDITIONAL,
    'flight_details': AppIcons.BOOKING_FLIGHT_DETAILS,
    'instruction': AppIcons.BOOKING_INSTRUCTION_NOTES,
    'luggage_vehicle': AppIcons.BOOKING_LUGGAGE_VEHICLE_REQUIRED,
    'journey_preference': AppIcons.BOOKING_JOURNEY_PREFERENCE,
    'pet': AppIcons.BOOKING_PET_ACCOMODATION
  };

  excludeAccordionHeaderFormValue = [
    'instruction_note', 'display_to_driver', 'flight_number', 'flight_name', 'arrival_time', 'display_to_passenger'
  ]

  ride_preference_code = 'ride_preference';
  booking_passenger_address = 'booking_passenger_address';

  stopPlaceAttributeCode = 'stops';
  usersForm: FormGroup;
  stopField;
  isReady = false;
  isManualDispatch = false;

  manualDispatch = {
    "attributeCode": "vehicle_assignment",
    "presetValues": [
      {
        "labelKey": 'assign_vehicle_later',
        "labelValue": this.cs.getLabel('bookings.assign_vehicle_later'),
        "labelIcon": "mi-basic-clock",
        "parentLabelKey": null
      },
      {
        "labelKey": 'assign_vehicle_now',
        "labelValue": this.cs.getLabel('bookings.assign_vehicle_now'),
        "labelIcon": "mi-car",
        "parentLabelKey": null
      }
    ],
    "uiColumns": 6,
    "inputCode": "radioButton",
    "optionVertical": true,
  };

  selectVehicle = {
    "attributeCode": 'selectVehicle',
    "presetValues": [
      {
        "labelKey": this.cs.getLabel('bookings.any_vehicle'),
        "labelValue": this.cs.getLabel('bookings.any_vehicle'),
        "parentLabelKey": null
      },
    ],
  };
  selectedVehicle: any;
  isVisibleAssignVehicle: boolean = false;
  isVisibleSelectVehicle: boolean = false;
  assignVehicleControlCode = 'vehicle_assignment';
  stopsAttributeCode = "booking_stops_location_geolocation";
  manualDispatchConst = "Manual Dispatch";
  assignVehicleNow = "assign_vehicle_now";
  selectVehicleCode = 'selectVehicleCode';
  transportationTypeCode = "transportation_type";
  trip_code = 'trip'
  totalPassengerCode = 'total_passenger';
  excludeLabels = ['trip', 'transportation_mode', 'transportation_type', 'vehicle_to_transfer']
  excludeGroupCodeLabel = ['transportation_type', 'booking_location_details', 'booking_details'];
  horizontalForm = ['luggage', 'child_seat', 'journey_preference']
  returnTimeAndDateCode = "return_time_and_date";
  journeyTimeCode = "journey_time";
  departureTimeAndDate = "departure_time_and_date";
  airportValue = "Airport";
  isShowAirportDetails = false;
  tripCode = "trip";
  roundTripValue = ["Round Trip", "Hourly Trip"];
  isRoundTrip = false;
  isHourlyTrip = false;
  formValue: any;
  vehicleBodyType: string;
  private subscriptions: Subscription[] = [];
  requestedByChecked: boolean = false;
  coPassengerInfo: boolean = false;
  petInfo: boolean = false;
  selectedPassenger = [];
  currentSelectedPassenger: any;
  suggestions: any[] | undefined;
  defaultLoaderImg = AppSettings.DEFAULT_LOADER_IMAGE;
  entityData: EntityList = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: "",
    filters: [],
    countryCode: '',
    deleted: AppSettings.DELETED_TYPE.ONLY_NON_DELETED,
    forTenantCode: "",
    actionStatus: ''
  };
  tableData = [];
  passenger: any;
  requestedPassenger = [];
  currentRequestedPassenger: any;
  passengerSearch: string[] = [];
  totalPassengers = [];
  numberOfPassengerCount = 0;
  petCount = 0;
  petFields: any[] = [];
  showStopLocation: boolean = false;
  passengerId: any;
  requestedPassengerId: any;
  isPassengerSelected: boolean = false;
  isRequestedPassengerSelected: boolean = false;
  assignAdditionalConfiguration = {
    additionalVehicleCount: 0,
    luggageVehicleCount: 0,
    isBookNow: false,
  };
  petAccomadationArray = [];

  additionalRequiredData: any;
  autoToManual: boolean = false;
  @Input() luggageBookingIds: [];
  @Input() passengerBookingIds: [];
  @Input() mainBookingId;
  previousPetCount: number = 0;
  BOOKING_STATUS_IDS = AppSettings.BOOKING_STATUS_IDS;

  constructor(public cs: CommonBindingDataService, private validationsService: MiValidationsService,
    private cd: ChangeDetectorRef, private messageService: MessageService,
    private entityService: EntityService, private configService: ConfigService, private fb: FormBuilder, private router: Router, private bookingService: BookingService
  ) {

  }

  ngOnInit() {
    this.initializeVars();
    this.formGroup = new FormGroup({
      value: new FormControl(1)
    });

    if (this.petGroup && this.petGroup.tabs) {
      const petTab = this.petGroup.tabs.find(tab => tab.tabCode === 'default');
      if (petTab && petTab.groups[0]) {
        const petGroup = petTab.groups[0];
        this.petFields = petGroup.fields.map((field, index) => ({
          ...field,
          attributeCode: field.attributeCode,
        }));
       

      }
    }
  
      this.buildForm();
      this.isReady = true;

     this.sectionHighlight();

      if (this.isEdit) {
        this.handleEditMode();
      }

      const pickDropAddressLocationsValuePairs = [
        { controlCode: 'booking_pickup_location_geolocation', targetCode: 'booking_pickup_location_address' },
        { controlCode: 'booking_dropoff_location_geolocation', targetCode: 'booking_dropoff_location_address' },
      ];


      pickDropAddressLocationsValuePairs.forEach(pair => {
        const subscription = this.miFormGroup.controls[pair.controlCode]?.valueChanges.subscribe(value => {
          this.miFormGroup.controls[pair.targetCode].setValue(value);
        });
        this.subscriptions.push(subscription);
      });

      this.passengerSearch = Array(this.numberOfPassengerCount).fill('');

      this.miFormGroup.controls['number_of_passenger']?.valueChanges.subscribe(value => {
        this.numberOfPassengerCount = value;
        this.passengerSearch = Array(value).fill('');
      });

    
    if (this.isEdit) {
      this.miFormGroup.controls['pet_count']?.valueChanges.pipe(
        skip(1)
      ).subscribe(value => {
        this.petCount = value as any;
        if(this.petCount > 0 && this.petCount > this.previousPetCount){
          this.updatePetFormFields(this.petCount);
        }
        this.previousPetCount = this.petCount;
      });
    } else { 
      this.miFormGroup.controls['pet_count']?.valueChanges.subscribe(value => {
        this.petCount = value as any;        
        if(this.petCount > 0 && this.petCount > this.previousPetCount){
          this.updatePetFormFields(this.petCount);
        }
        this.previousPetCount = this.petCount;
      });
    }
    

      this.miFormGroup.controls['additional_passenger_vehicle']?.valueChanges.subscribe(value => {
        this.assignAdditionalConfiguration.additionalVehicleCount = value || 0;
      });

      this.miFormGroup.controls['luggage_vehicle_required']?.valueChanges.subscribe(value => {
        this.assignAdditionalConfiguration.luggageVehicleCount = value || 0;
      });

      const dispatchControlCode = 'vehicle_dispatch_preference';
      const dispatchSubscription = this.miFormGroup.controls[dispatchControlCode]?.valueChanges.subscribe(value => {
        this.isManualDispatch = value === this.manualDispatchConst;
      });

      const assignVehicleSubscription = this.miFormGroup.controls['vehicle_assignment']?.valueChanges.subscribe(value => {
        this.isVisibleSelectVehicle = value === this.assignVehicleNow;
      });

      const vehiclePreference = this.miFormGroup.controls['vehicle_type_preference']?.valueChanges.subscribe(value => {
        this.vehicleBodyType = value
      });


      const tripSubscription = this.miFormGroup.controls[this.tripCode]?.valueChanges.subscribe(value => {
        this.setTrip(value);

      });

      const returnTime = this.miFormGroup.controls[this.returnTimeAndDateCode]?.valueChanges.subscribe(value => {
        const departureDate = this.miFormGroup.get(this.returnTimeAndDateCode).value;
        const returnDate = this.miFormGroup.get(this.returnTimeAndDateCode).value;
        const journeyTime = returnDate.diff(departureDate, 'millisecond');

        this.miFormGroup.get(this.journeyTimeCode).setValue(journeyTime);
        this.miFormGroup.get(this.journeyTimeCode).updateValueAndValidity();

      });




      const transportationTypeSubscription = this.miFormGroup.controls[this.transportationTypeCode]?.valueChanges.subscribe(value => {
        this.transportationType.emit(value);
        // this.isShowAirportDetails = value === this.airportValue;
        // if (this.isShowAirportDetails) {
        //   const itemToRemove = 'flight_details';
        //   this.skipGroup = _.without(this.skipGroup, itemToRemove);
        //   ['flight_number', 'flight_name', 'arrival_or_departure_time'].forEach(flightDetailsCode => {
        //     this.miFormGroup.controls[flightDetailsCode]?.setValue('');
        //   });
        // } else {
        //   this.skipGroup = ['booking_pass', 'passenger', 'flight_details'];
        // }
      });

      this.subscriptions.push(dispatchSubscription, tripSubscription);
      this.subscriptions.push(assignVehicleSubscription);
      this.subscriptions.push(transportationTypeSubscription, returnTime);

      this.bookingService.visibleAssignVehicle$.subscribe(visible => {
        if (this.transportationTypeView === 'Vehicle Transfer' ) {
          this.isVisibleAssignVehicle = visible;
        }
      });
    
  }
 

  private handleEditMode() {
    this.miFormGroup.controls['transportation_type']?.setValue(this.attributeValues['transportation_type'])
    this.numberOfPassengerCount = this.attributeValues['number_of_passenger'];
    this.petCount = this.attributeValues['pet_accommodation']?.length;
    this.miFormGroup.controls['pet_count']?.setValue(this.petCount);
    // console.log(this.ownerPassDetails);
    console.log('old co-pass:',this.coPassengerDetails);
    // console.log('pet detail:',this.petInfoDetails);

    if(this.ownerPassDetails) {
      let ownerPassObj : any = {};

      ownerPassObj.name = this.ownerPassDetails?.pass_full_name;
      ownerPassObj.passenger_type = this.ownerPassDetails?.passenger_type;
      ownerPassObj.unique_id = this.ownerPassDetails?.unique_id;
      ownerPassObj.id = this.ownerPassDetails?.id;
      if (this.ownerPassDetails?.passenger_profile_image_url && this.ownerPassDetails?.passenger_profile_image_url.length > 0) {
        ownerPassObj.passenger_profile_image = this.ownerPassDetails?.passenger_profile_image_url[0]
        this.getFile(ownerPassObj);
      }
      this.selectedPassenger[0] = ownerPassObj; 
      this.isPassengerSelected = true;
      // ownerPassObj.isAdded = true;
      // this.bookingService.setSelectedPassenger(ownerPassObj);
      // this.miFormGroup.controls['booking_for']?.setValue(this.ownerPassDetails.id);
    }

    if (this.coPassengerDetails && this.coPassengerDetails.length > 0) {
      const coPassengerIds = [];
      this.miFormGroup.controls['co_passenger_ids']?.setValue([]);
      this.coPassengerDetails.forEach((coPass, index) => {
        let coPassengerObj: any = {};
        coPassengerObj.name = coPass?.pass_full_name;
        coPassengerObj.passenger_type = coPass?.passenger_type;
        coPassengerObj.unique_id = coPass?.unique_id;
        coPassengerObj.id = coPass?.id;
        if (coPass?.passenger_profile_image) {
          coPassengerObj.passenger_profile_image = coPass?.passenger_profile_image;
          this.getFile(coPassengerObj);
        }
        coPassengerIds.push(coPass.id);
        this.selectedPassenger[index + 1] = coPassengerObj;  
        // coPassengerObj.isAdded = true;
        // this.bookingService.setSelectedPassenger(coPassengerObj); 
        // this.miFormGroup.controls['booking_passenger_section']?.setValue(coPassengerIds);
      });
      console.log(this.selectedPassenger);
    }

    if (this.petInfoDetails && this.petInfoDetails.length > 0) {
      this.petInfoDetails.forEach((petDetail, petIndex) => {
        this.createAndPatchPetFormFields(petIndex+1);
      });
    }

    // const value = this.miFormGroup.controls[this.tripCode].value;
    // this.setTrip(value);
  }


  createAndPatchPetFormFields(index){  
      const fields = _.cloneDeep(this.petFields);
        for (const field of fields) {
          this.currentSelectedCode = field.inputCode;
          const validators = this.validationsService.addValidator(field.validation, this.currentSelectedCode);
          field.attributeCodeOneToMultiple = `${index}_${field.attributeCode}`;
          field.isOneToMultiple = true;
          field.attributeValue= this.petInfoDetails?.[index-1]?.[field.attributeCode];
          field.fieldName = field.attributeCodeOneToMultiple;
          const value = field.attributeValue?field.attributeValue : '';
          this.formGroupFields[field.attributeCodeOneToMultiple] = new FormControl(value, validators);
        }
        console.log(this.formGroupFields);
      this.petAccomadationArray.push(fields);
      console.log(this.petAccomadationArray);
      
    }

  updatePetFormFields( petCount){
    const fields = _.cloneDeep(this.petFields);
      for (const field of fields) {
        this.currentSelectedCode = field.inputCode;
        const validators = this.validationsService.addValidator(field.validation, this.currentSelectedCode);
        field.attributeCodeOneToMultiple = `${petCount}_${field.attributeCode}`;
        field.isOneToMultiple = true;
        field.fieldName = field.attributeCodeOneToMultiple;
        const value = this.attributeValues ? this.attributeValues[field?.attributeCode] : '';
        this.formGroupFields[field.attributeCodeOneToMultiple] = new FormControl(value, validators);
      }
      console.log(this.formGroupFields);
    this.petAccomadationArray.push(fields);
    console.log(this.petAccomadationArray);
    
  }

  addStopPoint(event: Event) {
    this.showStopLocation = true;
  }

  handleStopRemoval(index: number) {
    if (index === 0) {
      this.showStopLocation = false;
    }
  }

  get passengerIndices(): number[] {
    return Array.from({ length: this.numberOfPassengerCount - 1 }, (_, index) => index + 1);
  }

  getPetIndices(count: number): number[] {
    return Array.from({ length: count }, (_, index) => index);
  }

  removePetInfo(index: number) {
    if (index >= 0 && index < this.petCount) {
      this.petFields.forEach(field => {
        const controlKey = `${index + 1}_${field.attributeCode}`;
        if (this.formGroupFields[controlKey]) {
          delete this.formGroupFields[controlKey];
        }
      });

      this.petAccomadationArray.splice(index, 1);
      

      this.previousPetCount=this.petCount;
      this.petCount--;
      this.miFormGroup.controls['pet_count'].setValue(this.petCount);


      if (this.petCount === 0) {
        this.petInfo = false;
      }
    }
  }

  search(event: AutoCompleteCompleteEvent) {
    this.entityData.searchStr = event.query;
    this.searchEntity();
  }

  searchEntity() {
    this.tableData = [];
    this.entityData.countryCode = this.country[0].countryCode;
    this.entityData.forTenantCode = this.configService.getForTenantCode();
    const isLoader = false;
    this.entityService.searchEntity(AppSettings.ENTITY_CODE.PASSENGER, this.entityData, isLoader).subscribe((response: ListResponse) => {
      const passenger = this.cs.getTableData(response);
      this.setPassengerSuggestions(passenger);
    });
  }

  setPassengerSuggestions(res) {
    const passenger = res;
    passenger.map(item => {
      item.name = item.first_name + ' ' + item.last_name
    });
    passenger.forEach(rowData => {
      if (rowData?.passenger_profile_image && (rowData?.passenger_profile_image[0])) {
        this.getFile(rowData)
      }
    });
    this.suggestions = passenger;
  }

  getFile(rowData) {

    if (rowData?.passenger_profile_image) {
      this.entityService.getFile(rowData.passenger_profile_image[0], AppSettings.DOCUMENTS_TYPE.PROFILE).subscribe(result => {
        const file = new File([result], 'entity');
        const reader = new FileReader();
        reader.readAsDataURL(result);
        const that = this;
        reader.onloadend = function() {
          const base64data = reader.result;
          rowData.passenger_profile_image = base64data;
        }
      })
    } else {
      return AppSettings.DEFAULT_LOADER_IMAGE;
    }
  }

  onSelectPassenger(selected, selectedType?: string) {
    if (selectedType === 'isOwner') {
      this.passenger = selected.value;
      this.passenger.isBookingFor = true;
      this.passengerId = selected.value.id;
      this.getPassengerSetData(this.passengerId, AppSettings.ENTITY_CODE.PASSENGER);
      this.selectedPassenger.push(this.passenger);
      this.bookingService.setSelectedPassenger(this.passenger); 
      this.miFormGroup.controls['booking_for'].setValue(this.passengerId);
      this.isPassengerSelected = true;
      this.cd.detectChanges();
    } else {
      this.passenger = selected.value;
      this.passenger.isCoPass = true;
      this.passengerId = selected.value.id;
      this.getPassengerSetData(this.passengerId, AppSettings.ENTITY_CODE.PASSENGER);
      this.selectedPassenger.push(this.passenger);
      this.bookingService.setSelectedPassenger(this.passenger); 
      this.isPassengerSelected = true;
      this.cd.detectChanges();
    }
    
  }

  onSelectRequestPassenger(selected) {
    this.passenger = selected.value;
    this.requestedPassengerId = selected.value.id;
    this.getPassengerSetData(this.requestedPassengerId, AppSettings.ENTITY_CODE.PASSENGER);
    this.isRequestedPassengerSelected = true;
    if (this.requestedByChecked) {
      this.requestedPassenger.push(this.passenger);
      this.bookingService.setRequestedPassenger(this.passenger);
      this.miFormGroup.controls['requested_by'].setValue(this.requestedPassengerId);
    }
  }

  getPassengerSetData(passengerId, ENTITY_CODE, API_CALL?) {
    this.entityService.getEntity(passengerId, ENTITY_CODE, AppSettings.VIEW_CODE.DETAIL_VIEW).subscribe((result: any) => {
    });
  }

  coPassengerInfoChange(event) {
    const newState = event.checked;
    this.coPassengerInfo = newState;
  }

  petInfoChange(event) {
    const newState = event.checked;
    this.petInfo = newState;
  }

  onRequestedBySwitchChange(event) {
    const newState = event.checked;
    if (!newState) {
      this.requestedPassenger = [];
    }
    this.requestedByChecked = newState;
  }

  removePassenger(passenger, requestPass = false) {
    if (requestPass) {
      this.requestedPassenger = this.requestedPassenger.filter(p => p.id !== passenger.id);
      this.bookingService.removePassenger(passenger.id, 'requested');
      this.miFormGroup.controls['requested_by'].setValue(null);
      this.isRequestedPassengerSelected = false;

      if (this.requestedPassenger.length === 0) {
        this.requestedByChecked = false;
        this.cd.detectChanges();
      }
      if (this.currentRequestedPassenger?.id === passenger.id) {
        this.currentRequestedPassenger = null;
      }
    } else {
      this.selectedPassenger = this.selectedPassenger.filter(p => p.id !== passenger.id);
      this.bookingService.removePassenger(passenger.id, 'selected');
      // this.miFormGroup.controls['booking_for'].setValue(null);
      this.isPassengerSelected = false;
      if (this.currentSelectedPassenger?.id === passenger.id) {
        this.currentSelectedPassenger = null;
      }
    }
  }

  createNewPassenger(event: MouseEvent) {
    event.stopPropagation();
    this.router.navigate(['app/passengers/add']);
  }

  private setTrip(value) {
    this.isRoundTrip = this.roundTripValue.includes(value);
    const returnTimeAndDateCode = 'return_time_and_date';

    if (!this.isRoundTrip) {
      this.miFormGroup.get(returnTimeAndDateCode).setValidators(null);
      this.miFormGroup.get(returnTimeAndDateCode).updateValueAndValidity();

    } else {
      this.isHourlyTrip = value === "Hourly Trip" ? true : false;
      this.miFormGroup.get(returnTimeAndDateCode).setValidators(Validators.required);
      this.miFormGroup.get(returnTimeAndDateCode).updateValueAndValidity();
    }
  }

  showAssignVehicle() {
    this.isVisibleAssignVehicle = !this.isVisibleAssignVehicle;
  }

  initializeVars() {
    this.items = [
      {
        label: 'Refresh',
        icon: 'pi pi-refresh'
      },
      {
        label: 'Search',
        icon: 'pi pi-search'
      },
      {
        separator: true
      },
      {
        label: 'Delete',
        icon: 'pi pi-times'
      }
    ];
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
  }


  fetchBookingLocationAddress(attributeCode: string, viewCode: string) {
    const requestBody = {
      viewCode: viewCode,
      countryCode: this.country[0].countryCode,
      forTenantCode: this.configService.getForTenantCode(),
      searchText: "",
      actionStatus: "",
      deleted: AppSettings.DELETED_TYPE.ONLY_NON_DELETED
    }
    return this.entityService.autoComplete(AppSettings.ENTITY_CODE.BOOKING, requestBody).pipe(
      tap(res => {
        let attributeKey;
        if (attributeCode === 'booking_pickup_location_geolocation') {
          attributeKey = 'booking_pickup_location_address';
          this.setPresetValuesToLocationsFields(res.data, attributeKey, attributeCode);


        } else if (attributeCode === 'booking_dropoff_location_geolocation') {
          attributeKey = 'booking_dropoff_location_address';
          this.setPresetValuesToLocationsFields(res.data, attributeKey, attributeCode);
          this.setPresetValuesToLocationsFields(res.data, 'booking_stops_location_address', 'booking_stops_location_geolocation');
        }
      }));
  }


  setPresetValuesToLocationsFields(data, attributekey, attributeCode) {
    const locationAddress = data.map(item => item.values) || [];
    const locationFilter = _.chain(this.groups).find({ code: 'transportation' }).get('fields').find({ attributeCode }).value();
    if (locationFilter) {
      locationFilter.presetValues = locationAddress.map(address => {
        if (attributekey === 'booking_stops_location_address') {
          return address?.[attributekey]?.map((add, index) => {
            return {
              labelKey: add,
              labelValue: {
                address: add,
                geoLocation: address?.[attributeCode][index]
              }
            };

          })[0]
        } else if (address?.[attributekey]) {
          return {
            labelKey: address?.[attributekey],
            labelValue: {
              address: address?.[attributekey],
              geoLocation: address?.[attributeCode]
            }
          };

        }
      }
      ).filter(Boolean);
    }
  }

  getValues() {
  }

  get value() {
    return this.miFormGroup.value;
  }

  clearAllControls() {
    if(this.miFormGroup?.controls) {
      Object.keys(this.miFormGroup?.controls).forEach(controlName => {
        this.miFormGroup.removeControl(controlName);  // This will remove each control
      });
    }
  }

  private buildForm() {
    this.clearAllControls();
    this.buildGroups();
  }

  private buildGroups() {
    const miFormControl = this.getFormControl();
    this.miFormGroup = new FormGroup(miFormControl);
  }

  resetForm() {
    this.miFormGroup.reset();
  }

  private getFormControl() {
    _.remove(this.groups, {
      code: "passenger"
    });

    for (const group of this.groups) {
      for (const field of group.fields) {
        this.currentSelectedCode = field.inputCode;
        const validators = this.validationsService.addValidator(field.validation, this.currentSelectedCode);
        field.fieldName = field.attributeCode;
        const value = this.attributeValues ? this.attributeValues[field?.attributeCode] : '';
        if (field.attributeCode === this.stopsAttributeCode) {
          this.stopField = field;
          this.formGroupFields[this.stopPlaceAttributeCode] = new FormArray([new FormControl(value, validators)], validators);
        } else {
          this.formGroupFields[field.attributeCode] = new FormControl(value, validators);
        }
      }
    }
    this.formGroupFields[this.assignVehicleControlCode] = new FormControl();
    this.formGroupFields[this.selectVehicleCode] = new FormControl();
    return this.formGroupFields;
  }

  addStops() {
    const stop = new FormControl('');
    this.formGroupFields[this.stopPlaceAttributeCode].push(stop);
  }

  get stops() {
    return this.miFormGroup.get(this.stopPlaceAttributeCode) as FormArray;
  }

  onSwitchChange (event) {
    this.autoToManual =  event?.currentTarget?.checked;
    this.switchChanged.emit(event?.currentTarget?.checked);
  }

  submitData(event) {
    if (this.selectedPassenger?.length === 0 && this.transportationTypeView !== "Vehicle Transfer") {
      this.messageService.add({key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel('bookings.msg_please_select_passengers')});
      return;
    }


    if (this.miFormGroup.valid) {
      const vehicleAssignmentData = this.additionalRequiredData ?? {};
      this.saveData.next({formData: this.miFormGroup.value, vehicleAssignmentData});
    } 
    else {
      this.miFormGroup.markAllAsTouched();
      const firstElementWithError = document.querySelector('.ng-invalid');
      this.scrollTo(firstElementWithError);
    }
  }

  additionalRequired(event) {
    this.additionalRequiredData = event;
  }

  scrollTo(el: Element): void {
    if (el) {
      el.scrollIntoView({ behavior: 'smooth' });
    }
  }

  getIcon(id) {
    return this.bookingIcons[id];
  }

  removeStops(index) {
    this.stops.controls.splice(index, 1);
  }

  onToggleOverLay(event) {
    this.isVisibleAssignVehicle = event;
  }

  onSelectedVehicle(vehicle) {
    console.log(vehicle);
    this.selectVehicle.presetValues.push({
      labelKey: vehicle.name_code,
      labelValue: vehicle.id,
      parentLabelKey: ''
    });
    this.miFormGroup.controls['vehicle_to_transfer'].setValue(vehicle.id);
  }

  private sectionHighlight() {
    this.miFormGroup.valueChanges.subscribe(value => {
      this.formValue = value;
      this.groups.forEach(group => {
        group.codeActive = false;
        group.fields.forEach(field => {
          if (this.formValue.hasOwnProperty(field.attributeCode) && this.formValue[field.attributeCode]) {
            group.codeActive = true;
          }
        });
      });

    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription?.unsubscribe());
  }

}

import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as dayjs from 'dayjs';
import * as _ from 'lodash';
import { BehaviorSubject, Observable } from 'rxjs';
import { RestApiService } from '../../shared/services/rest-api.service';
import { AppSettings } from './../../shared/app.settings';
import { StorageService } from './../../shared/services/storage.service';
import { ConfigService } from './config.service';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { MetaDataService } from './meta-data.service';
import { Validators } from '@angular/forms';
@Injectable()
export class CommonBindingDataService {
  data = {
    "limit": 20,
    "offset": 0
  }
  searchStr;
  private dataFromMiSelect = new BehaviorSubject<any>(null);
  data$ = this.dataFromMiSelect.asObservable();
  private pickupCheckSubject = new BehaviorSubject<any>(null);
  pickupCheck$ = this.pickupCheckSubject.asObservable();
  private dropCheckSubject = new BehaviorSubject<any>(null);
  dropCheck$ = this.dropCheckSubject.asObservable();
  private passengerTypeSubject = new BehaviorSubject<any>(null);
  passengerType$ = this.passengerTypeSubject.asObservable();
  public seededPlayerSelected = new BehaviorSubject({});

  constructor(private restApiService: RestApiService,
    private storageService: StorageService,
    private translateService: TranslateService,
    private configService: ConfigService,
    private router: Router,
    private messageService: MessageService,
    private metaDataService: MetaDataService,
  ) { }

  sendDataToEntitySelect(data: any) {
    this.dataFromMiSelect.next(data);
  }

  getAttributeLabels(data) {
    const attributeLabels = [];
    if (data?.attributeLabels) {

      for (const labelsObj of data.attributeLabels) {
        attributeLabels[labelsObj.labelKey] = labelsObj.labelValue
      }
      if (data?.relatedAttributes) {
        for (const Attributes of data?.relatedAttributes) {
          for (const labelsObj of Attributes.attributeLabels) {
            attributeLabels[labelsObj.labelKey] = labelsObj.labelValue
          }
        }
      }
    } 
    return attributeLabels;
  }

  getAttributeLabelsForDriver(data) {
    const attributeLabels = {};
    for (const labelsObj of data.attributeLabels) {
      attributeLabels[labelsObj.labelKey] = labelsObj.labelValue;
    }
    if (data?.relatedAttributes) {
      for (const attribute of data.relatedAttributes) {
        if (attribute.attributeLabels) {
          for (const labelsObj of attribute.attributeLabels) {
            attributeLabels[labelsObj.labelKey] = labelsObj.labelValue;
          }
        }
      }
    }
    return attributeLabels;
  }

  getTableData(data) {
    let tableData = [];
    const resData = data?.data;
    if (data?.data[0]?.values) {
      for (const data of resData) {
        tableData.push({
          id: data.id,
          deleted: data.deleted,
          relatedData: data?.relatedData,
          ...data.values
        });
      }
    } else {
      tableData = [];
    }
    return tableData;
  }

  getTableDataForSettings(data) {
    let tableData = [];
    const resData = data?.data;
    if (data?.data[0]?.labelValue) {
      for (const data of resData) {
        tableData.push({
          attributePresetLabelId: data.attributePresetLabelId,
          labelValue: data.labelValue,
          usage: data.usage,
          ...data
        });
      }
    } else {
      tableData = [];
    }
    return tableData;
  }

  getTableDataForTags(data) {
    let tableData = [];
    const resData = data?.data;
    if (resData && resData.length) {
      tableData = resData.map(item => ({
        tagId: item.tagId,
        tenantId: item.tenantId,
        countryId: item.countryId,
        label: item.label,
        labelCode: item.labelCode,
        priority: item.priority,
        icon: item.icon,
        customValue: item.customValue,
      }));
    }
    return tableData;
  }

  getOrgAttributeValues(data) {
    const attributeValues = [];
    for (const labelsObj of data.attributeCodeValueDtoList) {
      attributeValues[labelsObj.attributeCode] = labelsObj.attributeValue
    }
    return attributeValues
  }


  getPassengerData(dataArray) {
    return dataArray.map(data => {
      const attributeValues = [];
      attributeValues['booking_pass_entityId'] = data.entityId;
      const attributeData = this.getOrgAttributeValues(data)

      return _.merge(attributeValues, attributeData);
    });
  }

  getColumns(data) {
    const orgData = data;
    const fields = [];
    for (const tab of orgData.tabs) {
      for (const group of tab.groups) {
        fields.push(...group.fields)
      }
    }

    const cols = _.sortBy(fields,
      [function(o) {
        return o.attributeIndex;
      }]);

    return cols;

  }

  getFilterList(data) {
    const filterList = _.remove(data, (n) => {
      return n.filterIndex > 0;
    });

    return filterList;
  }

  getAttributeIdForFilterFields(filterList, attributeCode) {
    return filterList.find(ele => ele.attributeCode === attributeCode)?.attributeId;
  }

  getOrgColumnData(data) {
    for (const tab of data.tabs) {
      for (const group of tab.groups) {
        if (group.entityRelationshipConfigId) {
          const configId = group.entityRelationshipConfigId
          for (const relation of data.relations) {
            if (configId === relation.entityRelationshipConfigId) {
              group.relation = relation;
              for (const attribute of data.relatedAttributes) {
                if (relation.otherEntityCode === attribute.entityCode) {
                  group.fields = attribute.tabs[0].groups[0].fields;
                }
              }
            }
          }
        } else {
          group.relation = { relation: 'oneToOne', }
        }
      }
    }
    return data;

  }

  getOrganizedAttribute(data) {
    data.tabs = _.sortBy(data.tabs, 'tabIndex');
    for (const tab of data.tabs) {
      tab.groups = _.sortBy(tab.groups, 'index');
      for (const group of tab.groups) {
        group.fields = _.sortBy(group.fields, 'attributeIndex');
        if (group.entityRelationshipConfigId) {          
          if (group?.fields[0].inputCode === "entitySection") { 
            const configId = group.entityRelationshipConfigId
            for (const relation of data.relations) {
              if (configId === relation.entityRelationshipConfigId) {
                group.relation = relation;
                for (const attribute of data.relatedAttributes) {
                  if (relation.otherEntityCode === attribute.entityCode) {
                    group.fields = attribute?.tabs[0]?.groups[0]?.fields;
                    group.fields = _.sortBy(group.fields, 'attributeIndex');
                  }
                }
              }
            }
          }
        } else {
          group.relation = { relation: 'oneToOne', }
        }
      }
    }
    return data;

  }

  fetchTabsGroupsData(data) {
    const result = [];

    // Sort tabs by tabIndex
    const sortedTabs = _.sortBy(data.tabs, 'tabIndex');

    for (const tab of sortedTabs) {
        const tabData = {
            tabCode: tab.tabCode,
            groups: []
        };

        // Sort groups by index
        const sortedGroups = _.sortBy(tab.groups, 'index');

        for (const group of sortedGroups) {
            const groupData = {
                code: group.code,
                fields: [],
                entityRelationshipConfigId: group.entityRelationshipConfigId
            };

            // Sort fields by attributeIndex
            const sortedFields = _.sortBy(group.fields, 'attributeIndex');

            for (const field of sortedFields) {
                groupData.fields.push({
                    attributeId: field.attributeId,
                    attributeCode: field.attributeCode,
                    attributeValue: field.attributeValue,
                    inputCode: field.inputCode,
                    presetValues: field.presetValues,
                });
            }

            tabData.groups.push(groupData);
        }

        result.push(tabData);
    }

    return result;
  }


  setDependsOnAttributeCode(data) {
    for (const item of data) {
      for (const group of item.groups) {
        const attributeMapping: { [key: number]: string } = {};
        for (const field of group.fields) {
          attributeMapping[field.attributeId] = field.attributeCode;
        }
        for (const field of group.fields) {
          if (field.inputCode === 'selectDependOnWithOther' && field.dependsOnAttributeId !== 0) {
            field.dependsOnAttributeCode = attributeMapping[field.dependsOnAttributeId] || null;
          }
        }
      }
    }
    return data;
  }

  mapAttributeIds(data, view, type?) {
    const attributeIdLookup = {};

    if (type === 'filter') {
      view.forEach(field => {
        attributeIdLookup[field.attributeCode] = field.attributeId;
      });
    } else {
      view.forEach(tab => {
        tab.groups.forEach(group => {
          if (!group.entityRelationshipConfigId) {
            group.fields.forEach(field => {
              attributeIdLookup[field.attributeCode] = field.attributeId;
            });
          } else {
            group.fields.forEach(field => {
              attributeIdLookup[field.attributeCode] = field.attributeId;
            });
          }
        });
      });
    }

    return data.map(item => {
      const attributeId = attributeIdLookup[item.attributeCode];
      if (attributeId) {
        return {
          attributeId,
          attributeValue: item.attributeValue
        };
      }
      return item;
    });
  }

  mapAttributeIdsForRelatedData(data, view, type?) {
    const attributeIdLookup = {};

    if (type === 'filter') {
      view.forEach(field => {
        attributeIdLookup[field.attributeCode] = field.attributeId;
      });
    } else {
      view.forEach(tab => {
        tab.groups.forEach(group => {
          if (group.entityRelationshipConfigId) {
            group.fields.forEach(field => {
              attributeIdLookup[field.attributeCode] = field.attributeId;
            });
          }
        });
      });
    }

    return data.map(item => {
      const attributeId = attributeIdLookup[item.attributeCode];
      if (attributeId) {
        return {
          attributeId,
          attributeValue: item.attributeValue
        };
      }
      return item;
    });
  }


  getOrganizedViewAttribute(data) {
    if (data?.tabs.length === 0) {
      data.tabs = [{
        tabCode: "default",
        tabIndex: 0,
        groups: [
          {
            code: "default",
            entityRelationshipConfigId: null,
            fields: []
          }
        ]
      }]
    }

    for (const attribute of data.relatedAttributes || []) {
      const fields = attribute.tabs[0].groups[0].fields;
      data.tabs[0].groups.push(_.sortBy(fields, 'attributeIndex'));
    }


  }


  getCountry() {
    return JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
  }
  getLanguage() {
    return JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
  }

  getOrganizedAttributeForDriver(data) {
    data.tabs = _.sortBy(data.tabs, 'tabIndex');
    for (const tab of data.tabs) {
      tab.groups = _.sortBy(tab.groups, 'index');
      for (const group of tab.groups) {
        group.fields = _.sortBy(group.fields, 'attributeIndex');
        if (group.entityRelationshipConfigId) {
          const configId = group.entityRelationshipConfigId
          for (const relation of data.relations) {
            if (configId === relation.entityRelationshipConfigId) {
              group.relation = relation;
              for (const attribute of data.relatedAttributes) {
                if (relation.otherEntityCode === attribute.entityCode) {
                  group.fields = attribute.tabs[0].groups[0].fields;
                  group.code = attribute.entityCode;
                }
              }
            }
          }
        } else {
          group.relation = { relation: 'oneToOne', }
        }
      }
    }
    return data;
  }

  setSearchData(data) {
    this.searchStr = data;
  }

  getSearchData() {
    return this.searchStr;
  }

  getCountryDetails(): Observable<any> {
    if (this.configService.getForTenantCode()) {
      return this.restApiService.post('COUNTRY SEARCH', `${this.configService.getForTenantCode()}/api/v1/countries/search?forTenantCode=${this.configService.getForTenantCode()}`, this.data, 'page-center');
    } else {
      this.router.navigate(['/auth/login']);
    }
  }

  getTenants(tenantId): Observable<any> {
    return this.restApiService.get('Get Tenants', `tsys/api/v1/tenants/${tenantId}`);
  }

  getLanguageDetails(): Observable<any> {
    if (this.configService.getForTenantCode()) {
      return this.restApiService.post('GET LANGUAGE DETAILS', `${this.configService.getForTenantCode()}/api/v1/languages/search?forTenantCode=${this.configService.getForTenantCode()}`, this.data, 'page-center');
    } else {
      this.router.navigate(['/auth/login']);
    }
  }

  generateUploadFileSignedUrl(data) {
    return this.restApiService.post('generate isgned url for file', `${this.configService.getLoggedInTenantCode()}/api/v1/files/signed/upload`, data, 'page-center');
  }

  uploadFile(file, urlForFileUpload, headers): Observable<any> {
    return this.restApiService.imagePostRequest('PUT', 'FILE UPLOAD', `${urlForFileUpload}`, file, headers);
  }

  verifyUploadedFile(data, docType) {
    return this.restApiService.post('verify uploaded file', `${this.configService.getLoggedInTenantCode()}/api/v1/files/${docType}/verify`, data, 'page-center');
  }

  importEntity(file, entityCode): Observable<any> {
    return this.restApiService.importEntities('FILE UPLOAD', `${this.configService.getForTenantCode()}/api/v1/entities/${entityCode}/files/import-entities`, file);
  }

  getFormattedAddressFromLatLong(latLong, key) {
    return this.restApiService.getFormattedAddress('formatted address', `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latLong}&key=${key}`, 'page-center');
  }

  getLatLngFromAddress(address, key) {
    return this.restApiService.getFormattedAddress('formatted address', `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${key}`, 'page-center');
  }

  getLabelValue(checkLabelKey, attributeLabels, defaultValue) {
    return attributeLabels?.[checkLabelKey] || defaultValue;
  }

  toGMT(now) {
    return new Date(now.getTime() - (now.getTimezoneOffset() * 60000)).getTime();
  }

  toLocalTime(miliSeconds) {
    const now = new Date(miliSeconds);
    return new Date(miliSeconds + now.getTimezoneOffset() * 60000).getTime();
  }

  toLocalDate(miliSeconds) {
    const now = new Date(miliSeconds);
    return new Date(miliSeconds + now.getTimezoneOffset() * 60000);
  }


  unitConversionMToKm(m) {
    const results = m / 1000;
    if (results > 0) {
      return results;
    } else {
      return 0;
    }
  }

  unitConversionKMToM(km) {
    const results = km * 1000;
    if (results > 0) {
      return results;
    } else {
      return 0;
    }
  }

  unitConversionMinToMiliseconds(min) {
    const results = ((min * 60) * 1000);
    if (results > 0) {
      return results;
    } else {
      return 0;
    }
  }

  unitConversionMilisecondsToMin(mili) {
    const results = ((mili / 60) / 1000);
    if (results > 0) {
      return results;
    } else {
      return 0;
    }
  }

  centerLatLng(dropPoints) {
    if (dropPoints.lenght === 0) {
      return 0;
    }
    const latArray = [];
    const lngArray = [];
    for (const points of dropPoints) {
      latArray.push(points.lat);
      lngArray.push(points.lng);
    }
    return this.findCenterDashLatLng(lngArray, latArray);
  }

  findCenterDashLatLng(lngArray, latArray) {
    const x1 = this.findMinMaxValue('min', lngArray);
    const x2 = this.findMinMaxValue('max', lngArray);
    const y1 = this.findMinMaxValue('min', latArray);
    const y2 = this.findMinMaxValue('max', latArray);
    const centerPoly = {
      lat: y1 + ((y2 - y1) / 2),
      lng: x1 + ((x2 - x1) / 2)
    };
    return centerPoly;
  }

  findMinMaxValue(type, data) {
    if (type === 'max') {
      return Math.max.apply(null, data);
    } else {
      return Math.min.apply(null, data);
    }
  }


  getSpecificTimeout(code, subcode) {
    const timeouts = this.storageService.getItem(AppSettings.TIME_SETTINGS);
    if (timeouts !== null) {
      for (const item of timeouts[code]) {
        if (item.key === subcode) {
          return parseInt(item.value, 10);
        }
      }
    }
  }


  getLabel(string) {
    let select;
    this.translateService.get(string).subscribe(translatedValue => {
      select = translatedValue;
    });
    return select;
  }

  telFormat(value) {
    if (value) {
      return value.replace(/^(\d{3})(\d{3})(\d{4}).*/, '($1) $2-$3');
    } else {
      return '';
    }
  }

  getState(response) {
    const states = [{
      label: this.getLabel('lbl_please_select'),
      value: ''
    }];
    if (response) {
      for (const item of response) {
        states.push({
          label: item.name,
          value: item.stateId
        });
      }
    }
    return states;
  }

  filterNullAttributeValues(a) {
    return _.filter(_.uniq(a.filters, function(item, key, a) {
      return item.attributeValue;
    }), function(element) {
      return element.attributeValue
    });
  }


  getAgeGroup(response) {
    const states = [{
      label: this.getLabel('lbl_please_select'),
      value: ''
    }];
    if (response) {
      for (const item of response) {
        states.push({
          label: item.ageGroupLabel,
          value: item.ageGroupId
        });
      }
    }
    return states;
  }

  getCities(response) {
    const cities = [{
      label: this.getLabel('lbl_please_select'),
      value: ''
    }];
    if (response) {
      for (const item of response) {
        cities.push({
          label: item.name,
          value: item.cityId
        });
      }
    }
    return cities;
  }

  getDosageDays(response) {
    const medicationDosageDays = [];
    if (response) {
      for (const item of response) {
        medicationDosageDays.push({
          label: item.medicationDosageDayLabel,
          value: item.medicationDosageDayId
        });
      }
    }
    return medicationDosageDays;
  }

  getDosageUnits(response) {
    const medicationDosageUnits = [{
      label: this.getLabel('lbl_please_select'),
      value: ''
    }];
    if (response) {
      for (const item of response) {
        medicationDosageUnits.push({
          label: item.medicationDosageUnitText,
          value: item.medicationDosageUnitId
        });
      }
    }
    return medicationDosageUnits;
  }

  getAnswerType(response) {
    const answerType = [];
    if (response) {
      for (const item of response) {
        answerType.push({
          label: item.answerTypeLabel,
          value: item.answerTypeId
        });
      }
    }
    return answerType;
  }

  getMedicatinDosageSkipReasons(response) {
    const medicationSkipReasons = [];
    if (response) {
      for (const item of response) {
        medicationSkipReasons.push({
          label: item.dosageSkipReason,
          value: item.dosageSkipReasonId
        });
      }
    }
    return medicationSkipReasons;
  }

  getMedicationFreqeuncies(response) {
    const medicationFrequencies = [{
      label: this.getLabel('lbl_please_select'),
      value: {}
    }];
    if (response) {
      for (const item of response) {
        medicationFrequencies.push({
          label: item.medicationDosageFrequencyLabel,
          value: {
            medicationDosageFrequencyId: item.medicationDosageFrequencyId,
            defaultTimes: item.defaultTimes,
          }
        });
      }
    }
    return medicationFrequencies;
  }

  msToHM(milliseconds: number) {

    let seconds = milliseconds / 1000;

    const hours = seconds / 3600;
    seconds = seconds % 3600;

    const minutes = seconds / 60;

    seconds = seconds % 60;
    return hours + ':' + minutes;
  }

  timeToMilliseconds(mins, hh) {
    return (mins * 60000) + (hh * 60 * 60000);
  }

  gmtTOHHMM(time) {
    return (dayjs(parseInt(time, 10)).format('HH:mm'));
  }

  duplicatesFromArray(arr) {
    const sorted_arr = arr.slice().sort();
    const results = [];
    for (let i = 0; i < sorted_arr.length - 1; i++) {
      if (sorted_arr[i + 1] === sorted_arr[i]) {
        results.push(sorted_arr[i]);
      }
    }
    return results;
  }

  close(event: any) {
    const divsToHide = document.getElementsByClassName('grid-menu');
    for (let i = 0; i < divsToHide.length; i++) {
      divsToHide[i]['style'].display = 'none';
    }
  }

  weekOfTheDay(start) {
    return dayjs(start).startOf('week').format('MMM D') + '-' + dayjs(start).endOf('week').format('MMM D');
  }


  millisecondsToTime(scheduledTime) {
    scheduledTime = parseInt(scheduledTime, 10);
    const hh = parseInt(dayjs(scheduledTime).format('HH'), 10);
    const mm = parseInt(dayjs(scheduledTime).format('mm'), 10);
    return hh + ':' + mm;
  }


  setWeeklyDays(startDate: number, endDate: number) {
    const tz = dayjs().utcOffset();
    const weekly = [];
    weekly.push({
      label: this.getLabel('lbl_trial_period'),
      value: {
        start: startDate,
        end: endDate
      }
    }, {
      label: this.weekOfTheDay(startDate),
      value: {
        start: dayjs(startDate).startOf('week').valueOf(),
        end: dayjs(startDate).endOf('week').valueOf()
      }
    });

    let start = dayjs(startDate).startOf('week').add(7, 'd').valueOf();
    while (start < endDate) {
      weekly.push({
        label: this.weekOfTheDay(start),
        value: {
          start: dayjs(start).startOf('week').valueOf(),
          end: dayjs(start).endOf('week').valueOf()
        }
      });

      start = dayjs(start).startOf('week').add(7, 'd').valueOf();
    }
    return weekly;
  }


  getUTCDateWithOutLocalTime(time) {
    const tz = dayjs().utcOffset();
    time = time - (tz * 60000);
    return dayjs(time).format('ll');
  }

  convertToTimestamp(selectedDate: any): number {
    return new Date(selectedDate).getTime();
  }

  getStartAndEndOfWeek(diff) {
    const currentDate = new Date();
    const currentDay = currentDate.getDay();
    const offset = currentDay === 0 ? -6 : 1 - currentDay + diff;
    const monday = new Date(currentDate);
    monday.setDate(currentDate.getDate() + offset);
    const sunday = new Date(monday);
    sunday.setDate(monday.getDate() + 6);
    return { start: monday.getTime(), end: sunday.getTime() };
  }

  getStartAndEndOfMonth(offset) {
    const currentDate = new Date();
    const firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + offset, 1);
    const lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + offset + 1, 0);
    return { start: firstDay.getTime(), end: lastDay.getTime() };
  }

  getStartAndEndOfYear(offset) {
    const currentDate = new Date();
    const firstDay = new Date(currentDate.getFullYear() + offset, 0, 1);
    const lastDay = new Date(currentDate.getFullYear() + offset, 11, 31);
    return { start: firstDay.getTime(), end: lastDay.getTime() };
  }



  getGeoLocations(booking) {

    let pickup = [];
    let dropoff = [];
    let stops = [];

    if (booking.booking_pickup_location_geolocation) {
      pickup = booking.booking_pickup_location_geolocation.split(",");
    }
    if (booking.booking_dropoff_location_geolocation) {
      dropoff = booking.booking_dropoff_location_geolocation.split(",");
    }
    if (booking?.booking_stops_location_geolocation) {
      stops = booking.booking_stops_location_geolocation.map(location => location.split(","));
    }

    return {
      pickup: pickup,
      dropoff: dropoff,
      stops: stops
    };

  }

  getBrowserLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;

          return { lat: lat, lng: lng }
        }
      );
    } else {
      return { lat: -25.344, lng: 131.031 }
    }
  }

  removeDocumentByIndex(data, index) {
    const prefix = `${1000 + index}`;
    const keysToDelete = _.keys(data).filter(key => _.startsWith(key, prefix));
    keysToDelete.forEach(key => _.unset(data, key));
    return data;
  }

  setDuration(data) {
    data?.data.forEach((element: any) => {
      const assignedAt = new Date(element.values.assigned_at).getTime();
      const releasedAt = element.values.released_at ? new Date(element.values.released_at).getTime() : null;

      if (releasedAt === null) {
        element.values.duration = '--';
        return;
      }
      const duration = releasedAt - assignedAt;

      const millisecondsInASecond = 1000;
      const secondsInAMinute = 60;
      const minutesInAnHour = 60;
      const hoursInADay = 24;
      const daysInAWeek = 7;

      const totalSeconds = duration / millisecondsInASecond;
      const totalMinutes = totalSeconds / secondsInAMinute;
      const totalHours = totalMinutes / minutesInAnHour;
      const totalDays = totalHours / hoursInADay;
      const totalWeeks = totalDays / daysInAWeek;

      const weeks = Math.floor(totalWeeks);
      const days = Math.floor(totalDays % daysInAWeek);
      const hours = Math.floor(totalHours % hoursInADay);
      const minutes = Math.floor(totalMinutes % minutesInAnHour);

      const showDuration = [];
      if (weeks > 0) showDuration.push(`${weeks}w`);
      if (days > 0) showDuration.push(`${days}d`);
      if (hours > 0) showDuration.push(`${hours}h`);
      if (minutes > 0 || showDuration.length === 0) showDuration.push(`${minutes}m`);

      const formattedDuration = showDuration.join(' ');
      element.values.duration = formattedDuration;
    });
  }


  getDate15DaysBefore(): string {
    const today = new Date();
    const fifteenDaysAgo = new Date(today.getTime() - (15 * 24 * 60 * 60 * 1000));
    fifteenDaysAgo.setHours(0, 0, 0, 0);
    return this.formatDate(fifteenDaysAgo);
  }

  getDate15DaysAfter(): string {
    const today = new Date();
    const fifteenDaysAfter = new Date(today.getTime() + (15 * 24 * 60 * 60 * 1000));
    fifteenDaysAfter.setHours(23, 59, 59, 999);
    return this.formatDate(fifteenDaysAfter);
  }

  adjustAndFormatDate = (dateStr: string, isEndOfDay: boolean): string => {
    const date = new Date(dateStr);
    if (isEndOfDay) {
      date.setHours(23, 59, 59, 999);
    } else {
      date.setHours(0, 0, 0, 0);
    }
    return this.formatDate(date);
  };

  formatDate = (date: Date): string => {
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  formatDateFromTimestamp(timestamp: number): string {
    const date = new Date(timestamp);
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  formatDateToDDMMYYYY(date) {
    if (!(date instanceof Date)) {
      throw new Error('Invalid date object');
    }
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  adjustAndFormatMinDate(dateStr: string, isEndOfDay: boolean): Date {
    const date = new Date(dateStr);
    if (isEndOfDay) {
      date.setHours(23, 59, 59, 999);
    } else {
      date.setHours(0, 0, 0, 0);
    }
    return date;
  }


  onDisplayMessage(data: any, moduleName: string, action: string, type: string) {
    const messageKey = `${moduleName}.message.${type}.${action}`;
    const messageLabel = data.attributeLabels.find(attribute => attribute.labelKey === messageKey)?.labelValue;

    if (messageLabel) {
      this.messageService.add({ key: 'tst', severity: type === 'success' ? 'success' : 'error', summary: type === 'success' ? 'Success' : 'Error', detail: messageLabel });
    }
  }

  formatTimeAgo(timestamp: number): string {
    const now = new Date().getTime();
    const diff = now - timestamp; 
    const minutes = Math.floor(diff / 60000);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
  
    const remainingMinutes = minutes % 60;
    const remainingHours = hours % 24;
  
    let timeAgo = '';
  
    if (days > 0) {
      timeAgo += `${days} day${days > 1 ? 's' : ''} `;
    }
  
    if (remainingHours > 0) {
      timeAgo += `${remainingHours} hr${remainingHours > 1 ? 's' : ''} `;
    }
  
    if (remainingMinutes > 0 || (days === 0 && remainingHours === 0)) {
      timeAgo += `${remainingMinutes} min${remainingMinutes > 1 ? 's' : ''} `;
    }
  
    return `${timeAgo.trim()} ago`;
  }

  getLabelClass(status: string): string {
    const classes = {
      '1': 'default-text-blue-color',
      '2': 'default-text-blue-color',
      '3': 'default-text-blue-color',
      '4': 'default-text-blue-color',
      '5': 'default-text-blue-color',
      '6': 'text-green-color',
      '7': 'text-green-color',
      '8': 'text-green-color',
      '9': 'text-green-color',
      '10': 'text-green-color',
      '11': 'default-text-black-color',
      '12': 'text-dark-red-color',
    };
    return classes[status] || 'default-text-black-color';
  }

  getBgColorClass(status: string): string {
    const classes = {
      '1': 'background-color: rgb(221, 238, 255);color: rgb(30, 144, 255);',
      '2': 'background-color: rgb(221, 238, 255);color: rgb(30, 144, 255);',
      '3': 'background-color: rgb(221, 238, 255);color: rgb(30, 144, 255);',
      '4': 'background-color: rgb(221, 238, 255);color: rgb(30, 144, 255);',
      '5': 'background-color: rgb(221, 238, 255);color: rgb(30, 144, 255);',
      '6': 'background-color: rgb(224, 248, 224); color: rgb(0, 128, 0);',
      '7': 'background-color: rgb(224, 248, 224); color: rgb(0, 128, 0);',
      '8': 'background-color: rgb(224, 248, 224); color: rgb(0, 128, 0);',
      '9': 'background-color: rgb(224, 248, 224); color: rgb(0, 128, 0);',
      '10': 'background-color: rgb(224, 248, 224); color: rgb(0, 128, 0);',
      '11': 'background-color: rgb(230, 230, 230); color: rgb(38, 44, 45);',
      '12': 'background-color: rgb(255, 217, 217); color: rgb(178, 34, 34);',
    };
    return classes[status] || 'default-text-black-color';
  }

  getIconClass(status: string): string {
    const icons = {
      '1': 'bg-default-text-blue icon-star-outline wh-20 mr-2',
      '2': 'bg-default-text-blue user_circle wh-20 mr-2',
      '3': 'bg-default-text-blue mi-driver wh-20 mr-2',
      '4': 'bg-default-text-blue user_check wh-20 mr-2',
      '5': 'bg-default-text-blue reconfirmed wh-20 mr-2',
      '6': 'bg-green user-on-the-way wh-20 mr-2',
      '7': 'bg-green driver_arrived wh-20 mr-2',
      '8': 'bg-green driver_arrived wh-20 mr-2',
      '9': 'bg-green user-on-trip wh-20 mr-2',
      '10': 'bg-green mi-circle_check_outline wh-20 mr-2',
      '11': 'bg-default-text-black calendar_x wh-20 mr-2',
      '12': 'bg-cancel-icon mi-off_outline_close wh-20 mr-2',
    };
    return icons[status] || 'bg-default-text-black calendar_x';
  }

  updatePickupCheck(value: any) {
    this.pickupCheckSubject.next(value);
  }

  updateDropCheck(value: any) {
    this.dropCheckSubject.next(value);
  }

  getPassengerTypeValue(value: any) {
    this.passengerTypeSubject.next(value);
  }

  bindEntityDataToFields(tabs, response) {
    const dataValues = response.data[0].values;
    let result = [];
    tabs.forEach(tab => {
      if (tab.groups && Array.isArray(tab.groups)) {
        tab.groups.forEach(group => {
          if (group.fields && Array.isArray(group.fields)) {
            group.fields.forEach(field => {
              const fieldCode = field.attributeCode;
              if (dataValues.hasOwnProperty(fieldCode)) {
                result[fieldCode] = dataValues[fieldCode];
              } else {
                console.warn(`No data found for field: ${fieldCode}`);
              }
            });
          }
        });
      }
    });
    return result; 
  }

  getUnit(field) {
    const language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));   
    const unit = this.metaDataService.systemOfMeasurement;
    const tempUnit = unit === 'Imperial' ? JSON.parse(field?.imperialUnit) : JSON.parse(field?.metricUnit);
    const languageCode = language[0].langCode;
    return tempUnit[languageCode];
  }

  convertMilesToMeters(miles: number): number {
    const results = miles * 1609.34;
    if (results > 0) {
      return results;
    } else {
      return 0;
    }
  }

  convertKmToMeters(km: number): number {
    const results = km * 1000;
    if (results > 0) {
      return results;
    } else {
      return 0;
    }
  }

  convertMetersToMiles(meters: number): number {
    const results =  meters / 1609.34;
    if (results > 0) {
      return results;
    } else {
      return 0;
    }
  }

  convertMetersToKm(meters: number): any {
    const results = meters / 1000;
    if (results > 0) {
      return results;
    } else {
      return 0;
    }
  }

  getAttributeId(attributeCode: string, attributeData): number | undefined {
    for (const tab of attributeData.tabs) {
      for (const group of tab.groups) {
        const attribute = group.fields.find(field => field.attributeCode === attributeCode);
        if (attribute) {
          return attribute.attributeId;
        }
      }
    }
    return undefined;
  }

  getLabelIcon(key) {
    const iconMap = {
        "Trains": "mi-lg mi-request_for_transport wh-20",
        "Journeys With WC": "mi-lg mi-wheel_chair wh-20",
        "Journeys With Booster": "mi-lg mi-baby_seat wh-20",
        "Airport": "mi-lg mi-request_for_transport wh-20",
        "Vehicle Transfer": "mi-lg mi-vehicle_transfer wh-20",
        "Shuttle": "mi-lg mi-shuttle wh-20",
        "Journeys With Child Seat": "mi-lg mi-baby_seat wh-20",
        "Car Journey": "mi-lg mi-car wh-20",
        "Journeys With Infant Carrier": "mi-lg mi-infant_carrier wh-20"
    };

    return iconMap[key] || "mi-lg mi-infant_carrier wh-20";
  }

  getCountryCodeAndMobileNumber(mobileNumber: string, countryCodeWithName: any[]): { countryCodeForMobileNumber: string | null, mobileNumber: string | null } {
    const findFlag = (flagCode: string): string | undefined => {
        return countryCodeWithName.find(ele => ele.countryCode === flagCode)?.code;
    };

    if (mobileNumber?.includes('-')) {
      const parts = mobileNumber.split('-');
      const countryCode = findFlag(parts[0]);
      return {
        countryCodeForMobileNumber: countryCode || null,
        mobileNumber: parts[1] || null
      };
    } else {
      return {
        countryCodeForMobileNumber: null,
        mobileNumber: mobileNumber || null
      };
    }
  }
  
  addValidator(rules) {
    if (!rules) {
      return [];
    }
    const validators = [];
    (Object.keys(rules).map((rule) => {
      if (rule === "required" && rules.required == true) {
        validators.push(Validators.required);
      }

      if (rule === "min" && rules.min) {
        if (rules[rule] !== null) {
          validators.push(Validators.min(rules[rule] ? rules[rule] : 10))
        }
      }

      if (rule === "max" && rules.max) {
        if (rules[rule] !== null) {
          validators.push(Validators.max(rules[rule] ? rules[rule] : 50));
        }
      }

      if (rule === "pattern" && rules.pattern) {
        if (rules[rule] !== null) {
          const validatorRule = new RegExp([rules[rule]].join(''));
          validators.push(Validators.pattern(rules[rule] ? validatorRule : ''));
        }

      }
      if (rule === "unique" && rules.unique == true) {
        validators.push(Validators.pattern(''));

      }
    }));

    return validators;
  }

  getUniqueAttributeCodes(unique: string[]): string[] {
    const attributeCodeMapping = {
      'Accreditation ID': 'unique_id',
      'Employee ID': 'unique_id',
      'Unique ID': 'unique_id',
      'Email Address': 'email',
      'Contact Number': 'mobile_number'
    };
  
    const resultSet: Set<string> = new Set();
    unique.forEach(value => {
      if (attributeCodeMapping[value]) {
        resultSet.add(attributeCodeMapping[value]);
      }
    });
    return Array.from(resultSet);
  }

  transformString(input: string): string {
    return input?.toLowerCase().replace(/[\s\-]+/g, '_');
  }
}

import { NgClass } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppIcons } from 'app/modules/shared/app.icons';
import { AppSettings } from 'app/modules/shared/app.settings';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';

@Component({
  selector: 'app-input-select-button',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, NgClass],
  templateUrl: './input-select-button.component.html',
  styleUrl: './input-select-button.component.scss'
})
export class InputSelectButtonComponent {
  @Input() field: any;
  @Input() attributeLabels: [];
  @Input() moduleName: string;
  miIcons = AppIcons;
  formName: FormGroup;
  selectedPreset;

  constructor(private formGroupDirective: FormGroupDirective,
    public cs: CommonBindingDataService) {
    this.formName = this.formGroupDirective.control;
  }

  ngOnInit() {
    let type;
    if(this.moduleName === AppSettings.ENTITY_CODE.INCIDENTS_AND_COMPLAINT) {
      type = this.formName.controls['type'].value;
    }
    if (this.field?.presetValues && this.field.presetValues.length > 0) {
      this.selectedPreset = type ? type : this.field.presetValues[0].labelValue;
      this.formName.patchValue({[this.field?.attributeCode]: this.selectedPreset});
    }
  }

  selectPresetValue(value: string) {
    this.formName.patchValue({
      [this.field?.attributeCode]: value
    });
    this.selectedPreset = value;
    Object.keys(this.formName.controls).forEach(controlName => {
      if (controlName !== this.field?.attributeCode) {
        const control = this.formName.get(controlName);
        if (control) {
          control.reset();
          control.updateValueAndValidity();
        }
      }
    });
  }
}

<div class="flex flex-column pl-4 pt-0">
  <div class="flex flex-row align-items-center border-bottom-grey py-12px">
    <div class="w-5 default-text-light-black-color font-13 font-normal" translate>dispatch.journey_status</div>
    @if (bookingStatusStringValue) {
      <div class="w-7 journey-status flex flex-row justify-content-between">
        <div>
            <i [class]="bookingStatusIcon" class="mi-lg bg-green inline-block vertical-align-text-bottom cursor-pointer wh-18 mr-2"></i>
            <span class="font-13 font-bold text-green-color" translate>{{ bookingStatusStringValue }}</span>
        </div>
    </div>
    } @else {
      <label> - </label>
    }
  </div>
  <div class="flex flex-row border-bottom-grey py-12px">
    <div class="w-5 default-text-light-black-color font-13 font-normal" translate>dispatch.assign</div>
    <div class="w-7 flex flex-row align-items-center">
        <div class="flex align-items-center mr-3">
            <p-radioButton [disabled]="true" name="assign" value="Auto" [(ngModel)]="assignType" inputId="auto" #autoRadioButton></p-radioButton>
            <label [ngClass]="{'font-bold': autoRadioButton.checked}" for="auto" class="ml-2" translate>dispatch.auto</label>
        </div>
        
        <div class="flex align-items-center">
            <p-radioButton [disabled]="true" name="assign" value="Manual" [(ngModel)]="assignType" inputId="manual"></p-radioButton>
            <label for="manual" class="ml-2" translate>dispatch.manual</label>
        </div>
    </div>
  </div>

  <div class="flex flex-row border-bottom-grey py-12px">
    <div class="w-5 default-text-light-black-color font-13 font-normal" translate>dispatch.trans_type</div>
    <div class="w-7">
        <span>
          @switch (dispatchBookingDetails?.transportation_type) {
            @case (TRANSPORTATION_MODE.AIRPORT) {
              <i class="{{miIcons.BOOKING_AIRPORT_OLD}} bg-print-label-color inline-block vertical-align-text-bottom cursor-pointer wh-20 mr-2"></i>
            }

            @case (TRANSPORTATION_MODE.CAR) {
              <i class="{{miIcons.BOOKING_CAR_OLD}} bg-print-label-color inline-block vertical-align-text-bottom cursor-pointer wh-20 mr-2"></i>
            }

            @case (TRANSPORTATION_MODE.SHUTTLE) {
              <i class="{{miIcons.BOOKING_SHUTTLE_OLD}} bg-print-label-color inline-block vertical-align-text-bottom cursor-pointer wh-20 mr-2"></i>
            }

            @case (TRANSPORTATION_MODE.BUS) {
              <i class="{{miIcons.BOOKING_BUS}} bg-print-label-color inline-block vertical-align-text-bottom cursor-pointer wh-20 mr-2"></i>
            }

            @case (TRANSPORTATION_MODE.VEHICLE_TRANSFER) {
              <i class="{{miIcons.BOOKING_VEHICLE_TRANSFER}} bg-print-label-color inline-block vertical-align-text-bottom cursor-pointer wh-20 mr-2"></i>
            }
          }
        </span>
        <span class="font-15 font-bold default-text-black-color" translate>{{dispatchBookingDetails?.transportation_type}}</span>
    </div>
  </div>
  <div class="flex flex-row border-bottom-grey py-12px">
    <div class="w-5 default-text-light-black-color font-13 font-normal" translate>dispatch.pickup</div>
    <div class="w-7 default-text-black-color">
      @if (dispatchBookingDetails?.pickup_date) {
        <span class="font-15 font-bold default-text-black-color">
          {{dispatchBookingDetails?.pickup_time | date: 'hh:mm a'}} </span>,  <label>{{dispatchBookingDetails?.pickup_date | date: 'dd MMM yyyy'}} </label>
      } @else {
        <label>-</label>
      }
    </div>
  </div>
  <div class="flex flex-row border-bottom-grey py-12px">
    <div class="w-5 default-text-light-black-color font-13 font-normal" translate>dispatch.pickup_location</div>
    <div class="w-7 default-text-black-color">
        <span><i class="mi-lg mi-home bg-print-label-color inline-block vertical-align-text-bottom cursor-pointer wh-15 mr-1"></i></span>
        {{dispatchBookingDetails?.booking_pickup_location_address ? dispatchBookingDetails?.booking_pickup_location_address : '-'}}
    </div>
  </div>
  <div class="flex flex-row border-bottom-grey py-12px">
    <div class="w-5 default-text-light-black-color font-13 font-normal" translate>dispatch.stop_location</div>
    <div class="w-7">{{dispatchBookingDetails?.booking_stops_location_address ? dispatchBookingDetails?.booking_stops_location_address : '-'}}</div>
  </div>
  <div class="flex flex-row border-bottom-grey py-12px">
    <div class="w-5 default-text-light-black-color font-13 font-normal" translate>dispatch.drop_location</div>
    <div class="w-7 default-text-black-color">
        <span><i class="mi-lg mi-hotel bg-print-label-color inline-block vertical-align-text-bottom cursor-pointer wh-15 mr-1"></i></span>
        {{dispatchBookingDetails?.booking_dropoff_location_address ? dispatchBookingDetails?.booking_dropoff_location_address : '-'}}
    </div>
  </div>
  <div class="flex flex-row border-bottom-grey py-12px">
    <div class="w-5 default-text-light-black-color font-13 font-normal" translate>dispatch.total_pass</div>
    <div class="w-7 font-15 font-bold default-text-black-color">{{ (dispatchBookingDetails?.number_of_passenger ? dispatchBookingDetails?.number_of_passenger : null) }}</div>
  </div>
  <div class="flex flex-row border-bottom-grey py-12px">
    <div class="w-5 default-text-light-black-color font-13 font-normal" translate>dispatch.child_seats</div>
    <div class="w-7 flex flex-column">
        <div class="default-text-black-color" translate>dispatch.infant_carrier <span class="default-text-blue-color font-13 font-bold">
          {{dispatchBookingDetails?.infant_carrier ? dispatchBookingDetails?.infant_carrier : '-'}}</span>
        </div>
        <div class="default-text-black-color" translate>dispatch.child_seat <span class="default-text-blue-color font-13 font-bold">
          {{dispatchBookingDetails?.child_seat ? dispatchBookingDetails?.child_seat : '-'}}</span>
        </div>
    </div>
  </div>
  <div class="flex flex-row border-bottom-grey py-12px">
    <div class="w-5 default-text-light-black-color font-13 font-normal" translate>dispatch.luggages</div>
    <div class="w-7 default-text-blue-color font-15 font-bold">{{dispatchBookingDetails?.luggage ? dispatchBookingDetails?.luggage : '-'}}</div>
  </div>
  <div class="flex flex-row border-bottom-grey py-12px">
    <div class="w-5 default-text-light-black-color font-13 font-normal" translate>dispatch.wheelchairs</div>
    <div class="w-7">{{dispatchBookingDetails?.wheelchair ? dispatchBookingDetails?.wheelchair : '-'}}</div>
  </div>
  <div class="flex flex-row border-bottom-grey py-12px">
    <div class="w-5 default-text-light-black-color font-13 font-normal pr-4" translate>dispatch.add_vehicle_requirements</div>
    <div class="w-7 font-15 font-bold default-text-black-color">{{dispatchBookingDetails?.additional_passenger_vehicle ? dispatchBookingDetails?.additional_passenger_vehicle : '-'}}</div>
  </div>
  <div class="flex flex-row border-bottom-grey py-12px">
    <div class="w-5 default-text-light-black-color font-13 font-normal" translate>dispatch.add_requirements</div>
    <div class="w-7 flex flex-column">
      @if (dispatchBookingDetails?.journey_preference) {
        @for(journeyPref of dispatchBookingDetails?.journey_preference; track journeyPref; let i = $index){
          <label>{{ i + 1 }}. {{ journeyPref }}</label>
        }
      } @else {
        <label>-</label>
      }
        <!-- <div>{{dispatchBookingDetails?.journey_preference.toString()}}</div> -->
    </div>
  </div>
  <div class="flex flex-row border-bottom-grey py-12px">
    <div class="w-5 default-text-light-black-color font-13 font-normal" translate>dispatch.booking_by_name</div>
    <div class="w-7"><span class="font-semibold default-text-black-color">{{ dispatchBookingDetails?.passengers[0]?.pass_full_name }}</span> ({{ dispatchBookingDetails?.passengers[0]?.passenger_type }})</div>
  </div>
  <div class="flex flex-row border-bottom-grey py-12px">
    <div class="w-5 default-text-light-black-color font-13 font-normal" translate>dispatch.booking_time</div>
    <div class="w-7 font-semibold default-text-black-color"> {{ dispatchBookingDetails?.booking_time ? (dispatchBookingDetails?.booking_time | date : 'hh:mm, dd MMM yyyy') : '-' }}</div>
  </div>
  <div class="flex flex-row border-bottom-grey py-12px">
    <div class="w-5 default-text-light-black-color font-13 font-normal" translate>dispatch.booking_note</div>
    <div class="w-7">{{dispatchBookingDetails?.instruction_note ? dispatchBookingDetails?.instruction_note : '-'}}</div>
  </div>
  <!-- <div class="flex flex-row border-bottom-grey py-12px">
    <div class="w-5 default-text-light-black-color font-13 font-normal" translate>dispatch.note</div>
    <div class="w-7">{{dispatchBookingDetails?.instruction_note ? dispatchBookingDetails?.instruction_note : '-'}}</div>
  </div> -->
</div>

import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppSettings } from 'app/modules/shared/app.settings';
import { Country } from 'app/modules/shared/models/country';
import { Language } from 'app/modules/shared/models/language';
import { MenuItem, MessageService } from 'primeng/api';
import { EntityService } from 'app/modules/shared/services/entity.service';
import { Status } from 'app/modules/shared/models/status';
import { StatusComponent } from 'app/modules/shared/components/status/status.component';
import { TabViewModule } from 'primeng/tabview';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ConfigService } from 'app/modules/shared/services/config.service';
import { MiMobileValueComponent } from 'app/modules/shared/ui-sharable/mi-mobile-value/mi-mobile-value.component';
import { Countries } from 'app/modules/shared/countries';
import { DialogModule } from 'primeng/dialog';
import { UserManagementService } from 'app/modules/user-management/services/user-management.service';
import { OrganizationService } from '../../services/organization.service';
import { PasswordModule } from 'primeng/password';
import { AppIcons } from 'app/modules/shared/app.icons';
import { AccessProviderDirective } from 'app/modules/shared/directives/access-provider.directive';
import { MiImageContainerComponent } from 'app/modules/shared/ui-sharable/mi-image-container/mi-image-container.component';

@Component({
  selector: 'app-organization-details',
  standalone: true,
  imports: [InputTextModule, FormsModule, ReactiveFormsModule, TranslateModule, ButtonModule, StatusComponent,
    TabViewModule, BreadcrumbModule, MiMobileValueComponent, DialogModule, PasswordModule, ButtonModule, AccessProviderDirective, MiImageContainerComponent,
  ],
  templateUrl: './organization-details.component.html',
  styleUrls: ['./organization-details.component.scss', '../../../shared//components//mi-fields//mobile-input//flags.scss']
})
export class OrganizationDetailsComponent {
  generalFormGroup: FormGroup;
  addOrgForm: FormGroup;
  defaultLocationList = AppSettings.DEFAULT_ADDRESS_LIST_FOR_ORGANIZATION;
  tenantId: any;
  country: Country;
  language: Language;
  routePath: MenuItem[] = [];
  dataForStatus: Array<Status> = [];
  imgFileId: any;
  imgFileUrl: any;
  entityForStatus: any;
  logoImage: any;
  defaultLoaderImg = AppSettings.DEFAULT_LOADER_IMAGE;
  userDetails: any;
  tenantDetails: any;
  showStatus: boolean = false;
  moduleList: any[] = [];
  accessModueList: any[] = [];
  moduleListValues = [];

  activeIndex: number = 0;
  tenantCode: any;
  countryCodeForMobileNumber: any;
  countryCodeForUserMobileNumber: any;
  buttonLabel: any;
  resetPasswordGroup: FormGroup;
  showPopup: boolean = false;
  header: any;
  miIcons = AppIcons;
  tenantContactNumber;
  adminContactNumber: any;
  countryCodeForOrg: any;
  countryCodeForAdmin: any;

  constructor(
    public cs: CommonBindingDataService,
    private entityService: EntityService,
    private route: ActivatedRoute,
    private configService: ConfigService,
    private router: Router,
    private messageService: MessageService,
    private userManagementService: UserManagementService,
    private fb: FormBuilder,
    private orgService: OrganizationService,
    private cd: ChangeDetectorRef) { }

  ngOnInit() {
    this.tenantId = this.route.snapshot.paramMap.get('id');
    this.setCountryAndLanguage();
    this.setRoutePath();
    this.getTenantDetails();
    this.resetPasswordGroup = this.fb.group({
      password: ['', [Validators.required, Validators.pattern(AppSettings.PASSWORD_PATTERN)]]
    });

  }

  getTenantDetails() {
    this.entityService.getOrgDetails(this.tenantId).subscribe((result: any) => {
      this.tenantDetails = result;
      this.setCountryCode();
      this.setCountryCodeForUser();
      this.imgFileId = this.tenantDetails.logoFileId;
      this.imgFileUrl = this.logoImage = this.tenantDetails.logoFileUrl;
      this.getUserDetails(result.accountOwnerUserId);
      this.setStatusData();
      this.tenantCode = result.tenantCode;
      this.showStatus = true;
      this.getModuleList();
      this.buttonLabel = this.cs.getLabel('organization.reset_password');
      this.header = this.cs.getLabel('organization.reset_password');
    })
  }

  setCountryCode() {
    if (this.tenantDetails.contactNumber) {
      const mobileNumberCountryCodeArray = this.tenantDetails.contactNumber.split('-');
      this.tenantContactNumber = this.tenantDetails.contactNumber;
      let countryCodeText;
      if (mobileNumberCountryCodeArray.length > 1) {
        countryCodeText = this.countryCodeForOrg = mobileNumberCountryCodeArray[0];
      }
      if (countryCodeText) {
        this.countryCodeForMobileNumber = Countries.COUNTRY_CODE.find(ele => ele.value === countryCodeText)?.code;
      }
    }
  }

  setCountryCodeForUser() {
    if (this.tenantDetails.accountOwnerPhone) {
      const mobileNumberCountryCodeArray = this.tenantDetails.accountOwnerPhone.split('-');
      let countryCodeText;
      this.adminContactNumber =  this.tenantDetails.accountOwnerPhone;
      if (mobileNumberCountryCodeArray.length > 1) {
        countryCodeText = this.countryCodeForAdmin =  mobileNumberCountryCodeArray[0];
      }

      if (countryCodeText) {
        this.countryCodeForUserMobileNumber = Countries.COUNTRY_CODE.find(ele => ele.value === countryCodeText)?.code;
      }
    }
  }

  getModuleList() {
    this.orgService.getModuleList().subscribe({
      next: (res: any) => {
        this.moduleList = res;
        this.getListOfAccessModulesList();
      },
      error: (error) => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
      }
    });
  }

  getListOfAccessModulesList() {
    this.orgService.getAccessModuleList(this.tenantDetails.tenantId).subscribe({
      next: (res: any) => {
        this.accessModueList = res;
        this.setAccessModules();
      },
      error: (error) => {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
      }
    });
  }

  setAccessModules() {
    if (this.accessModueList.length > 0) {
      this.moduleList.forEach(element => {
        const module = this.accessModueList.find(module => module.moduleId === element.moduleId && module.active);
        if (module) {
          element.isAccessible = true;
          element.tenantModuleId = module.tenantModuleId;
        }
      });
    }
  }

  setStatusData() {
    this.dataForStatus = [
      { label: 'Email', text: this.tenantDetails.email },
      { label: 'Contact Number', text: this.tenantDetails.contactNumber },
      { label: 'Organization Code', text: this.tenantDetails.tenantCode },
    ];

    this.entityForStatus = {
      entityName: this.tenantDetails.tenantName
    };
  }

  getUserDetails(userId) {
    this.entityService.getUserDetails(userId, this.tenantDetails.tenantCode).subscribe(result => {
      this.userDetails = result;
      if (this.tenantDetails?.uniqueIdentification) {
        try {
            const uniqueIdentification = JSON.parse(this.tenantDetails.uniqueIdentification);
            if (this.areAllValuesFalse(uniqueIdentification)) {
                this.tenantDetails.uniqueIdentificationDisplay = '--';
            } else {
                this.tenantDetails.uniqueIdentificationDisplay = this.tenantDetails.uniqueIdentification;
            }
        } catch (e) {
            console.error("Error parsing uniqueIdentification:", e);
            this.tenantDetails.uniqueIdentificationDisplay = '--';
        }
    }
    })
  }

  get parsedUniqueIdentificationDisplay() {
    try {
      const uniqueArray = JSON.parse(this.tenantDetails.uniqueIdentificationDisplay);
      return Array.from(new Set(uniqueArray));
    } catch (e) {
      return ["--"];
    }
  }
  
  areAllValuesFalse(obj: any): boolean {
    return Object.values(obj).every(value => value === false);
  }  

  edit(index, view) {
    this.router.navigate([`/app/organization/edit/`, this.tenantId, index, view])
  }

  setRoutePath() {
    this.routePath = [
      {
        label: "Organization",
        routerLink: '/app/organization',
        icon: 'pi pi-arrow-left',
        iconStyle: { 'font-weight': 'bold', 'margin-right': '10px' }
      },
      {
        label: "Details",
        styleClass: 'breadcrumb-child forward-slash details-text',
        style: { 'display': 'flex', 'top': '2px', 'position': 'relative' }
      },
    ];
  }

  setCountryAndLanguage() {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE));
  }

  btnClick() {
    this.resetPasswordGroup.reset();
    this.showPopup = true;
  }

  resetPassword() {
    if (this.resetPasswordGroup.valid) {
      const requestBody = {
        forTenantCode: this.tenantDetails.tenantCode,
        newPassword: this.resetPasswordGroup.controls['password'].value
      };

      if (this.userDetails && this.userDetails?.userId) {
        this.userManagementService.resetPassword(this.userDetails.userId, requestBody).subscribe({
          next: (res: any) => {
            this.messageService.add({ key: 'tst', severity: 'success', summary: 'Success', detail: this.cs.getLabel('organization.lbl_organization_saved_successfully') });
            this.showPopup = false;
          },
          error: (error) => {
            this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel(error.errors.general[0].message) });
          }
        });
      } else {
        this.messageService.add({ key: 'tst', severity: 'error', summary: 'Error', detail: this.cs.getLabel('organization.admin_user_not_assigned') });
        this.showPopup = false;
      }

    }
  }

}

<div class="flex flex-row justify-content-between card-container" (click)="onCardClick(driverDetail)">
    <div class="flex flex-row">
        <div class="flex flex-column mr-3 justify-content-between">
            <div class="image-container mt-1">
                <app-mi-image-container [miImage]="driverDetail.profile" miHeight="26" miWidth="26"></app-mi-image-container>
                <span class="mi-lg mi-stops wh-18 label-icon inline-block vertical-align-text-bottom relative top-9 right-11"
                    [ngClass]="{ 'bg-on-shift': driverDetail.driverCurrentStatus === 'Available', 'bg-off-shift': (driverDetail.driverCurrentStatus === 'Busy' || driverDetail.driverCurrentStatus === 'Not Available') }"></span>
            </div>
            @if(isWheelchair || isChildSeat){<div class="child-wheelchair-flag flex justify-content-center align-items-center font-10 font-bold mb-1">
                @if(isWheelchair && isChildSeat){<ng-container >C|W</ng-container>}
                @if(isWheelchair && !isChildSeat){<ng-container >W</ng-container>}
                @if(!isWheelchair && isChildSeat){<ng-container>C</ng-container>}
            </div>}
        </div>
        <div class="flex flex-column">        
            <div>
                <span class="font-13 font-semibold default-text-black-color mr-2">{{driverDetail.driverName}}</span>
                <span class="font-13 font-medium default-text-black-color mr-1" >{{driverDetail.driverUniqueId}}</span>
                <span class="font-15 font-semibold default-text-light-gray mr-1" >|</span>
                <span class="font-13 font-normal default-text-black-color">{{driverGroupDetails ? driverGroupDetails?.values?.group_name : ''}}</span>
            </div>
            <div>
                <span class="font-12 font-medium default-text-black-color mr-2">{{ driverVehicleDetails ? driverVehicleDetails?.values?.name_code : ''}}</span>
                <span class="font-12 font-normal default-print-label-color">{{ driverVehicleDetails ? driverVehicleDetails?.values?.manufacturer : ''}} {{driverVehicleDetails ? driverVehicleDetails?.values?.varient : ''}}</span>
                <span class="font-12 font-normal default-print-label-color"> ({{driverVehicleDetails ? driverVehicleDetails?.values?.vehicle_type : ''}} - {{driverVehicleDetails ? driverVehicleDetails?.values?.passenger_capacity : ''}})</span>
            </div>
            <div class="mt-2 flex flex-row">
              <div class="mr-2 time-container" (mouseenter)="onMouseEnter($event)" 
              (mouseleave)="onMouseLeave($event)" [ngClass]="{'hovered': isHovered, 'blue-container': !isHovered}" >
                   <span class="font-13 font-bold default-text-black-color">{{driverDetail.eta}} Mins</span>
                   <span class="font-14 default-print-label-color mr-1 ml-1 relative top-2">|</span>
                   <span class="font-13 font-semibold default-text-black-color">{{driverDetail.etaDistance}} Miles</span>                   
              </div>
              <p-overlayPanel #op>
                <div class="m-2" style="width: 392px;">
                    <div class="font-15 font-bold default-text-black-color mb-2" translate>dispatch.driver_status.driver_status</div>
                    <div class="flex flex-row py-2">
                        <div class="w-5 font-13 font-normal default-print-label-color" translate>dispatch.driver_status.availability</div>
                        <div class="w-7 flex flex-column">
                            <div class="font-13 font-bold text-red-color" translate>{{ driverDetail.driverCurrentStatus }}</div>
                            @if (currentBookingStatus && currentBookingStatus.length) {
                                <div class="font-13 font-medium default-text-black-color" translate>{{(currentBookingStatus && currentBookingStatus.length) ? currentBookingStatus[0]?.label : ''}} <span><i class="mi-lg reconfirmed bg-route-icon wh-18 inline-block vertical-align-text-bottom"></i></span></div>
                            }
                        </div>
                    </div>
                    <div class="hr"></div>

                    <div class="flex flex-row py-2">
                        <div class="w-5 font-13 font-normal default-print-label-color" translate>dispatch.eta</div>
                        <div class="w-7">
                            <span class="font-15 font-bold default-text-black-color mr-2">{{ driverDetail.eta }} Mins</span>
                            <span class="font-13 font-semibold default-print-label-color">({{ driverDetail.etaDistance }} Miles)</span>
                        </div>
                    </div>
                    <div class="hr"></div>

                    <div class="flex flex-row py-2">
                        <div class="w-5 font-13 font-normal default-print-label-color" translate>dispatch.driver_status.current_status</div>
                        @if (currentBooking) {
                            <div class="w-7 flex flex-column">
                                <div class="font-13 font-semibold default-text-blue-color">{{ currentBooking ? currentBooking.booking_code : '-' }}</div>
                                <div class="font-13 font-medium default-print-label-color">Drop off at <span class="font-14 font-semibold default-text-black-color">{{ currentBooking ? currentBooking?.values?.booking_dropoff_location_address : '-'}}</span>
                                     estimated drop off time <span class="font-14 font-semibold default-text-black-color">{{ driverDetail.eta }} Mins</span> from now.
                                </div>
                            </div>
                        } @else {
                            <div class="w-7 flex flex-column"> - </div>
                        }

                    </div>
                    <div class="hr"></div>

                    <div class="flex flex-row py-2">
                        <div class="w-5 font-13 font-normal default-print-label-color" translate>dispatch.driver_status.next_upcoming</div>
                        @if (upcomingBookings && upcomingBookings.length > 0) {
                            
                                <div class="w-7 flex flex-column">
                                    @for (booking of upcomingBookings; track booking; let i = $index) {
                                        <div class="font-13 font-semibold default-text-blue-color">{{ booking ? booking.booking_code : '-' }} <span class="font-14 font-medium default-print-label-color">(Pickup -{{ booking?.values?.pickup_time | date : 'hh:mm' }})</span></div>
                                        <div class="font-15 font-bold default-text-black-color mr-2">{{ booking?.eta_droptime }}<span class="font-14 font-medium default-print-label-color">({{booking?.eta_distance}} Miles)</span></div>
                                        <br>
                                    }
                                </div>
                               
                           
                        } @else {
                            <div class="w-7 flex flex-column"> - </div>
                        }                      
                    </div>
                </div>
              </p-overlayPanel>
              <div class="shift-toggle font-13 font-semibold flex align-items-center" [ngClass]="{ 'on-shift': driverDetail.shiftStatus === 'On Shift', 'off-shift': driverDetail.shiftStatus === 'Off Shift' }">{{ driverDetail.shiftStatus }}</div>
            </div>  
        </div>
    </div>

    <div class="flex align-items-center">
        <p-button [label]="'Assign'" class="assign-btn" (click)="onAssignClick(driverDetail, $event)"></p-button>
    </div>

    @if(forDetail){
        <div (click)="onCloseDetailView()" class="cancel-btn"><i class="mi-lg mi-off_close mt-4 inline-block cursor-pointer wh-24"></i></div>
     }
</div>

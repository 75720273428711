import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NoResultFoundComponent } from 'app/modules/shared/components/no-result-found/no-result-found.component';
import * as _ from 'lodash';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { RadioButtonModule } from 'primeng/radiobutton';
import { AppIcons } from '../../../shared/app.icons';
import { AppSettings } from '../../../shared/app.settings';
import { MITableComponent } from '../../../shared/components/mi-table/mi-table.component';
import { QuickFilterFormComponent } from '../../../shared/components/quick-filter-form/quick-filter-form.component';
import { StatusComponent } from '../../../shared/components/status/status.component';
import { Country } from '../../../shared/models/country';
import { Language } from '../../../shared/models/language';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { ConfigService } from '../../../shared/services/config.service';
import { EntityService } from '../../../shared/services/entity.service';
import { EntityList } from '../../../vehicles/models/entity.models';
import { ListResponse, Values } from '../../../vehicles/models/listResponse.models';
import { AccessProviderDirective } from 'app/modules/shared/directives/access-provider.directive';

@Component({
  selector: 'app-assign-vehicle',
  standalone: true,
  imports: [DialogModule, TranslateModule, MITableComponent, QuickFilterFormComponent, InputTextModule, RadioButtonModule, StatusComponent, ButtonModule, ReactiveFormsModule, FormsModule, NoResultFoundComponent, NgClass, AccessProviderDirective],
  templateUrl: './assign-vehicle.component.html',
  styleUrl: './assign-vehicle.component.scss'
})
export class AssignVehicleComponent implements OnChanges, OnInit {
  miIcons = AppIcons;
  @Input() visibleAssignVehicle;
  @Input() vehicleBodyType: string;
  @Input() rowData;
  @Input() filterList;
  @Input() attributeLabels: {};
  @Input() changeDriverOnDuty;
  @Output() onToggleOverLay: EventEmitter<boolean> = new EventEmitter();
  @Output() onSearchValueChange: EventEmitter<string> = new EventEmitter();
  @Output() onValueChange = new EventEmitter<any>();
  smartSearchTimer: any;
  moduleName: string;
  selectedFilterCount: number;
  driverData: any;
  advanceFilterValue: any[] = [];
  quickFilterValue: any[] = [];
  userId: any = '';
  imgFileId: any;
  selectedModify: {};
  tableData: any = [];
  tableDataTemp: any = [];
  country: Country;
  language: Language;
  listColumns: Values;
  entityData: EntityList = {
    limit: AppSettings.ENTITY_DATA_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: "",
    filters: [],
    countryCode: '',
    deleted: AppSettings.DELETED_TYPE.ONLY_NON_DELETED,
    forTenantCode: '',
    actionStatus: ''
  };
  hideTable: boolean = false;
  bodyTypeAttribute: any;
  vehicleStatus: any;
  vehicleGroup: any;

  private maxDataReached = {
    entityDataForVehicle: false,
  }

  constructor(private driverService: EntityService, private cs: CommonBindingDataService, private messageService: MessageService,
    private configService: ConfigService) { }

  ngOnInit() {
    this.moduleName = AppSettings.ENTITY_CODE.VEHICLE;
    this.entityData.forTenantCode = this.configService.getForTenantCode()
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.tableData = [];
    this.searchEntity();
    this.getFilterView();
  }

  applyInfiniteScroll(selector, onEndPageCallback) {
    const contentEl = document.querySelector(selector) as HTMLElement;

    const handleInfiniteScroll = () => {
      const heightDiff = contentEl.scrollHeight - contentEl.offsetHeight;
      const endOfPage = Math.round(contentEl.scrollTop) === heightDiff || Math.round(contentEl.scrollTop) === (heightDiff + 1);

      if (endOfPage) {
        onEndPageCallback()
      }
    };

    contentEl.addEventListener("scroll", handleInfiniteScroll)
  }

  ngOnChanges(): void {
    this.driverData = this.rowData;
    this.tableData = JSON.parse(localStorage.getItem('assignVehicleData'));
    const indexOfDefaultVehicle = this.tableData?.findIndex(ele => ele?.id === this.driverData?.default_vehicle_id);
    if (indexOfDefaultVehicle >= 0) {
      const deletedData = this.tableData.splice(indexOfDefaultVehicle, 1);
      this.tableData.unshift(deletedData[0]);
    }
    this.selectedModify = this.tableData[0]?.id;
    this.tableDataTemp = this.tableData;
  }

  getFilterView() {
    this.driverService.getAttributeDefinition(AppSettings.ENTITY_CODE.VEHICLE, AppSettings.VIEW_CODE.ADVANCED_FILTER_VIEW).subscribe(filterResponse => {
      if (filterResponse) {
        this.setVehicleFilterView(filterResponse);
      }
    })
  }

  setVehicleFilterView(filterResponse) {
    const filterList = this.cs.getColumns(filterResponse);
    this.attributeLabels = this.cs.getAttributeLabels(filterResponse);
    this.vehicleStatus = _.find(filterList, { attributeCode: 'vehicle_status' });
    this.filterList = [this.vehicleStatus];
    this.searchEntity();
  }

  onHide(event) {
    this.visibleAssignVehicle = false;
    this.onToggleOverLay.emit(this.visibleAssignVehicle);
  }

  onShow() {
    setTimeout(() => {
      const cb = () => {
        this.entityData.offset = this.entityData.offset + (this.entityData.offset === 0 ? this.entityData.limit : AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT);
        if (!this.maxDataReached.entityDataForVehicle) {
          this.searchEntity();
        }
      };
      this.applyInfiniteScroll('#assign-vehicle .dialog-content', cb)
    }, 30);
  }

  searchEntity() {
    const country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));

    if (this.entityData.offset === 0) {
      this.tableData = [];
    }

    if (!country || !country[0]?.countryId) {
      console.error('Country information not found in local storage.');
      return;
    }
    this.entityData.countryCode = country[0].countryCode;
    this.driverService.searchEntity(AppSettings.ENTITY_CODE.VEHICLE, this.entityData).subscribe((res: ListResponse) => {
      this.listColumns = res?.data[0]?.values;
      const tmpTableData = [];

      if (res?.data[0]?.values) {
        for (const data of res.data) {
          tmpTableData.push({
            id: data.id,
            ...data.values
          });
        }
        this.tableData = [...this.tableData, ...tmpTableData];
      } else {
        this.tableData = [];
      }

      if (res?.data.length === 0) {
        this.maxDataReached.entityDataForVehicle = true;
      }

      if (!this.maxDataReached.entityDataForVehicle) {
        this.maxDataReached.entityDataForVehicle = this.tableData.length >= res.count;
      }
      localStorage.setItem('assignVehicleData', JSON.stringify(this.tableData));
    })
  }

  onSearch(event, driverGroupList?) {
    const value = event.target.value.toLowerCase();
    this.entityData.searchStr = value;
    this.searchEntity();
  }

  onSearchValueChanges(event) {
    this.entityData.searchStr = event;
    this.searchEntity();
  }

  onFilterValueChange(filterFlag, event) {
    filterFlag ? (this.advanceFilterValue = event) : (this.quickFilterValue = event);

    const combineFilter = { ...this.advanceFilterValue, ...this.quickFilterValue }
    this.selectedFilterCount = 0;

    for (const [key, value] of Object.entries(event)) {
      const attributeValue = value;
      if (attributeValue) {
        this.selectedFilterCount++;
      }

    }
    this.onFilterValue(combineFilter);
  }

  onFilterValue(combineFilter) {
    const vData = combineFilter;
    this.entityData.filters = [];
    for (const [key, value] of Object.entries(vData)) {
      const attributeValue = value;
      if (attributeValue) {
        this.entityData.filters.push({
          attributeCode: key,
          attributeValue
        });
      }
    }
    this.entityData.filters = _.filter(_.uniq(this.entityData.filters, function(item, key, a) {
      if (item.attributeValue.length > 0) {
        return item.attributeCode;

      }
    }), function(element) {
      if (element.attributeValue.length > 0) {
        return true;
      }
      return false;
    });
    this.entityData.offset = 0;
    this.entityData.filters = this.cs.mapAttributeIds(this.entityData.filters, this.filterList, 'filter');
    this.searchEntity();
  }
  
  assignVehicleData(data) {
    const country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY))?.[0];
    const language = JSON.parse(localStorage.getItem(AppSettings.LANGUAGE))?.[0];
  
    const reqBody = {
      forTenantCode: this.configService.getForTenantCode(),
      countryCode: country?.countryCode,
      languageCode: language?.langCode,
      driverEntityId: this.driverData?.id,
      activeVehicleEntityId: data?.id,
    };
  
    const entityCode = AppSettings.ENTITY_CODE.VEHICLE;
  
    this.driverService.assignVehicle(entityCode, reqBody).subscribe(
      res => this.handleVehicleAssignmentResponse(res, country, language),
      () => this.showMessage('error', 'lbl_invalid_data')
    );
  }
  
  private handleVehicleAssignmentResponse(res, country, language) {
    if (this.changeDriverOnDuty === this.cs.getLabel('lbl_change_driver_on_duty')) {
      const dutyReqBody = {
        forTenantCode: this.configService.getForTenantCode(),
        countryCode: country?.countryCode,
        languageCode: language?.langCode,
        driverEntityId: this.driverData?.id,
        dutyStatus: AppSettings.DRIVER.DUTY_STATUS.ON_DUTY,
      };
  
      this.driverService.saveDriverDutyStatusData(AppSettings.ENTITY_CODE.DRIVER, dutyReqBody).subscribe(
        () => this.showMessage('success', res.message),
        () => this.showMessage('error', 'lbl_invalid_data')
      );
    } else {
      this.showMessage('success', res.message);
    }
  }
  
  private showMessage(severity: 'success' | 'error', labelKey: string) {
    const message = severity === 'success' ? this.cs.getLabel(labelKey) : this.cs.getLabel(labelKey);
    this.messageService.add({ key: 'tst', severity, summary: severity === 'success' ? 'Successful' : 'Error', detail: message });
    this.visibleAssignVehicle = false;
    this.searchEntity();
  }
  
  

  selectRadioButton(id: any) {
    this.selectedModify = id;
  }
}


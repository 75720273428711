import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonBindingDataService } from '../../../services/common-binding-data.service';

import { MessageService } from 'primeng/api';
import { FileUploadModule } from 'primeng/fileupload';
import { ToastModule } from 'primeng/toast';
import { AppSettings } from '../../../app.settings';
import { EntityService } from '../../../services/entity.service';
import { ConfigService } from '../../../services/config.service';
import { AppIcons } from 'app/modules/shared/app.icons';
import { TranslateModule } from '@ngx-translate/core';
import { MiImageContainerComponent } from 'app/modules/shared/ui-sharable/mi-image-container/mi-image-container.component';


interface UploadedFiles {
  file: File,
  fileId: string
}

@Component({
  selector: 'app-mi-upload-file',
  templateUrl: './mi-upload-file.component.html',
  styleUrls: ['./mi-upload-file.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, FileUploadModule, ToastModule, TranslateModule, MiImageContainerComponent
  ]
})
export class MiUploadFileComponent {
  @Input() field: any;
  formName: FormGroup;
  @Input() attributeLabels: [];
  @Input() moduleName: string;
  @Input() docType: string;
  @Input() showImg: boolean = false;
  @Input() isMultipleFilesUpload: boolean = false;
  @Output() fileUploadEvent: EventEmitter<any> = new EventEmitter<any>();
  miIcons = AppIcons;
  chooseLabel: string = this.cs.getLabel('lbl_select_document');
  accept: string;
  fileUploadInstruction: string;
  chooseIcon: any = null;
  dropAreaMsg: any = '';
  dropAreaMsgForDocument: string = this.cs.getLabel('lbl_drop_document');
  maxFileSize: any = AppSettings.FILE_UPLOAD_MAX_SIZE_IN_BYTE;
  entityType: any = AppSettings.ENTITY_TYPE;
  img: any;
  showCheckIcon: boolean = true;
  isOneToManyRelation: boolean = false;
  docUrl: any;
  showDoc: boolean = false;

  uploadedFiles: UploadedFiles[] = [];
  uploadedFileIds: string[] = []

  constructor(private formGroupDirective: FormGroupDirective,
    private messageService: MessageService,
    private cd: ChangeDetectorRef,
    public cs: CommonBindingDataService,
    private configService: ConfigService,
    private entityService: EntityService) {
    this.formName = this.formGroupDirective.control;
  }

  ngOnInit() {
    console.log(this.moduleName);
    if (this.moduleName === AppSettings.ENTITY_CODE.DRIVER_DOCUMENT || this.moduleName === AppSettings.ENTITY_CODE.VEHICLE_DOCUMENT) {
      this.isOneToManyRelation = true;
      this.setImageOrDocumentForOneToManyRelation();
    } else {
      this.setImageForOneToOneRelation();
    }


    if (this.field?.attributeCode === AppSettings.ATTRIBUTE_CODE.UPLOAD_DOCUMENT) {
      if (this.field?.attributeValue && Array.isArray(this.field.attributeValue)) {
        for (const ids of this.field.attributeValue) {
          this.getDocumentByFileId(ids);
        }
      }
    }

    if (this.field.inputCode === 'fileImgPdf' || this.field.inputCode === 'entitySection') {
      this.accept = AppSettings.IMAGE_UPLOAD_TYPES + ',' + AppSettings.PDF_UPLOAD;
      this.fileUploadInstruction = this.cs.getLabel('lbl_img_pdf_upload_instruction');
      this.chooseLabel = this.cs.getLabel('lbl_select_document');
      this.dropAreaMsg = this.cs.getLabel('lbl_drop_document');
    } else if (this.field.inputCode === 'fileImg') {
      this.accept = AppSettings.IMAGE_UPLOAD_TYPES;
      this.fileUploadInstruction = this.cs.getLabel('lbl_img_upload_instruction');
      this.chooseLabel = this.cs.getLabel('lbl_select_image');
      this.dropAreaMsg = this.cs.getLabel('lbl_drop_img');
    }
    this.setLabelsAccordindgToAttributeCode();
  }

  setImageForOneToOneRelation() {
    let fileIds = [];
    if (this.field?.imgId) {
      fileIds.push(this.field?.imgId);
      this.img = this.field?.imgUrl ? this.field?.imgUrl : null;
    } else {
      const imgIdAndUrl = this.formName.controls[this.field.attributeCode]?.value;
      if (imgIdAndUrl && imgIdAndUrl?.imgId) {
        this.uploadedFileIds.push(imgIdAndUrl.imgId);
        this.img = (imgIdAndUrl?.imgUrl && imgIdAndUrl?.imgUrl.length > 0) ? imgIdAndUrl?.imgUrl[0] : null;
        this.formName.controls[this.field.attributeCode].setValue(imgIdAndUrl.imgId);
      }
    }
  }

  setImageOrDocumentForOneToManyRelation() {
    let fileIds = [];
    const imgIdAndUrl = this.formName.controls[`${this.field.attributeCodeOneToMultiple}_url`]?.value;
    if (imgIdAndUrl && imgIdAndUrl?.imgId) {
      this.uploadedFileIds.push(imgIdAndUrl.imgId);
      this.docUrl = imgIdAndUrl?.imgUrl
    }
  }

  setLabelsAccordindgToAttributeCode() {
    if (this.field.attributeCode === 'event_image') {
      this.chooseLabel = this.cs.getLabel('lbl_select_logo');
      this.dropAreaMsg = this.cs.getLabel('lbl_drop_logo');
    }
  }

  previewImage(event) {
    const reader = new FileReader();
    const that = this;
    reader.onloadend = function() {
      that.img = reader.result
    }
    reader.readAsDataURL(event.files[0]);
  }

  showCloseImageIcon() {
    this.showCheckIcon = false;
  }

  showCheckImageIcon() {
    this.showCheckIcon = true;
  }

  removeImage(file) {
    const index = this.uploadedFiles.findIndex(ele => ele.fileId === file.fileId);
    this.uploadedFiles.splice(index, 1);
    const fileIdIndex = this.uploadedFileIds.findIndex(ele => ele === file.fileId);
    this.uploadedFileIds.splice(fileIdIndex, 1);
    this.img = null;
    this.formName.controls[this.field?.isOneToMultiple ? this.field?.attributeCodeOneToMultiple : this.field.attributeCode].setValue(this.uploadedFileIds);
    this.formName.get(this.field?.isOneToMultiple ? this.field?.attributeCodeOneToMultiple : this.field.attributeCode).updateValueAndValidity();
  }

  onUpload(event) {
    if (this.checkFileExtension(event.files[0])) {
      this.previewImage(event);
      const key = this.field.attributeCode;
      for (const file of event.files) {
        if (file) {
          if (this.moduleName !== AppSettings.ENTITY_CODE.DRIVER_DOCUMENT && this.moduleName !== AppSettings.ENTITY_CODE.VEHICLE_DOCUMENT) {
            if (file.type === 'application/pdf' || file.name.endsWith('.pdf')) {
              this.showImg = false;
              this.messageService.add({ key: "fileupload", severity: 'error', summary: 'Error', detail: this.cs.getLabel('lbl_pdf_files_cannot_be_uploaded') });
              continue;
            }
            else {
              this.showImg = true;
            }
          }
  
          if (file.size > AppSettings.FILE_UPLOAD_MAX_SIZE_IN_BYTE) {
            this.messageService.add({ key: "fileupload", severity: 'error', summary: 'Error', detail: this.cs.getLabel('err_file_size_exceed_msg') });
          } else {
            const formData = new FormData();
            formData.append("files", file);

            const country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
            const requestBody = {
              forTenantCode: this.configService.getForTenantCode(),
              countryCode: country[0].countryCode,
              uploadType: this.docType,
              originalFilename: file.name
            }

            this.cs.generateUploadFileSignedUrl(requestBody).subscribe((data: any) => {
              const urlForFileUpload = data?.signedUrl;
              if (urlForFileUpload) {
                this.cs.uploadFile(file, urlForFileUpload, data.headers).subscribe(res => {
                  const reqBody = {
                    forTenantCode: this.configService.getForTenantCode(),
                    countryCode: country[0].countryCode,
                    uploadType: this.docType,
                    originalFilename: file.name,
                    fileName: data.fileName,
                    createdAt: data.createdAt,
                    signedToken: data.signedToken
                  
                  }
                  this.cs.verifyUploadedFile(reqBody, this.docType).subscribe((verifyResult: any) => {
                    this.messageService.add({ key: "fileupload", severity: 'success', summary: 'Success', detail: this.cs.getLabel(this.showImg ? 'lbl_img_uploaded_successfully' : 'lbl_file_uploaded_successfully') });
                    const fileId = verifyResult.find(ele => ele.thumbnailSize === null)?.resourceId
                    if (this.isMultipleFilesUpload) {
                      if (verifyResult && verifyResult.length > 0) {
                        this.uploadedFileIds.push(fileId);
                        this.uploadedFiles.push({ file: file, fileId: fileId });
                      }
                    } else {
                      this.uploadedFileIds = [];
                      this.uploadedFiles = [];
                      this.uploadedFileIds.push(fileId);
                      this.uploadedFiles.push({ file: file, fileId: fileId });
                    }
                    this.fileUploadEvent.emit(this.uploadedFileIds);
                    this.formName.controls[this.field?.isOneToMultiple ? this.field?.attributeCodeOneToMultiple : this.field.attributeCode].setValue(this.uploadedFileIds);
                    this.formName.get(this.field?.isOneToMultiple ? this.field?.attributeCodeOneToMultiple : this.field.attributeCode).updateValueAndValidity();

                  })
                })
              }
            });
          }
        }
      }
    } else {
      if (this.field.inputCode === 'fileImgPdf') {
        this.messageService.add({ key: "fileupload", severity: 'error', summary: 'Error', detail: 'Accepted format JPG, PNG, PDF. Please upload again' });
      } else if (this.field.inputCode === 'fileImg') {
        this.messageService.add({ key: "fileupload", severity: 'error', summary: 'Error', detail: 'Accepted format JPG, PNG, JPEG. Please upload again' });
      }
    }
  }

  checkFileExtension(file) {
    if (this.moduleName === AppSettings.ENTITY_TYPE.PASSENGER 
        || this.moduleName === AppSettings.ENTITY_TYPE.EVENTS  
        || this.moduleName === AppSettings.ENTITY_TYPE.ORGANIZATION 
        || this.moduleName === AppSettings.ENTITY_TYPE.GENERAL_SETTINGS
        || this.moduleName === AppSettings.ENTITY_TYPE.INCIDENTS_AND_COMPLAINT) {
          let fileExtension = file.name.split('.');
          if (this.accept.includes(fileExtension[1])) {
            return true;
          } else {
            return false;
          }
    } else {
      return true;
    }

  }

  getImageByFileId(fileId) {
    this.entityService.getFile(fileId, AppSettings.DOCUMENTS_TYPE.PROFILE).subscribe(result => {
      const file = new File([result], this.moduleName);
      this.uploadedFiles.push({ file: file, fileId: fileId });
      const reader = new FileReader();
      reader.readAsDataURL(result);
      const that = this;
      reader.onloadend = function() {
        const base64data = reader.result;
        that.img = base64data;
      }
    })
  }

  getDocumentByFileId(fileId) {
    this.entityService.getFile(fileId, AppSettings.DOCUMENTS_TYPE.DOCUMENT, 'no').subscribe(result => {
      const file = new File([result], this.moduleName);
      this.uploadedFiles.push({ file: file, fileId: fileId });
      const reader = new FileReader();
      reader.readAsDataURL(result);
      const that = this;
      reader.onloadend = function() {
        const base64data = reader.result;
        that.img = base64data;
      }
    })
  }

  downloadDocument(event) {
    if (event) {
      const fileId = event?.fileId;
      const documentTypes = event?.file?.type;
      const fileName = event?.file?.name;
      this.getFile(fileId, documentTypes, fileName);
    } else {
      console.error('Invalid Documents data.');
    }
  }

  getFile(imgFileId, documentTypes, fileName) {
    this.entityService.getFile(imgFileId, AppSettings.DOCUMENTS_TYPE.DOCUMENT, 'no').subscribe(result => {
      const blob = new Blob([result], { type: `${documentTypes}` });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${fileName}`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    }, error => {
      console.error('Error fetching file:', error);
    });
  }

  get isDocumentModule(): boolean {
    return this.moduleName === 'vehicle_document' || this.moduleName === 'driver_document';
  }

}
import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MiFieldsComponent } from '../mi-fields/mi-fields.component';
import { PassengerTypeFieldComponent } from '../passenger-type-field/passenger-type-field.component';
import { TwoColumnStructureComponent } from '../two-column-structure/two-column-structure.component';
import { AppSettings } from '../../app.settings';

@Component({
  selector: 'app-privilege-form',
  templateUrl: './privilege-form.component.html',
  standalone: true,
  imports: [
    CommonModule,
    MiFieldsComponent,
    PassengerTypeFieldComponent,
    TwoColumnStructureComponent
  ]
})
export class PrivilegeFormComponent {
  @Input() field: any;
  @Input() attributeLabels: any;
  @Input() moduleName: string;
  @Input() dateFormat: string;
  @Input() multiSelectDisplay: any;
  fieldsToDisplayHRLine = AppSettings.FIELDS;

  isPassengerTypePrivilegeConfig(field: any): boolean {
    return field.attributeCode === 'passenger_type' && 
           this.moduleName === 'pass_privilege_conf';
    }
    

    shouldDisplayHrLine(field: any): boolean {
    const hrLineFields = [
        this.fieldsToDisplayHRLine.AIRPORT_TRANSPORT,
        this.fieldsToDisplayHRLine.TRANSPORTATION_END_DATE,
        this.fieldsToDisplayHRLine.DEPARTURE_JOURNEY,
        this.fieldsToDisplayHRLine.NUMBER_OF_BOOKING_PER_DAY,
        this.fieldsToDisplayHRLine.BOOKING_CANCELLATION_IN_STATUS,
        this.fieldsToDisplayHRLine.OTP_VERIFICATION_FOR_BOOKING_END
    ];
    return hrLineFields.includes(field.attributeCode);
    }
} 
<form [formGroup]="formName">
    @if (this.isFilter) {
        <p-calendar class="date-filter" [formControlName]="field?.isOneToMultiple ? field?.attributeCodeOneToMultiple : field.attributeCode" [dateFormat]="dateFormat" [showIcon]="true" [placeholder]="placeholder"
        [minDate]="field.validation.min" [maxDate]="field.validation.max" appendTo="body"></p-calendar>
    } @else if (moduleName === 'vehicle_document' || moduleName === 'driver_document' || moduleName === 'driver_driving_record') {
        <p-calendar [formControlName]="field?.isOneToMultiple ? field?.attributeCodeOneToMultiple : field.attributeCode" [dateFormat]="dateFormat" [showIcon]="true" [placeholder]="placeholder"
        [minDate]="field.validation.min" [maxDate]="field.validation.max" appendTo="body"></p-calendar>
    } @else if (field.attributeCode === 'event_start_date' || field.attributeCode === 'event_end_date') {
        <p-calendar  #calendar [formControlName]="field?.isOneToMultiple ? field?.attributeCodeOneToMultiple : field.attributeCode" [dateFormat]="dateFormat" [showIcon]="true" [placeholder]="placeholder"
        [minDate]="field.validation.min" [maxDate]="field.validation.max" appendTo="body" [class]="field.attributeCode"
        (onSelect)="field.dateChange($event)"></p-calendar>
    } @else if(field.attributeCode === "incident_complaint_date") {
        <p-calendar [formControlName]="field?.isOneToMultiple ? field?.attributeCodeOneToMultiple : field.attributeCode" [dateFormat]="dateFormat" [showIcon]="true" [placeholder]="placeholder"
            [maxDate]="field.validation.max" appendTo="body" icon="mi-lg calendar_date_time" hideOnDateTimeSelect="true"></p-calendar>
    } @else {
        <p-calendar [formControlName]="field?.isOneToMultiple ? field?.attributeCodeOneToMultiple : field.attributeCode" [dateFormat]="dateFormat" [showIcon]="true" [placeholder]="placeholder"
            [minDate]="field.validation.min" [maxDate]="field.validation.max" appendTo="body"></p-calendar>
    }

</form>
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { DocumentListComponent } from 'app/modules/shared/components/document-list/document-list.component';
import { StorageService } from 'app/modules/shared/services/storage.service';
import { MenuItem } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { AppSettings } from '../../../shared/app.settings';
import { StatusComponent } from '../../../shared/components/status/status.component';
import type { Country } from '../../../shared/models/country';
import type { Status } from '../../../shared/models/status';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { EntityService } from '../../../shared/services/entity.service';
import type { AttributeData } from '../../models/attribute.models';
import { OverviewDetailsComponent } from './overview-details/overview-details.component';
import { SpecificationComponent } from './specification/specification.component';
import { VehicleAssignmentHistoryComponent } from './vehicle-assignment-history/vehicle-assignment-history.component';
import { AccessProviderDirective } from 'app/modules/shared/directives/access-provider.directive';
import { VehicleJourneyHistoryComponent } from '../vehicle-journey-history/vehicle-journey-history.component';
import { NoResultFoundComponent } from 'app/modules/shared/components/no-result-found/no-result-found.component';

@Component({
  selector: 'app-vehicle-details',
  templateUrl: './vehicle-details.component.html',
  styleUrls: ['./vehicle-details.component.scss'],
  standalone: true,
  imports: [
    BreadcrumbModule,
    StatusComponent,
    TabViewModule,
    TranslateModule,
    MenuModule,
    ToastModule,
    OverviewDetailsComponent,
    ButtonModule,
    SpecificationComponent,
    VehicleAssignmentHistoryComponent,
    DocumentListComponent,
    AccessProviderDirective,
    VehicleJourneyHistoryComponent,
    NoResultFoundComponent
  ],
})
export class VehicleDetailsComponent implements OnInit {
  @Input() tableData: any[];
  routePath: MenuItem[] = [];
  vehicleId: string;
  dataForVehicleStatus: Array<Status> = [];
  userId: any = '';
  country: Country;
  activeIndex: any = 0;
  data: AttributeData;
  vehicleAttributeData: any;
  attributeLabels = {};
  vehicleAttributeLength;
  attributeValues = [];
  imgFileId: any;
  imgFileUrl: any;
  showStatus: boolean = false;
  vehicleEntityId;
  currentRow;
  vehicleDetails: any;
  entityForStatus: any;
  editBtnLabel: any;
  relatedData: any;
  driverList: any[] = [];
  // addBtnLabel;
  defaultLoaderImg = AppSettings.DEFAULT_LOADER_IMAGE;
  entityCode: string = AppSettings.ENTITY_CODE.VEHICLE_DOCUMENT;

  constructor(private route: ActivatedRoute,
    public cs: CommonBindingDataService,
    private cd: ChangeDetectorRef,
    private vehicleService: EntityService,
    private storageService: StorageService,
    private router: Router) {

  }

  ngOnInit() {
    // this.addBtnLabel=this.cs.getLabel('add_vehicle');
    const activeIndex = this.storageService.getItem('vehicleActiveIndex');
    this.activeIndex = activeIndex ? activeIndex : 0;
    this.editBtnLabel = this.cs.getLabel('lbl_edit');
    this.vehicleId = this.route.snapshot.paramMap.get('id');
    this.getVehicleDetails();

    this.getAttributeData();
    this.routePath = [
      {
        label: "Vehicles",
        routerLink: '../list',
        icon: 'pi pi-arrow-left',
        iconStyle: { 'font-weight': 'bold', 'margin-right': '10px' },
        command: () => {
          this.storageService.setItem('vehicleActiveIndex', 0);
          this.router.navigate['app/vehicles'];
        }
      },
      {
        label: "Overview",
        routerLink: '../' + this.vehicleId,
        styleClass: 'breadcrumb-child forward-slash breadcrumb-text',
        style: { 'display': 'flex', 'top': '2px', 'position': 'relative' }
      },
    ];
    this.vehicleService.vehicleCurrentRow.subscribe(res => {
      this.currentRow = res;
    })
  }

  onAddVehicleDocument() {
    this.router.navigate(['app/vehicles/add']);
  }

  activeIndexChange(event) {
    this.storageService.setItem('vehicleActiveIndex', event);
  }

  getAttributeData() {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.vehicleService.getAttributeDefinition(AppSettings.ENTITY_CODE.VEHICLE, AppSettings.VIEW_CODE.DETAIL_VIEW).subscribe(res => {
      res.tabs.forEach(element => {
        element.groups.forEach(group => {
          if (group.code === 'details') {
            group.entityRelationshipConfigId = null;
          }
        });
      });
      this.data = res;
      this.cd.detectChanges();
      this.attributeLabels = this.cs.getAttributeLabels(this.data);
      this.vehicleAttributeData = this.cs.getOrganizedAttribute(this.data);
      this.vehicleAttributeLength = this.vehicleAttributeData.tabs.length;
    })
  }

  getVehicleDetails() {
    this.vehicleService.getEntity(this.vehicleId, AppSettings.ENTITY_CODE.VEHICLE, AppSettings.VIEW_CODE.DETAIL_VIEW).subscribe((result: any) => {
      this.attributeValues = this.cs.getOrgAttributeValues(result);
      this.vehicleDetails = result;
      const value = result.attributeCodeValueDtoList.find(ele => ele.attributeCode === 'vehicle_images_url');
      this.driverList = result.relatedData.filter(ele => ele.entityCode === 'driver').map(ele => {
        return {
          driverProfileImage:  ele.attributeCodeValueDtoList.find(ele => ele.attributeCode === 'driver_profile_image_url')?.attributeValue[0],
          driverProfileImageId: ele.attributeCodeValueDtoList.find(ele => ele.attributeCode === 'driver_profile_image')?.attributeValue[0],
          driverName: ` ${ele.attributeCodeValueDtoList.find(ele => ele.attributeCode === 'first_name').attributeValue} ${ele.attributeCodeValueDtoList.find(ele => ele.attributeCode === 'last_name').attributeValue}`
        }
      });

      if (value) {
        this.imgFileUrl = value.attributeValue[0];
        this.cd.detectChanges();
      }
      this.showStatus = true;
      this.setStatusData();
    })
  }

  getDriverImg(driverProfileImageId, driver) {
    this.vehicleService.getFile(driverProfileImageId, AppSettings.DOCUMENTS_TYPE.PROFILE).subscribe(result => {
      const reader = new FileReader();
      reader.readAsDataURL(result);
      reader.onloadend = function() {
        const base64data = reader.result;
        driver.driverProfileImage = base64data;
      }
    });
  }

  setStatusData() {
    const findAttributeValue = (attributeCode: string) =>
      this.vehicleDetails?.attributeCodeValueDtoList.find(ele => ele.attributeCode === attributeCode)?.attributeValue;

    const getAttributeValueOrEmpty = (attributeCode: string) =>
      findAttributeValue(attributeCode) || this.cs.getLabel('lbl_empty');

    this.entityForStatus = {
      entityName: `${findAttributeValue('name_code')}`,
      entityStatus: `${findAttributeValue('vehicle_status')}`
    };

    this.dataForVehicleStatus = [
      { userId: '', vehicleType: 'type', label: '', text: getAttributeValueOrEmpty('vehicle_type') },
      { label: '', text: getAttributeValueOrEmpty('year_of_manufacturing') },
      { label: '', text: findAttributeValue('manufacturer') },
      { label: '', text: findAttributeValue('model') },
      { label: 'VIN', text: findAttributeValue('vin_chassis_no') },
      { label: 'License Plate', text: findAttributeValue('reg_number') },
    ];
  }

  edit() {
    this.router.navigate(['app/vehicles/add/' + this.vehicleDetails.entityId,]);
  }

  isDeleted(): boolean {
    return this.vehicleDetails?.actionStatus === 'DELETED';
}

}

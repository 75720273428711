import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonBindingDataService } from '../../../shared/services/common-binding-data.service';
import { FormControl, FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { MiErrorComponent } from '../../../shared/components/mi-error/mi-error.component';
import { MiFieldsComponent } from '../../../shared/components/mi-fields/mi-fields.component';
import { MiTooltipComponent } from '../../../shared/components/mi-fields/mi-tooltip/mi-tooltip.component';
import { SelectButtonWithAddNewComponent } from '../../../shared/components/mi-fields/select-button-with-add-new/select-button-with-add-new.component';
import * as _ from 'lodash';

import { GeoPointComponent } from '../../../shared/components/mi-fields/geo-point/geo-point.component';
import { AppSettings } from '../../../shared/app.settings';
import { NgClass } from '@angular/common';
import { GeoTransportComponent } from 'app/modules/shared/components/mi-fields/geo-transport/geo-transport.component';
import { EntityService } from 'app/modules/shared/services/entity.service';
import { EntityList } from 'app/modules/vehicles/models/entity.models';
import { Country } from 'app/modules/shared/models/country';
import { ConfigService } from 'app/modules/shared/services/config.service';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-stay-home-address',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MiFieldsComponent,
    MiErrorComponent,
    ButtonModule,
    MiTooltipComponent,
    TranslateModule,
    SelectButtonWithAddNewComponent,
    GeoPointComponent,
    NgClass,
    GeoTransportComponent,
  ],
  templateUrl: './stay-home-address.component.html',
  styleUrl: './stay-home-address.component.scss'
})
export class StayHomeAddressComponent {
  @Input() group: any;
  @Input() attributeLabels: [];
  @Input() moduleName: string;
  @Input() miFormGroup: FormGroup;
  @Input() attributeValues;
  @Input() showImg: boolean = false;
  @Input() addressEntityIdList: any = [];
  @Input() defaultLocationList: any = [];
  @Input() isShowBookingMap: boolean;

  @Output() formGroupEmit: EventEmitter<any> = new EventEmitter<any>();

  selectedAddress;
  formGroupFields = [];
  addressFields: any = [];
  locationType = [];
  showForm: boolean = true;
  fieldsCopy: any;
  lastSelectedButton: any;
  pKeyFilter: any = 'alphanum';
  currentSelectedCode: any;
  formName: FormGroup;
  entityData: EntityList = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT_FOR_SETTINGS,
    offset: 0,
    searchStr: "",
    filters: [],
    countryCode: '',
    deleted: AppSettings.DELETED_TYPE.ONLY_NON_DELETED,
    forTenantCode: "",
    actionStatus: '',
    tableViewCode: AppSettings.VIEW_CODE.LOCATION_WITH_GEOCODE_VIEW
  };
  locationCategoryAttributeId: any;
  country: Country;
  stayLocationList: any[] = [];

  constructor(
    public cs: CommonBindingDataService,
    public entityService: EntityService,
    private formGroupDirective: FormGroupDirective,
    private configService: ConfigService,
    private messageService: MessageService,
    private cd: ChangeDetectorRef) {
    this.formName = this.formGroupDirective.control;
  }

  ngOnInit(): void {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
    this.entityData.forTenantCode = this.configService.getForTenantCode();
    this.getStayLocationsForPresetValues();
    this.fieldsCopy = _.cloneDeep(this.group.fields);
    this.moduleName = this.moduleName === AppSettings.ENTITY_CODE.PASSENGER ? AppSettings.ENTITY_CODE.PASSENGER_ADDRESS : this.moduleName === AppSettings.ENTITY_TYPE.GENERAL_SETTINGS ? AppSettings.ENTITY_TYPE.GENERAL_SETTING_ADDRESS : this.moduleName;
    this.selectedAddress = this.defaultLocationList[0]?.labelKey;
    this.formGroupEmit.emit(this.miFormGroup);
    this.entityService.defaultAddressSelection.subscribe(result => {
      this.defaultAddressCheckBoxValueChangeEvent(result)
    })

  }

  setFormFields() {
    this.group.fields = [];
    for (let index = 0; index < this.defaultLocationList.length; index++) {
      const fields = _.cloneDeep(this.fieldsCopy);
      for (const field of fields) {
        this.currentSelectedCode = field.inputCode;
        const validators = this.addValidator(field.validation);
        field.attributeCodeOneToMultiple = index + '_' + field.attributeCode;
        field.isOneToMultiple = true;
        field.fieldName = field.attributeCode;
        field.locationType = this.defaultLocationList[index].labelKey;
        const value = this.attributeValues ? this.attributeValues[field.attributeCodeOneToMultiple] : '';
        if (field.attributeCode === AppSettings.ATTRIBUTE_CODE_FOR_STAY_HOME_ADDRESS.SELECT_LOCATION_GEOLOCATION) {
          field.presetValues = [];
          field.presetValues.push(...this.stayLocationList);
        }
        if (this.attributeValues && this.attributeValues[`${index}_${AppSettings.ATTRIBUTE_CODE_FOR_STAY_HOME_ADDRESS.SELECT_LOCATION_GEOLOCATION}`]) {
          this.setPresetValuesForSelectLocationField(field, index, validators);
        }

        this.miFormGroup.registerControl(field.attributeCodeOneToMultiple, new FormControl(value, validators));
        this.group.fields.push(field);
        console.log(this.group.fields)
      }
    }
  }

  setPresetValuesForSelectLocationField(field, index, validators) {
    if (field.attributeCode === AppSettings.ATTRIBUTE_CODE_FOR_STAY_HOME_ADDRESS.SELECT_LOCATION_GEOLOCATION) {

      if (field.presetValues) {
        const element = field.presetValues.find(ele => ele.labelKey === this.attributeValues[field?.attributeCodeOneToMultiple]);
        if (element === undefined) {
          const address = {
            labelKey: this.attributeValues[`${index}_${AppSettings.ATTRIBUTE_CODE_FOR_STAY_HOME_ADDRESS.SELECT_LOCATION_ADDRESS}`],
            labelValue: {
              address: this.attributeValues[`${index}_${AppSettings.ATTRIBUTE_CODE_FOR_STAY_HOME_ADDRESS.SELECT_LOCATION_ADDRESS}`],
              geoLocation: this.attributeValues[`${index}_${AppSettings.ATTRIBUTE_CODE_FOR_STAY_HOME_ADDRESS.SELECT_LOCATION_GEOLOCATION}`],
              placeId: this.attributeValues[`${index}_${AppSettings.ATTRIBUTE_CODE_FOR_STAY_HOME_ADDRESS.LOCATION_PLACE_ID}`] ? this.attributeValues[`${index}_${AppSettings.ATTRIBUTE_CODE_FOR_STAY_HOME_ADDRESS.LOCATION_PLACE_ID}`] : null,
              locationEntityId: this.attributeValues[`${index}_${AppSettings.ATTRIBUTE_CODE_FOR_STAY_HOME_ADDRESS.LOCATION_ENTITY_ID}`] ? this.attributeValues[`${index}_${AppSettings.ATTRIBUTE_CODE_FOR_STAY_HOME_ADDRESS.LOCATION_ENTITY_ID}`] : null
            }
          }
          field.presetValues.push(address);
          this.miFormGroup.registerControl(field.attributeCodeOneToMultiple, new FormControl(address.labelValue, validators));
        }

      }  else {
        field.presetValues = [];
        const element = field.presetValues.find(ele => ele.labelKey === this.attributeValues[field?.attributeCodeOneToMultiple]);
        if (element === undefined) {
          const address = {
            labelKey: this.attributeValues[`${index}_${AppSettings.ATTRIBUTE_CODE_FOR_STAY_HOME_ADDRESS.SELECT_LOCATION_ADDRESS}`],
            labelValue: {
              address: this.attributeValues[`${index}_${AppSettings.ATTRIBUTE_CODE_FOR_STAY_HOME_ADDRESS.SELECT_LOCATION_ADDRESS}`],
              geoLocation: this.attributeValues[`${index}_${AppSettings.ATTRIBUTE_CODE_FOR_STAY_HOME_ADDRESS.SELECT_LOCATION_GEOLOCATION}`],
              placeId: this.attributeValues[`${index}_${AppSettings.ATTRIBUTE_CODE_FOR_STAY_HOME_ADDRESS.LOCATION_PLACE_ID}`] ? this.attributeValues[`${index}_${AppSettings.ATTRIBUTE_CODE_FOR_STAY_HOME_ADDRESS.LOCATION_PLACE_ID}`] : null,
              locationEntityId: this.attributeValues[`${index}_${AppSettings.ATTRIBUTE_CODE_FOR_STAY_HOME_ADDRESS.LOCATION_ENTITY_ID}`] ? this.attributeValues[`${index}_${AppSettings.ATTRIBUTE_CODE_FOR_STAY_HOME_ADDRESS.LOCATION_ENTITY_ID}`] : null
            }

          }
          field.presetValues.push(address);
          this.miFormGroup.registerControl(field.attributeCodeOneToMultiple, new FormControl(address.labelValue, validators));
        }
      }
    }
  }

  getStayLocationsForPresetValues() {
    this.entityService.getAttributeDefinition(AppSettings.ENTITY_CODE.LOCATION, AppSettings.VIEW_CODE.DEFAULT_TABLE_VIEW).subscribe(res => {
      if (res) {
        res.tabs[0]?.groups[0]?.fields.forEach(element => {
          if (element.attributeCode === AppSettings.ATTRIBUTE_CODE_FOR_SETTINGS.LOCATION_CATEGORY) {
            this.locationCategoryAttributeId = element.attributeId;
            this.getLocations()
          }
        });
      }
    });
  }

  getLocations() {
    this.entityData.countryCode = this.country[0].countryCode;
    this.entityData.filters = [];
    this.entityData.filters.push({
      attributeId: this.locationCategoryAttributeId,
      attributeValue: 'Stay Location'
    });
    this.entityService.searchEntity(AppSettings.ENTITY_CODE.LOCATION, this.entityData).subscribe((res: any) => {
      if (res && res.data.length > 0) {
        res.data.forEach((stayLocation, index) => {
          const address = {
            labelKey: stayLocation.values?.location_address,
            labelValue: {
              address: stayLocation.values?.location_address,
              geoLocation: stayLocation.values?.location_geolocation,
              placeId: stayLocation.values?.location_place_id,
              locationEntityId: stayLocation.id
            }
          }
          this.stayLocationList.push(address);
          if (index === res.data.length - 1) {
            this.setFormFields();
          }
        });
      }
    })
  }

  onNewAddressAdd(event) {
    const newAddressGroupFields = _.cloneDeep(this.fieldsCopy);
    for (const field of newAddressGroupFields) {
      this.currentSelectedCode = field.inputCode;
      const validators = this.addValidator(field.validation);
      field.attributeCodeOneToMultiple = (this.defaultLocationList.length - 1) + '_' + field.attributeCode;
      field.isOneToMultiple = true;
      field.fieldName = field.attributeCode;
      field.locationType = event;
      const value = this.attributeValues ? this.attributeValues[field?.attributeCodeOneToMultiple] : '';
      this.miFormGroup.registerControl(field.attributeCodeOneToMultiple, new FormControl(value, validators));
      this.group.fields.push(field);
    }
  }

  onSelectButtonChange(event) {
    this.selectedAddress = event;
  }

  onAddressDelete(event) {
    this.group.fields.forEach(element => {
      if (element.locationType === event) {
        this.miFormGroup.removeControl(element.attributeCodeOneToMultiple);
      }
    });
    const index = this.defaultLocationList.findIndex(ele => ele.labelValue === event);
    const entityIdToDelete = this.defaultLocationList[index].entityId;
    this.defaultLocationList.splice(index, 1);
    if (entityIdToDelete) {
      const index = this.addressEntityIdList.findIndex(ele => ele === entityIdToDelete);
      this.addressEntityIdList.splice(index, 1);
      this.deleteFromDb(entityIdToDelete);
    }
    this.group.fields = this.group.fields.filter(ele => ele.locationType !== event);
    this.selectedAddress = this.defaultLocationList[0].labelKey;
    this.cd.detectChanges();

  }

  deleteFromDb(entityIdToDelete) {
    const requestBody = {
      countryCode: this.country[0].countryCode,
      entityIds: [
        entityIdToDelete
      ],
      forTenantCode:  this.configService.getForTenantCode(),
      entityCode: this.moduleName
    }
    this.entityService.deleteEntityActionStatus(requestBody)
    .subscribe(() =>{})
  }

  defaultAddressCheckBoxValueChangeEvent(field) {
    this.group.fields.forEach(groupField => {
      if (groupField.attributeCode === 'default_address' && field.attributeCodeOneToMultiple !== undefined && groupField.attributeCodeOneToMultiple !== field.attributeCodeOneToMultiple) {
        this.formName.controls[groupField.attributeCodeOneToMultiple].setValue(false);
        this.formName.get(groupField.attributeCodeOneToMultiple).updateValueAndValidity();
      }
    });
  }

  private addValidator(rules) {
    const stringValues = ['inputString', 'text', 'textArea', 'inputMobile'];
    if (!rules) {
      return [];
    }
    const validators = [];
    (Object.keys(rules).map((rule) => {
      const isString = stringValues.includes(this.currentSelectedCode);
      if (rule === "required" && rules.required == true) {
        validators.push(Validators.required);
      }

      if (rule === "min" && rules.min && !isString) {
        if (rules[rule] !== null) {
          validators.push(Validators.min(rules[rule] ? rules[rule] : 10))
        }
      }

      if (rule === "max" && rules.max && !isString) {
        if (rules[rule] !== null) {
          validators.push(Validators.max(rules[rule] ? rules[rule] : 50));
        }
      }

      if (rule === "min" && rules.min && isString) {
        if (rules[rule] !== null) {
          validators.push(Validators.minLength(rules[rule] ? rules[rule] : 10));
        }
      }

      if (rule === "max" && rules.max && isString) {
        if (rules[rule] !== null) {
          validators.push(Validators.maxLength(rules[rule] ? rules[rule] : 50));
        }
      }

      if (rule === "pattern" && rules.pattern) {
        if (rules[rule] !== null) {
          const validatorRule = new RegExp([rules[rule]].join(''));
          validators.push(Validators.pattern(rules[rule] ? validatorRule : ''));
        }

      }
      if (rule === "unique" && rules.unique == true) {
        validators.push(Validators.pattern(''));

      }
    }));

    return validators;
  }
}

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonBindingDataService } from '../../../services/common-binding-data.service';
import { CalendarModule } from 'primeng/calendar';
import { DeviceDetectionService } from 'app/modules/shared/services/device-detection.service';

@Component({
  selector: 'app-date-range',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, CalendarModule],
  templateUrl: './date-range.component.html',
  styleUrl: './date-range.component.scss'
})
export class DateRangeComponent {
  @Output() onSelectedDate: EventEmitter<any> = new EventEmitter();
  @Input() field: any;
  formName: FormGroup;
  @Input() attributeLabels: [];
  @Input() moduleName: string;
  placeholder: any;
  rangeDates: Date[] | undefined;
  isTouchDevice:boolean=false;

  constructor(private formGroupDirective: FormGroupDirective,
    public cs: CommonBindingDataService,
    private deviceDetectionService: DeviceDetectionService,) {
    this.formName = this.formGroupDirective.control;
  }

  ngOnInit(): void {
    this.placeholder = this.cs.getLabelValue(this.moduleName + '.fields.' + this.field.attributeCode + '.placeholder', this.attributeLabels, null);
    this.placeholder = this.placeholder ? this.placeholder : this.cs.getLabel('lbl_select_date')
    this.isTouchDevice = this.deviceDetectionService.isTouchDevice();
 
  }

}

import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { BookingResponse } from 'app/modules/bookings/models/booking.models';
import { AppIcons } from 'app/modules/shared/app.icons';
import { AppSettings } from 'app/modules/shared/app.settings';
import { Country } from 'app/modules/shared/models/country';
import type { SearchEntityPayload } from 'app/modules/shared/models/entity.models';
import { Language } from 'app/modules/shared/models/language';
import { CommonBindingDataService } from 'app/modules/shared/services/common-binding-data.service';
import { ConfigService } from 'app/modules/shared/services/config.service';
import { EntityService } from 'app/modules/shared/services/entity.service';
import dayjs from 'dayjs';
import * as _ from "lodash";
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { InputTextModule } from 'primeng/inputtext';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ToastModule } from 'primeng/toast';
import { NoResultFoundComponent } from '../../no-result-found/no-result-found.component';

@Component({
  selector: 'app-select-journey-id',
  standalone: true,
  imports: [ToastModule, DialogModule, TranslateModule, RadioButtonModule, FormsModule, InputTextModule, ReactiveFormsModule, ButtonModule, NoResultFoundComponent],
  templateUrl: './select-journey-id.component.html',
  styleUrl: './select-journey-id.component.scss'
})
export class SelectJourneyIdComponent implements OnInit{
  @Input() field: any;
  miIcons = AppIcons;
  formName: FormGroup;
  @Input() attributeLabels: [];
  @Input() moduleName: string;
  country: Country;
  language: Language;
  listColumns: any;
  tableData: any = [];
  totalRecords: number = 0;
  filterList;
  filterAttributeLabels = {};
  isLoading = true;
  isVisible: boolean = false;
  data: any;
  // attributeLabels = {};
  attributeIdForPickupDate: any;
  attributeIdForBookingForId: any;
  incidentComplaintDate: any;
  reportedBy: any;
  reporterType: any;
  selectedBookingId: string;
  isSaveBtnDisable: boolean = true;
  entityData: SearchEntityPayload = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: "",
    filters: [],
    countryCode: '',
    deleted: AppSettings.DELETED_TYPE.ONLY_NON_DELETED,
    forTenantCode: this.configService.getForTenantCode(),
    actionStatus: '',
    advanceFilters: [],
  };

  constructor(private entityService: EntityService, private configService: ConfigService,
    private cs: CommonBindingDataService, private formGroupDirective: FormGroupDirective,
    private messageService: MessageService,
  ) { 
    this.formName = this.formGroupDirective.control;
  }

  ngOnInit() {
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));
  }

  getValues() {
    this.incidentComplaintDate = this.formName.controls['incident_complaint_date'].value;
    this.reportedBy = this.formName.controls['reported_by'].value;
    this.reporterType = this.formName.controls['reporter_type'].value;
    if(this.incidentComplaintDate && this.reportedBy) {
      this.getTableView();
      this.isVisible = true;
    } else {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: this.cs.getLabel('err_please_select_reporter_type_reported_by_and_incident_complaint_date') });
    }
  }

  getTableView() {
    this.entityService.getAttributeDefinition(AppSettings.ENTITY_CODE.BOOKING, AppSettings.VIEW_CODE.BOOKING_DETAILS_ON_CONCERN_VIEW).subscribe((res) => {
      if (res) {
        this.setTableView(res);
      }
    });
  }

  setTableView(res) { 
    this.isLoading = false;
    this.data = res;
    if(this.reporterType === 'Driver') {
      this.attributeIdForBookingForId = this.getAttributeId('driver_entity_id', this.data);
    } else {
      this.attributeIdForBookingForId = this.getAttributeId('booking_for', this.data);
    }
    this.attributeIdForPickupDate = this.getAttributeId('pickup_date', this.data);
    this.searchEntity();
  }

  getAttributeId(attributeCode: string, locationAttributeData): number | undefined {
    for (const tab of locationAttributeData.tabs) {
      for (const group of tab.groups) {
        const attribute = group.fields.find(field => field.attributeCode === attributeCode);
        if (attribute) {
          return attribute.attributeId;
        }
      }
    }
    return undefined;
  }

  searchEntity() {
    this.tableData = [];
    this.entityData.filters = [];
    this.entityData.advanceFilters = [];
    this.entityData.countryCode = this.country[0].countryCode;
    this.entityData.filters.push({
      attributeId: this.attributeIdForBookingForId,
      attributeValue: this.reportedBy
    });
    this.entityData.advanceFilters.push({
      attributeId: this.attributeIdForPickupDate,
      fromValue: dayjs(this.incidentComplaintDate).startOf('day').valueOf(),
      toValue: dayjs(this.incidentComplaintDate).endOf('day').valueOf(),
      comparisonOperator: "BETWEEN",
    });
    this.entityService.searchEntity(AppSettings.ENTITY_CODE.BOOKING, this.entityData).subscribe((res: BookingResponse) => {
      if (!res) {
        console.error('Invalid response.');
        return;
      }
      this.listColumns = res?.data[0]?.values;
      this.totalRecords = res.count;
      this.tableData = res.data?.map(data => {
        return {
          id: data.id,
          ...data.values,
        };
      }) || [];
    });
  }

  onHide() {
    this.isVisible = false;
  }

  selectRadioButton(value) {
    this.selectedBookingId = value;
    if(this.selectedBookingId) {
      this.isSaveBtnDisable = false;
    }
    else {
      this.isSaveBtnDisable = true;
    }
  }

  assignData() {
    this.formName.controls[this.field.attributeCode].setValue(this.selectedBookingId);
    this.formName.controls[this.field.attributeCode].updateValueAndValidity();
    this.isVisible = false;
  }
}

import * as dayjs from 'dayjs';
import { AppSettings } from '../app.settings';

export function getTimeDiffInHours(startTime, endTime) {
  const diffInMilliseconds = endTime.getTime() - startTime.getTime();
  const hours = (((diffInMilliseconds / 1000) / 60) / 60);
  return hours > 0 ? Math.trunc(hours) : 0;
}

export function convertSeconds(seconds) {
  const hours = Math.floor(seconds / 3600);
  seconds %= 3600;
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return {
    hour: hours,
    minutes: minutes,
    seconds: remainingSeconds
  };
}

export function addTimeToCurrentDate(time) {
  console.log(time);
  const timeArray = time.split(' ');
  const hourMinuteArray = timeArray[0].split(':');
  let hour = hourMinuteArray[0];
  const minute = hourMinuteArray[1];
  if (timeArray[1] === 'PM') {
    hour = (Number(hour) + 12).toString();
  }
  const dateWithTime: any = dayjs().startOf('day').add(hour, 'hour').add(minute, 'minute');
  return dateWithTime.$d;
}
import { DatePipe, NgClass } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { RadioButtonModule } from "primeng/radiobutton";
import { AppIcons } from "../../../../../shared/app.icons";
import { AppSettings } from "../../../../../shared/app.settings";
import { EntityService } from "app/modules/shared/services/entity.service";
import { CommonBindingDataService } from "app/modules/shared/services/common-binding-data.service";

@Component({
  selector: "app-booking-tab",
  templateUrl: "./booking-tab.component.html",
  styleUrls: ["./booking-tab.component.scss"],
  standalone: true,
  imports: [RadioButtonModule, FormsModule, NgClass, TranslateModule, DatePipe],
})
export class BookingTabComponent implements OnInit {
  @Input() bookingDetailItem: any;
  @Input() dispatchBookingDetails;
  assignType: string = "Auto";
  miIcons = AppIcons;
  TRANSPORTATION_TYPE = AppSettings.TRANSPORTATION_TYPE;
  TRANSPORTATION_MODE = AppSettings.TRANSPORTATION_MODE;
  bookingStatuses: any = [];
  bookingStatusStringValue: any;
  bookingStatusIcon: any;
  bookingDetailsById: any[] = [];
  geoLocationsData: any = {};

  constructor(private entityService: EntityService,
    private cs: CommonBindingDataService
  ) { }

  ngOnInit(): void {
    this.getBookingDetail();
   }

   getBookingDetail() {
    this.entityService.getEntity(this.bookingDetailItem.id, AppSettings.ENTITY_CODE.BOOKING, AppSettings.VIEW_CODE.BOOKING_DETAILS_ON_DISPATCH_VIEW)
    .subscribe((result: any) => {
      const details = {};
      const bookingPassengers = [];
      result.attributeCodeValueDtoList.map(data => {
        details[data.attributeCode] = data.attributeValue;
      })
      result.relatedData.forEach(element => {
        const passenger = {};
        if (element.entityCode === AppSettings.ENTITY_CODE.BOOKING_PASS) {
          passenger['id'] = element.entityId;
            element.attributeCodeValueDtoList.map(data => {
              passenger[data.attributeCode] = data.attributeValue;
            })
            bookingPassengers.push(passenger);
        }
      })
      this.dispatchBookingDetails = {...details, passengers: bookingPassengers};
      this.getBookingStatuses();
      this.entityService.setEntityView(AppSettings.ENTITY_CODE.BOOKING,AppSettings.KEYS_TO_STORE_DATA_IN_SERVICE.BOOKING_DETAILS, this.dispatchBookingDetails);
    })
   }

   getBookingStatuses() {
    this.bookingStatuses.push(...this.entityService.getBookingStatuses());
    this.getBookingStatusString();
   }

   getBookingStatusString() {
    const statusObject = this.bookingStatuses.find(ele => ele.bookingStatusId === this.dispatchBookingDetails?.booking_status);
    const statusStringArray = statusObject ? JSON.parse(statusObject?.bookingStatusDisplayStr) : null;
    this.bookingStatusStringValue = (statusStringArray && statusStringArray.length > 0) ?  statusStringArray[0]?.label : null;
    this.bookingStatusIcon = statusObject ? statusObject?.bookingIcon : null;
   }
}

import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormGroup, FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonBindingDataService } from '../../../services/common-binding-data.service';
import { AppSettings } from '../../../app.settings';
import { DropdownModule } from 'primeng/dropdown';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { BlockUIModule } from 'primeng/blockui';
import { InputTextModule } from 'primeng/inputtext';
import { NgClass, NgStyle } from '@angular/common';
import { AppIcons } from 'app/modules/shared/app.icons';
import { UIElementService } from 'app/modules/shared/services/ui-element.service';
import { EntityService } from 'app/modules/shared/services/entity.service';
import { Country } from 'app/modules/shared/models/country';
import { EntityList } from 'app/modules/vehicles/models/entity.models';
import { ConfigService } from 'app/modules/shared/services/config.service';
import { ListResponse } from 'app/modules/vehicles/models/listResponse.models';
import { BookingService } from 'app/modules/bookings/services/booking.service';
import { interval, Subscription } from 'rxjs';
import { GoogleMapsService } from '../../../services/google-map.services';

@Component({
  selector: 'app-geo-transport',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, DropdownModule, TranslateModule, ButtonModule, DialogModule, BlockUIModule, InputTextModule, NgStyle, NgClass
  ],
  templateUrl: './geo-transport.component.html',
  styleUrl: './geo-transport.component.scss'
})
export class GeoTransportComponent {
  @Input() field: any;
  formName: FormGroup;
  @Input() attributeLabels: [];
  @Input() moduleName: string;
  @Input() isShowBookingMap: boolean;
  @Output() onAddressChange: EventEmitter<any> = new EventEmitter();
  defaultLbl: string;
  placeholder: any;
  map: google.maps.Map;
  infoWindow: google.maps.InfoWindow;
  visible: boolean = false;
  ENTITY_CODE = AppSettings.ENTITY_CODE;
  selectedLocation;
  markerLocation;
  zoom = 4;
  position: any;
  cancelBtnText: any;
  saveBtnText: any;
  blockedDocument: boolean = false;
  newGeoLocation: any;
  newGeoAddress: any;
  premiseAddress;
  marker;
  showGoogleSearchBox: boolean = true;
  searchText: string = null;
  suggestions: any = [];
  addressOption: string = 'saved';
  miIcons = AppIcons;
  savedAddresses:any[] | undefined;
  predefinedAddresses:any[] | undefined;
  entityData = {
    limit: AppSettings.PAGINATION_ROWS_PER_PAGE_LIMIT,
    offset: 0,
    searchStr: "",
    filters: [],
    countryCode: '',
    deleted: AppSettings.DELETED_TYPE.ONLY_NON_DELETED,
    forTenantCode: "",
    actionStatus: '',
    tableViewCode: 'LOCATION_WITH_GEOCODE_VIEW'
  };
  country: Country;
  passengerId: any;
  showMap: boolean = false;
  showBookingGoogleSearchBox: boolean = false;
  currentStopField: any;
  placeId: any;
  postalCode: any;
  countryOfAddress: any;
  state: any;


  constructor(
    private formGroupDirective: FormGroupDirective,
    public cs: CommonBindingDataService,
    private cd: ChangeDetectorRef,
    private uiService: UIElementService,
    private entityService: EntityService,
    private configService: ConfigService,
    private bookingService: BookingService,
    private googleMapsService: GoogleMapsService) {
    this.formName = formGroupDirective.control;
  }

  ngOnInit(): void {
    this.entityData.forTenantCode = this.configService.getForTenantCode();
    this.country = JSON.parse(localStorage.getItem(AppSettings.COUNTRY));

    this.bookingService.selectedPassenger$.subscribe(passengers => {
      if (passengers.length > 0) {
        this.passengerId = passengers[0].id;
        this.loadPassengerSavedAddressesById();
      } else {
          this.passengerId = null;
      }
    });

    this.field.displayedAddresses = this.savedAddresses;

    this.cancelBtnText = this.cs.getLabel("cancel");
    this.saveBtnText = this.cs.getLabel("label_save");
    this.defaultLbl = this.cs.getLabel("lbl_please_select");
    this.placeholder = this.cs.getLabelValue(this.moduleName + '.fields.' + this.field.attributeCode + '.placeholder', this.attributeLabels, this.field.attributeCode);
    if (this.isShowBookingMap) {
      this.initMap();
    }
  }

  getIconClass(locationType: string) {
    switch (locationType) {
      case 'Home':
        return this.miIcons.HOME_DARK;
      case 'Hotel':
        return this.miIcons.HOTEL_DARK;
      case 'Parking':
        return this.miIcons.BOOKING_PICKUP_POINT_ICON;
      case 'Queue':
        return this.miIcons.BOOKING_ADDITIONAL_PASSENGER_VEHICLE;
      case 'Airport Location':
        return this.miIcons.BOOKING_AIRPORT;
      case 'Other Location':
        return this.miIcons.BASIC_LOCATION;
      case 'geoMap':
        return this.miIcons.BASIC_LOCATION;
      default:
        return this.miIcons.DRIVER_RATING;
    }
  }

  showLocationOption(option: string) {
    this.addressOption = option;
    if (option === 'saved') {
      this.loadPassengerSavedAddressesById();
      this.field.displayedAddresses = this.savedAddresses;
    } else if (option === 'predefined') {
      this.loadPredefinedAddresses();
    }
  }

  passengerSidebarOpen() {
    this.uiService.showSideDriverDrawer();
  }

  changedValue() {
    this.cd.markForCheck();
    const selectedValue = this.formName.get(this.field?.isOneToMultiple ? this.field?.attributeCodeOneToMultiple : this.field.attributeCode)?.value;
    this.formName.controls[this.field?.isOneToMultiple ? this.field?.attributeCodeOneToMultiple : this.field.attributeCode].setValue(selectedValue);
  }

  loadPredefinedAddresses() {
    this.entityData.countryCode = this.country[0].countryCode;
    this.entityService.searchEntity(AppSettings.ENTITY_CODE.LOCATION, this.entityData).subscribe((response: ListResponse) => {
      console.log(response);
      this.predefinedAddresses = this.mapApiResponseToAddresses(response.data);
      this.field.displayedAddresses = this.predefinedAddresses;
    }, error => {
      console.error('Error fetching predefined addresses:', error);
      this.field.displayedAddresses = [];
    });
  }

  mapApiResponseToAddresses(data: any[]): any[] {
    return data.map(item => ({
      select_location_type: item.values.location_category,
      select_location_address: item.values.location_address,
      select_location_geolocation: item.values.location_geolocation,
      address_line_1: item.values.location_short_code,
      address_line_2: '',
      nearby_landmark: item.values.dispatch_zone,
      state: '',
      country: '',
      postal_code: '',
      location_id: item.id
    }));
  }

  loadPassengerSavedAddressesById() {
    this.entityService.getEntity(this.passengerId, AppSettings.ENTITY_CODE.PASSENGER, AppSettings.VIEW_CODE.ADD_EDIT_VIEW).subscribe((result: any) => {
      const passengerAttributes = result.attributeCodeValueDtoList;
      const relatedData = result.relatedData;
      const stayHomeAddressSection = passengerAttributes.find(attr => attr.attributeCode === AppSettings.FIELDS.STAY_HOME_ADDRESS_SECTION);

      if (stayHomeAddressSection && Array.isArray(stayHomeAddressSection.attributeValue)) {
        const stayHomeAddressIds = stayHomeAddressSection.attributeValue;
        const matchedAddresses = stayHomeAddressIds.map(id => {
          const addressData = relatedData.find(rel =>
            rel.entityCode === AppSettings.ENTITY_CODE.PASSENGER_ADDRESS && rel.entityId === id
          );
          return addressData ? addressData.attributeCodeValueDtoList : null;
        });
        this.processPassengerAddress(matchedAddresses);
      }
    });
  }

  processPassengerAddress(matchedAddresses: any[]) {
    const validAddresses = matchedAddresses.filter(addr => addr !== null);
    this.savedAddresses = validAddresses.map(address => {
      const addressMap: any = {};
      address.forEach(attr => {
        addressMap[attr.attributeCode] = attr.attributeValue;
      });
      return addressMap;
    });
    this.field.displayedAddresses = this.savedAddresses;
  }

  showDialog(field) {
    this.currentStopField = field;
    this.visible = true;
    this.blockedDocument = true;
    this.showGoogleSearchBox = true;
    this.initMap();
  }

  close() {
    this.uiService.hideGeoLocationDialog();
    this.visible = false;
    this.showMap = false;
    this.blockedDocument = false;
  }

  saveGeoLocation() {
    if (this.moduleName === 'booking') {
      const address = {
        "labelKey": this.newGeoAddress,
        "labelValue": {
          "select_location_address": this.newGeoAddress,
          "select_location_geolocation": this.newGeoLocation,
          "select_location_type": ''
        }
      };

      this.field.displayedAddresses = [];
      this.field.displayedAddresses.push(address.labelValue);

      if (this.currentStopField) {
        this.currentStopField = address.labelValue;
      }
      this.formName.controls[this.field?.isOneToMultiple ? this.field?.attributeCodeOneToMultiple : this.field.attributeCode]?.setValue(address.labelValue);
    
    } else {
      let index = -1;
      if (this.field.presetValues && this.field.presetValues.length > 0) {
        index = this.field.presetValues.findIndex(ele => ele.labelKey === this.newGeoAddress);
      } else {
        this.field.presetValues = [];
      }

      if (index === -1) {
        if (this.newGeoAddress) {
          const address = {
            labelKey: this.newGeoAddress,
            labelValue: {
              address: this.newGeoAddress,
              geoLocation: this.newGeoLocation,
              placeId: this.placeId
            }
          };
          this.field.presetValues.push(address);
          this.formName.controls[this.field?.isOneToMultiple ? this.field?.attributeCodeOneToMultiple : this.field.attributeCode].setValue(address.labelValue);
          if (this.moduleName === AppSettings.ENTITY_CODE.PASSENGER_ADDRESS || this.moduleName === AppSettings.ENTITY_CODE.GENERAL_SETTING_ADDRESS) {
            const addressIndex = this.field.attributeCodeOneToMultiple.charAt(0);
            this.formName.controls[`${addressIndex}_${AppSettings.ATTRIBUTE_CODE_FOR_STAY_HOME_ADDRESS.COUNTRY}`].setValue(this.country);
            this.formName.controls[`${addressIndex}_${AppSettings.ATTRIBUTE_CODE_FOR_STAY_HOME_ADDRESS.STATE}`].setValue(this.state);
            this.formName.controls[`${addressIndex}_${ this.moduleName === AppSettings.ENTITY_CODE.PASSENGER_ADDRESS ? AppSettings.ATTRIBUTE_CODE_FOR_STAY_HOME_ADDRESS.POSTAL_CODE : AppSettings.ATTRIBUTE_CODE_FOR_STAY_HOME_ADDRESS.PINCODE}`].setValue(this.postalCode);
          }
        }
      }
    }

    this.visible = false;
    this.blockedDocument = false;
    this.showGoogleSearchBox = false;
  }

  async initMap(): Promise<void> {
    this.searchText = null;
    const { Map } = await google.maps.importLibrary('maps') as google.maps.MapsLibrary;
    const { AdvancedMarkerElement } = await google.maps.importLibrary('marker') as google.maps.MarkerLibrary;
    this.map = new Map(document.getElementById('map') as HTMLElement, {
      center: { lat: 18.509863497519373, lng: 74.31908731567906 },
      zoom: 8,
      mapTypeControl: false,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      mapId: this.googleMapsService.mapId
    });

    this.infoWindow = new google.maps.InfoWindow();
    const element = document.getElementById('googleSearch');
    this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(element);
    element.focus();

    const marker = new AdvancedMarkerElement({
      map: this.map,
      position: this.position,
      gmpDraggable: true
    });

    const options = {
      fields: ['formatted_address', 'geometry', 'name'],
      strictBounds: false,
    };

    const inputHtmlElement = document.getElementById('autocompleteSearch') as HTMLInputElement;
    const autocomplete = new google.maps.places.Autocomplete(inputHtmlElement, options);
    autocomplete.bindTo('bounds', this.map);

    autocomplete.addListener('place_changed', () => {
      const place = autocomplete.getPlace();
      if (place.geometry.viewport) {
        this.map.fitBounds(place.geometry.viewport);
      } else {
        this.map.setCenter(place.geometry.location);
        this.map.setZoom(17);
      }
      this.newGeoAddress = place.formatted_address;
      marker.position = place.geometry.location;
      this.newGeoLocation = place.geometry.location.lat() + ',' + place.geometry.location.lng();
      const latLong = place.geometry.location.lat() + ', ' + place.geometry.location.lng();
      this.getReverseGeoCodeFromLatLng(latLong);
    });


    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.position = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          marker.position = this.position;
          const latLong = this.position.lat + ', ' + this.position.lng;
          this.getReverseGeoCodeFromLatLng(latLong);
          marker.addListener('dragend', (event) => {
            const currentPosition = marker.position as google.maps.LatLng;
            this.position = { lat: currentPosition.lat, lng: currentPosition.lng };
            const latLong = this.position.lat + ',' + this.position.lng;
            this.getReverseGeoCodeFromLatLng(latLong);
          });
          this.map.panTo(this.position);
        },
        () => {
          this.handleLocationError(true, this.infoWindow, this.map.getCenter());
        });
    } else {
      this.handleLocationError(false, this.infoWindow, this.map.getCenter());
    }
  }

  getReverseGeoCodeFromLatLng(latLong) {
    this.entityService.getReverseGeoCodeUsingLatLng(latLong).subscribe((result: any) => {
      console.log(result);
      if (result && result.results.length > 0) {
        this.newGeoAddress = null;
        this.newGeoAddress = result.results[0].formattedAddress;
        const { lat, lng } = result.results[0].geometry.location
        this.newGeoLocation = `${lat},${lng}`;
        this.placeId = result.results[0].placeId;
        this.setCountryStatePostalCodeForAddress(result);
      }
    })
  }

  setCountryStatePostalCodeForAddress(result) {
    if (this.moduleName === AppSettings.ENTITY_CODE.PASSENGER_ADDRESS || this.moduleName === AppSettings.ENTITY_CODE.GENERAL_SETTING_ADDRESS) {
      result.results[0].addressComponents.forEach(addressComponent => {
        if (addressComponent?.types.includes('postal_code')) {
          this.postalCode = addressComponent?.longName;
        }
        if (addressComponent?.types.includes('country')) {
          this.country = addressComponent?.longName;
        }
        if (addressComponent?.types.includes('administrative_area_level_1')) {
          this.state = addressComponent?.longName;
        }
      });  
    }
  }

  getAndStoreAddress(event: any, addressType?: any) {
    this.selectedLocation = event;
    this.markerLocation = { lat: this.selectedLocation.lat, lng: this.selectedLocation.lng };

    const geoLocationValue = {
      address: this.selectedLocation.address,
      lat: this.selectedLocation.lat,
      lng: this.selectedLocation.lng
    };
    if (this.moduleName === AppSettings.ENTITY_CODE.PASSENGER) {
      this.formName.controls[AppSettings.PASSENGER_ATTRIBUTES.PASSENGER_ADDRESS].setValue(geoLocationValue);
      this.formName.controls[AppSettings.PASSENGER_ATTRIBUTES.PASSENGER_GEOLOCATION].setValue(geoLocationValue.address);
    }
  }

  handleLocationError(browserHasGeolocation, infoWindow, pos) {
    infoWindow.setPosition(pos);
    infoWindow.setContent(
      browserHasGeolocation
        ? "Error: The Geolocation service failed."
        : "Error: Your browser doesn't support geolocation.",
    );
    infoWindow.open(this.map);
  }

}

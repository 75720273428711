
<div id="shift-cells" class="scroll">
    <div class="flex">
        <div class="driver-list-column pr-0">
            @if (driverList.length === 0) {
                <div class="first-column first-row first-cell pl-3 pt-2 pb-2 flex align-items-center">
                    <div class="inline-flex dropdown-cell justify-content-around">
                        <p-dropdown [options]="listTypeCopy"  [(ngModel)]="selectedListType" (onChange)="onListTypeChange($event)" optionLabel="name" optionValue="value">
                            <ng-template  pTemplate="dropdownicon">
                                <i class="mi-lg caret-down-2 mt-2"></i>
                            </ng-template>
                        </p-dropdown>
                        <div class="filter-az" (click)="sortDrivers()">
                            <span translate>shift.az</span>
                            <i class="mi-lg mi-filter_2"></i>
                        </div>
                    </div>
                </div>
            }
    
            @for(driver of driverList; track driver; let j=$index) {
                @if (j === 0) {
                    <div id="firstRowColumn" class="first-column first-row first-cell pl-3 pt-2 pb-2 flex align-items-center">
                        <div class="inline-flex dropdown-cell justify-content-around">
                            <p-dropdown [options]="listTypeCopy"  [(ngModel)]="selectedListType" (onChange)="onListTypeChange($event)" optionLabel="name" optionValue="value">
                                <ng-template  pTemplate="dropdownicon">
                                    <i class="mi-lg caret-down-2"></i>
                                </ng-template>
                            </p-dropdown>
                            <div class="filter-az" (click)="sortDrivers()">
                                @if (isAscendingData) {
                                    <span translate>shift.za</span>
                                } @else {
                                    <span translate>shift.az</span>
                                }
    
                                <i class="mi-lg mi-filter_2"></i>
                            </div>
                        </div>
                    </div>
                }
    
                <div class="col-12 flex td">
                    <div class="column-cells pl-3 flex align-items-center">
                        <div class="inline-flex">
                            <div class="inline-flex day-list-image">
                                <app-mi-image-container [miImage]="driver.driverProfileImage" miWidth="38"></app-mi-image-container>
                            </div>
                           <div class="block w-13rem ml-2">
    
                            <div class="flex justify-content-between flex-wrap">
                                <div class="flex align-items-center justify-content-center ">
                                    <span class="flex align-items-center ellipse driver-name"  (mouseover)="op.toggle($event); hoveredDriver = driver" (mouseout)="op.toggle($event)">{{driver.driverName}}</span>
                                </div>
                                <div class="flex align-items-center justify-content-center ">
                                    <i class="mi-lg mi-endofshift"></i>
                                    <span class="total-week-hours ml-1">{{driver.workingHours / 60}} hrs</span>
                                </div>
                            </div>
                                <div class="inline-flex">
                                        <span class="group-name">({{driver.driverGroup}})</span>
                                        <span class="ml-2 mr-2 vertical-line">|</span>
                                        <span class="driver-unique-id">{{driver.uniqueCode}}</span>
                                </div>
                           </div>
                        </div>
    
                    </div>
                </div>
            }
        </div>
    
        <div class="toolbar-scroll pl-0">
            <!-- day row -->
            <div id="dayTime">
                <ul>
                    @if (datePipe.transform(selectedDayDate, 'dd MM yyyy') === datePipe.transform(currentDate, 'dd MM yyyy')) {
                        <span class="current-time" [style.margin-left.px]="((((currentDate | date: 'H') % 24) * 52) + 26 + (+(currentDate | date: 'm')))">
                            <span class="circle"></span>
                        </span>
                    }
    
                    @for (hourGroup of hourList; track hourGroup; let i = $index) {
                        <li class="first-row">
                                <div class="hour-row align-items-center flex pt-2 pb-2">
                                    @for (hour of hourGroup; track hour; let h = $index) {
                                            <div class="hour font-bold text-center"> {{ hour}} </div>
    
                                    }
                                </div>
                        </li>
                    }
                </ul>
            </div>
    
            <div>
                    @for(driver of driverList; track driver; let m=$index) {
                        <ul>
                            @for (hourGroup of hourList; track hourGroup; let n = $index) {
                                <li>
                                        <div class="shift-cells flex align-items-center">
                                            @for (hour of hourGroup; track hour; let k = $index) {
                                                @for (shift of driver.shift; track shift; let l = $index) {
                                                    @if (hourFormat === '12') {
                                                        @if (shift?.startTimeHour?.toUpperCase().trim() === (hour + 'm').toUpperCase().trim()) {
                                                            @if ((shift.startDateStr | date: 'dd MM yyyy') === (selectedDayTimeStamp | date: 'dd MM yyyy')) {   
                                                                                                            
                                                                @if (shift.isLeave) {
                                                                    <div class="block shift-block"  [style.width.px]="((+(52/60)) + (+ (24 * 60)) + (+18))">
                                                                        <div class="day-off ml-2 align-content-center">
                                                                            <div class="ml-2 uppercase" translate>shift.dayOff </div>
                                                                            <div class="ml-2 font-semibold"> {{shift.leaveReason}}</div>
                                                                        </div>
                                                                    </div>
                                                                } @else if (shift.isShiftPublish) {
                                                                    <div class="block shift-block">
                                                                        @if (shift.endTimeStr) {
                                                                            <div class="shift-time align-content-center pointer" [style.margin-left.px]="((+(k * 52)) + (+(shift.startTime)) + (+18))" [style.width.px]="(shift.timeDifferenceInMinutes * (+(52/60)))" (click)="shiftOverlay.toggle($event); hoveredPublishedUnPublishedShift = shift; publishedUnpublishedShiftDriver = driver">
                                                                                <div class="ml-2 uppercase"> {{ shift.shiftName }}</div>
                                                                                <div class="ml-2 font-semibold"> <span>{{shift.startTimeStr | date : hourFormat === '12' ? 'hh:mm a' : 'HH:mm'}} -  {{shift.endTimeStr | date : hourFormat === '12' ? 'hh:mm a' : 'HH:mm'}}</span> </div>
                                                                            </div>
                                                                        } @else {
                                                                            <div class="shift-time ml-2 align-content-center pointer" (click)="shiftOverlay.toggle($event); hoveredPublishedUnPublishedShift = shift; publishedUnpublishedShiftDriver = driver" [class]="shift.dateDifference > 1 ? 'line-img' : ''" [style.width.px]="((52/60) * 24 * 60)" >
                                                                                <div class="ml-2 uppercase"> {{ shift.shiftName }}</div>
                                                                                <div class="ml-2 font-semibold"> <span>{{shift.startDateStr  | date: 'd MMM'}} -  {{shift.endDate  | date: 'd MMM'}}</span> <span class="dot ml-2"></span> <span class="ml-2">{{shift.startTimeStr | date : hourFormat === '12' ? 'hh:mm a' : 'HH:mm'}} -  <span translate>shift.noEndTime</span></span> </div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                } @else if (!shift.isShiftPublish) {
                                                                    @if (shift.endTimeStr) {
                                                                        <div class="block shift-block" (click)="shiftOverlay.toggle($event); hoveredPublishedUnPublishedShift = shift; publishedUnpublishedShiftDriver = driver">
                                                                            <div class="unpublish-shift flex align-items-start pointer flex-column" [style.margin-left.px]="((+(k * 52)) + (+((shift.startTime) * (52/60))) + (+18))" [style.width.px]="((shift.timeDifferenceInMinutes * (+(52/60)) + (n*4)))">
                                                                                <div class="ml-2 uppercase shift-name"> {{ shift.shiftName }}</div>
                                                                                <div class="ml-2 font-semibold shift-start-time"> {{shift.startTimeStr | date : hourFormat === '12' ? 'hh:mm a' : 'HH:mm'  }} -  {{shift.endTimeStr | date : hourFormat === '12' ? 'hh:mm a' : 'HH:mm'}}</div>
                                                                            </div>
                                                                        </div>
                                                                    } @else {
                                                                        <div class="shift-time ml-2 flex align-items-center pointer" (click)="shiftOverlay.toggle($event); hoveredPublishedUnPublishedShift = shift; publishedUnpublishedShiftDriver = driver" [class]="shift.dateDifference > 1 ? 'line-img' : ''" [style.width.px]="(+(52/60) * 24 * 60)" >
                                                                            <div class="ml-2 uppercase"> {{ shift.shiftName }}</div>
                                                                            <div class="ml-2 font-semibold"> <span>{{shift.startDateStr  | date: 'd MMM'}} -  {{shift.endDate  | date: 'd MMM'}}</span> <span class="dot ml-2"></span> <span class="ml-2">{{shift.startTime  | date: 'h:mma'}} -  <span translate>shift.noEndTime</span></span> </div>
                                                                        </div>
                                                                    }
                                                                } @else {
                                                                    <div class="shift-time align-content-center pointer" [style.margin-left.px]="((+(k * 52)) + (+(shift.startTime)) + (+18))" [style.width.px]="(shift.timeDifferenceInMinutes * (+(52/60)))" (click)="shiftOverlay.toggle($event); hoveredPublishedUnPublishedShift = shift; publishedUnpublishedShiftDriver = driver">
                                                                        <div class="ml-2 uppercase"> {{ shift.shiftName }}</div>
                                                                        <div class="ml-2 font-semibold"> <span>{{shift.startTimeStr | date : hourFormat === '12' ? 'hh:mm a' : 'HH:mm'}} -  {{shift.endTimeStr  | date : hourFormat === '12' ? 'hh:mm a' : 'HH:mm'}}</span> </div>
                                                                    </div>
                                                                }
                
                
                                                            }
                                                        }
                                                    } @else {
                                                       
                                                        @if (shift?.startTimeHour.toString() === hour.toString()) {
                                                            @if ((shift.startDateStr | date: 'dd MM yyyy') === (selectedDayTimeStamp | date: 'dd MM yyyy')) {                                                                                                           
                                                                @if (shift.isLeave) {
                                                                    <div class="block shift-block"  [style.width.px]="((+(52/60)) + (+ (24 * 60)) + (+18))">
                                                                        <div class="day-off ml-2 align-content-center">
                                                                            <div class="ml-2 uppercase" translate>shift.dayOff </div>
                                                                            <div class="ml-2 font-semibold"> {{shift.leaveReason}}</div>
                                                                        </div>
                                                                    </div>
                                                                } @else if (shift.isShiftPublish) {
                                                                    <div class="block shift-block">
                                                                        @if (shift.endTimeStr) {
                                                                            <div class="shift-time align-content-center pointer" [style.margin-left.px]="((+(k * 52)) + (+(shift.startTime)) + (+18))" [style.width.px]="(shift.timeDifferenceInMinutes * (+(52/60)))" (click)="shiftOverlay.toggle($event); hoveredPublishedUnPublishedShift = shift; publishedUnpublishedShiftDriver = driver">
                                                                                <div class="ml-2 uppercase"> {{ shift.shiftName }}</div>
                                                                                <div class="ml-2 font-semibold"> <span>{{shift.startTimeStr | date : hourFormat === '12' ? 'hh:mm a' : 'HH:mm'}} -  {{shift.endTimeStr | date : hourFormat === '12' ? 'hh:mm a' : 'HH:mm'}}</span> </div>
                                                                            </div>
                                                                        } @else {
                                                                            <div class="shift-time ml-2 align-content-center pointer" (click)="shiftOverlay.toggle($event); hoveredPublishedUnPublishedShift = shift; publishedUnpublishedShiftDriver = driver" [class]="shift.dateDifference > 1 ? 'line-img' : ''" [style.width.px]="((52/60) * 24 * 60)" >
                                                                                <div class="ml-2 uppercase"> {{ shift.shiftName }}</div>
                                                                                <div class="ml-2 font-semibold"> <span>{{shift.startDateStr  | date: 'd MMM'}} -  {{shift.endDate  | date: 'd MMM'}}</span> <span class="dot ml-2"></span> <span class="ml-2">{{shift.startTimeStr | date : hourFormat === '12' ? 'hh:mm a' : 'HH:mm'}} -  <span translate>shift.noEndTime</span></span> </div>
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                } @else if (!shift.isShiftPublish) {
                                                                    @if (shift.endTimeStr) {
                                                                        <div class="block shift-block" (click)="shiftOverlay.toggle($event); hoveredPublishedUnPublishedShift = shift; publishedUnpublishedShiftDriver = driver">
                                                                            <div class="unpublish-shift flex align-items-start pointer flex-column" [style.margin-left.px]="((+(k * 52)) + (+((shift.startTime) * (52/60))) + (+18))" [style.width.px]="((shift.timeDifferenceInMinutes * (+(52/60)) + (n*4)))">
                                                                                <div class="ml-2 uppercase shift-name"> {{ shift.shiftName }}</div>
                                                                                <div class="ml-2 font-semibold shift-start-time"> {{shift.startTimeStr  | date : hourFormat === '12' ? 'hh:mm a' : 'HH:mm' }} -  {{shift.endTimeStr | date : hourFormat === '12' ? 'hh:mm a' : 'HH:mm'}}</div>
                                                                            </div>
                                                                        </div>
                                                                    } @else {
                                                                        <div class="shift-time ml-2 flex align-items-center pointer" (click)="shiftOverlay.toggle($event); hoveredPublishedUnPublishedShift = shift; publishedUnpublishedShiftDriver = driver" [class]="shift.dateDifference > 1 ? 'line-img' : ''" [style.width.px]="(+(52/60) * 24 * 60)" >
                                                                            <div class="ml-2 uppercase"> {{ shift.shiftName }}</div>
                                                                            <div class="ml-2 font-semibold"> <span>{{shift.startDateStr  | date: 'd MMM'}} -  {{shift.endDate  | date: 'd MMM'}}</span> <span class="dot ml-2"></span> <span class="ml-2">{{shift.startTime  | date: 'h:mma'}} -  <span translate>shift.noEndTime</span></span> </div>
                                                                        </div>
                                                                    }
                                                                } @else {
                                                                    <div class="shift-time align-content-center pointer" [style.margin-left.px]="((+(k * 52)) + (+(shift.startTime)) + (+18))" [style.width.px]="(shift.timeDifferenceInMinutes * (+(52/60)))" (click)="shiftOverlay.toggle($event); hoveredPublishedUnPublishedShift = shift; publishedUnpublishedShiftDriver = driver">
                                                                        <div class="ml-2 uppercase"> {{ shift.shiftName }}</div>
                                                                        <div class="ml-2 font-semibold"> <span>{{shift.startTimeStr  | date : hourFormat === '12' ? 'hh:mm a' : 'HH:mm'}} -  {{shift.endTimeStr  | date : hourFormat === '12' ? 'hh:mm a' : 'HH:mm'}}</span> </div>
                                                                    </div>
                                                                }
                
                
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        </div>
                                </li>
                            }
                        </ul>
            
                    }

        </div>
    
        </div>
    </div>
@if (driverList.length === 0) {
    <app-no-result-found></app-no-result-found>
}
<!-- calendar code end -->
</div>



<!-- Driver overlay panels -->
<p-overlayPanel #op showCloseIcon="true" appendTo="body">
    <ng-template pTemplate="content">
        <div class="grid">
            <div class="col-2">
                <app-mi-image-container [miImage]="hoveredDriver.driverProfileImage" miHeight="38" miWidth="38"></app-mi-image-container>
            </div>
            <div class="col-10 pl-0">
                <div class="flex">
                    <label class="driver-overlay-name mr-2">{{hoveredDriver.driverName}}</label> <label class="mr-2">(Driver)</label>
                    <span class="group">
                        <i class="mi-lg mi-driver"></i>
                        <span class="ml-1">
                            {{hoveredDriver.driverGroup}}
                        </span>
                    </span>
                </div>
                <div> <label class="unique-id"> {{hoveredDriver.uniqueCode}} </label>
                </div>
            </div>
        </div>
        <div class="grid mt-2">
            <div class="col-5 label-text" translate>shift.status</div>
            <div class="col-6 label-value capitalize">
                <span [class]="hoveredDriver.status.toLowerCase()"></span>
                <span [class]="hoveredDriver.status.toLowerCase"></span> {{hoveredDriver.status}}
            </div>
        </div>
        <hr class="hr">
        <div class="grid mt-2">
            <div class="col-5 label-text" translate>shift.gender</div>
            <div class="col-6 label-value">{{hoveredDriver.gender}}</div>
        </div>
        <hr class="hr">
        <div class="grid mt-2">
            <div class="col-5 label-text" translate>shift.contactNumber</div>
            <div class="col-6 label-value">{{hoveredDriver.mobileNumber}}</div>
        </div>
        <hr class="hr">
        <div class="grid mt-2">
            <div class="col-5 label-text" translate>shift.email</div>
            <div class="col-6 label-value">{{hoveredDriver.email}}</div>
        </div>
        <hr class="hr">
        <div class="grid  mt-2">
            <div class="col-5 label-text" translate>shift.lbl_vehicle</div>
            <div class="col-6 label-value">{{hoveredDriver.vehicle}}</div>
        </div>
    </ng-template>
</p-overlayPanel>


<!-- unpublish, publish shift overlay panel -->
<p-overlayPanel [style]="{ width: '35rem' }" showCloseIcon="true"  #shiftOverlay appendTo="body">
    <ng-template pTemplate="content">
        <div class="grid">
            <div class="col-1">
                <app-mi-image-container [miImage]="publishedUnpublishedShiftDriver.driverProfileImage" miHeight="38" miWidth="38"></app-mi-image-container>
            </div>
            <div class="col-11 pl-3">
                <div class="flex align-items-end">
                    <label class="driver-overlay-name mr-2 ellipse-for-shift-popup"  [pTooltip]="publishedUnpublishedShiftDriver.driverName" tooltipPosition="top" [tooltipDisabled]="publishedUnpublishedShiftDriver.driverName?.length <= 18" >{{publishedUnpublishedShiftDriver.driverName}}</label> <label class="mr-2 font-14">(Driver)</label>
                    <span class="group">
                        <i class="mi-lg mi-scheduledbreak"></i>
                        <span class="ml-1" [pTooltip]="publishedUnpublishedShiftDriver.driverGroup" tooltipPosition="top" [tooltipDisabled]="publishedUnpublishedShiftDriver.driverGroup?.length <= 12">
                            {{publishedUnpublishedShiftDriver.driverGroup | truncate:12}}
                          </span>
                    </span>
                </div>
                <div> <label class="unique-id"> {{publishedUnpublishedShiftDriver.uniqueCode}}</label> </div>
            </div>

        </div>
        @if (hoveredPublishedUnPublishedShift.endTimeStr) {
            <div class="grid">
                <div class="col-4 label-text">
                    <i class="mi-lg calendar_calendar"></i>
                    <span class="ml-1">  {{hoveredPublishedUnPublishedShift.startDateStr  | date: 'dd MMM'}} </span>
                </div>
                <div class="col-8 label-value">
                    <i class="mi-lg mi-endofshift"></i>
                    <span class="ml-1">  {{hoveredPublishedUnPublishedShift.startTimeStr  | date : hourFormat === '12' ? 'hh:mm a' : 'HH:mm'}} -  {{hoveredPublishedUnPublishedShift.endTimeStr  | date : hourFormat === '12' ? 'hh:mm a' : 'HH:mm'}}</span>

                </div>
            </div>
        } @else {
            <div class="grid">
                <div class="col-4 label-text">
                    <i class="mi-lg calendar_calendar"></i>
                    <span class="ml-1">  {{hoveredPublishedUnPublishedShift.startDateStr  | date: 'd MMM'}} -  {{hoveredPublishedUnPublishedShift.endDateStr  | date: 'd MMM'}} </span>
                </div>
                <div class="col-8 label-value">
                    <i class="mi-lg mi-endofshift"></i>
                    <span class="ml-1">  {{hoveredPublishedUnPublishedShift.startTimeStr  | date : hourFormat === '12' ? 'hh:mm a' : 'HH:mm'}} -  <span translate>shift.noEndTime</span></span>
                </div>
            </div>
        }

        <hr class="hr">
        <div class="grid">
            <div class="col-4" translate>
                <i class="mi-lg mi-breaks"></i>
                <label class="label-text ml-2" translate>shift.breaks</label>
            </div>
            @for (break of hoveredPublishedUnPublishedShift.shiftBreakList; track break; let n = $index) {
                <div class="col-8 label-value">
                    <div>
                        <i class="mi-lg mi-endofshift"></i>
                        <span class="ml-1">  {{break.breakStartTime  | date : hourFormat === '12' ? 'hh:mm a' : 'HH:mm'}} -  {{break.breakEndTime  | date : hourFormat === '12' ? 'hh:mm a' : 'HH:mm'}}</span>
                    </div>
                </div>
            }
        </div>
    </ng-template>
</p-overlayPanel>

<p-breadcrumb [model]="routePath"></p-breadcrumb>
<div class="flex justify-content-end flex-wrap btn-with-links">
    <button pButton class="mr-2 bg-white default-text-light-black-color cursor-pointer border-button"
        [label]="'cancel' | translate" (click)="onCancel()"></button>
    <p-button class="flex align-items-center justify-content-center cursor-pointer"
        [label]="bookingBtnLabel | translate" (click)="onNextBtnClick()"></p-button>
</div>
<p-tabView [(activeIndex)]="activeIndex">
    <p-tabPanel>
        <ng-template pTemplate="header">
            <div class="tab-icons">
               @if(bookingAttributeData?.tabs[0].tabCode){<i class="mi-lg" [class]="bookingAttributeData?.tabs[0].tabCode"></i>} 
                <span>{{ cs.getLabelValue('booking.tabs.'+bookingAttributeData?.tabs[0].tabCode+'.label',attributeLabels,bookingAttributeData?.tabs[0].tabCode) }}</span>
            </div>
        </ng-template>
        <div class="grid m-0 p-0">
            <div class="col col-7 p-0 justify-content-center">
                <div class="booking-container ">
                    <app-transportation-type [bookingStatus]="bookingStatus" [isEdit]="isEdit" (transportationType)="transportationType($event)"
                        (transportationTypeList)="handleTransportationTypeList($event)"></app-transportation-type>
                    @if (bookingAttributeData && viewReady) {
                        <app-booking-form
                            [attributeLabels]="attributeLabels"
                            [attributeValues]="attributeValues"
                            [moduleName]="moduleName"
                            [groups]="bookingAttributeData?.tabs[0].groups"
                            [petGroup]="bookingPetInfo"
                            [isEdit]="isEdit"
                            (saveData)="onSaveBooking($event)"
                            [submitBtnText]="bookingBtnLabel" 
                            [saveBtnId]="'saveBookingId'" [transportationTypeView]="transportationTypeView"
                            (switchChanged)="onSwitchChanged($event)"
                            [isAssignNowClicked]="isAssignNowClicked"
                            [luggageBookingIds]="luggageBookingIds"
                            [passengerBookingIds]="passengerBookingIds"
                            [mainBookingId]="mainBookingId"
                            [ownerPassDetails]="ownerPassDetails"
                            [coPassengerDetails]="coPassengerDetails"
                            [petInfoDetails]="petInfoDetails"
                            [stopLocations]="stopLocations"
                            [bookingStatus]="bookingStatus"
                            ></app-booking-form>
                    }
                </div>
            </div>

            
            @if (transportationTypeView !== 'Vehicle Transfer') {
                <div class="col col-5 p-0 tab-group" [class.disabled-form]="isAssignNowClicked">
                    @if(isAssignNowClicked){
                        <div class="form-overlay"></div>
                    }
                    <div class="empty-container"></div>
                    <div class="passenger-container">
                        <div class="p-fluid p-formgrid grid">
                            <div class="field col-12 align-content-end md:col-12">
                                @if(bookingBookingPass){
                                <app-select-passengers
                                [attributeLabels]="attributeLabels"
                                [attributeValues]="attributeValues"
                                 [bookingPass]="bookingPass"
                                 [bookingBookingPass]="bookingBookingPass" 
                                 [addedPassengerList]="addedPassengerList" 
                                 [filteredLabels]="filteredLabels"
                                [passengerView]="passengerView"
                                (passengerList)="onPassengerList($event)"
                                [isEdit]="isEdit"></app-select-passengers>
                                }
                            </div>
                            <div class="field col-12 align-content-end md:col-12">
                                @if (uiService.sideDriverDrawerState){
                                    <app-booking-passenger-address (onToggleOverLay)="handleOverlayToggle()" [passengerAddressVisible]="uiService.sideDriverDrawerState" [passengerId]="passengerId"></app-booking-passenger-address>
                                }
                                </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    </p-tabPanel>
</p-tabView>
<p-dialog [(visible)]="displayAssignPopup" [modal]="true" [responsive]="true" [style]="{width: '550px'}" [closable]="true">
    <p-header>Booking Created Successfully</p-header>
    <div>
        <p>Would you like to assign vehicle now?</p>
    </div>
    <p-footer>
        <button pButton type="button" class="later bg-white" label="Later" (click)="onLater()"></button>
        <button pButton type="button" class="assign" label="Assign now" (click)="onAssignNow()"></button>
    </p-footer>
</p-dialog>
<router-outlet></router-outlet>


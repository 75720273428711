<div class="grid mt-2 ml-3 mr-3 overview-details">
    <div class="col-6 md:col-12 lg:col-6 pl-0">
        <div class="col p-0">
            <div class="card p-fluid">
                <div class="flex justify-content-between flex-wrap">
                    <div class="flex justify-content-center mb-3">
                        <label class="card-heading uppercase text-lg" translate>lbl_details</label>
                    </div>
                    <div appAccessProvider [accessCode]="{ menuCode: 'DRIVERS', subMenuCode: 'ALL_DRIVERS', pageCode: 'ALL', subPageCode: 'EDIT' }">
                        <div (click)="edit()" class="edit justify-content-center mb-3"  [ngClass]="{'hidden': isDeleted}">
                            <label class="card-action" translate>lbl_edit</label>
                            <i class="mi-lg edit_pencil"></i>
                        </div>
                    </div>
                </div>
                @for (field of details?.fields; track field; let i = $index) {
                    @if (i !== 0 && i !== 2) {
                        <hr class="hr-line">
                    }
                    @if (field.attributeCode === 'driver_profile_image') {
                        <div class="grid">
                            <div class="col-6 pt-1 mt-1">
                                <label class="detail-heading" translate>{{cs.getLabelValue('driver.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                            </div>
                            <div class="col-6 driver-logo-image">
                                @if (driverProfileImage) {
                                    <app-mi-image-container [miImage]="driverProfileImage" miWidth="100" ></app-mi-image-container>
                                } @else {
                                    <label class="detail-value" translate>lbl_empty</label>
                                }
                            </div>
                        </div>
                    } @else if(field.attributeCode === 'first_name'){
                        <div class="grid">
                            <div class="col-6 pt-1">
                                <label class="detail-heading" translate>lbl_name</label>
                            </div>
                            <div class="col-6 pb-1">
                                <label class="detail-value">{{ attributeValues['first_name'] + ' ' +  attributeValues['last_name']}}</label>
                            </div>
                        </div>
                    } @else if(field.attributeCode === 'last_name'){
                        <div class="hidden">
                        </div>
                    } @else if(field.attributeCode === 'space_1'){
                        <div class="hidden">
                        </div>
                    } @else if (field.attributeCode === 'date_of_birth') {
                        <div class="grid">
                            <div class="col-6 pt-1">
                                <label class="detail-heading" translate>{{cs.getLabelValue('driver.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                            </div>
                            <div class="col-6 pb-1">
                                <label class="detail-value">{{ dateOfBirth }}</label>
                            </div>
                        </div>
                    } @else if (field.attributeCode === 'mobile_number') {
                        <div class="grid">
                            <div class="col-6 pt-1">
                                <label class="detail-heading" translate>{{cs.getLabelValue('driver.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                            </div>
                            <div class="col-6 pb-1">
                                <app-mi-mobile-value [attributeValues]="attributeValues" [countryCodeForMobileNumber]="countryCodeForMobileNumber"  [field]="field"></app-mi-mobile-value>
                            </div>
                        </div>
                    } @else if (field.attributeCode === 'emergency_contact_number') {
                        <div class="grid">
                            <div class="col-6 pt-1">
                                <label class="detail-heading" translate>{{cs.getLabelValue('driver.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                            </div>
                            <div class="col-6 pb-1">
                                <app-mi-mobile-value [attributeValues]="attributeValues" [countryCodeForMobileNumber]="countryCodeForEmergencyContact" [field]="field"></app-mi-mobile-value>
                            </div>
                        </div>
                    } @else if(field.attributeCode === 'driver_group_id'){
                        <div class="grid">
                            <div class="col-6 pt-1">
                                <label class="detail-heading" translate>{{cs.getLabelValue('driver.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                            </div>
                            <div class="col-6 pb-1">
                                <div class="grid group-color">
                                    @if(driverGroupList.length > 0){
                                        <app-group-color [ngClass]="{'hidden': driverGroupList.length === 0}" [colorCode]="driverGroupList[0]?.driverGroupColor" [moduleName]="'driver'"></app-group-color> 
                                        <label class="detail-value group-label">{{ driverGroupList[0]?.driverGroupName }}</label>
                                    }
                                    @else {
                                        <label class="detail-value group-label-empty">{{ 'lbl_empty' | translate }}</label>
                                    }
                                </div>
                            </div>
                        </div>
                    } @else if(field.attributeCode === 'languages_spoken'){
                        <div class="grid">
                            <div class="col-6 pt-1">
                                <label class="detail-heading" translate>lbl_languages_spoken</label>
                            </div>
                            <div class="col-6 pb-1">
                                <label class="detail-value">{{ attributeValues[field.attributeCode] ? (attributeValues[field.attributeCode] | commaSpace) : ('lbl_empty' | translate) }}</label>
                            </div>
                        </div>
                    } @else {
                        <div class="grid">
                            <div class="col-6 pt-1">
                                <label class="detail-heading" translate>{{cs.getLabelValue('driver.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                            </div>
                            <div class="col-6 pb-1">
                                <label class="detail-value">{{ attributeValues[field.attributeCode] ? attributeValues[field.attributeCode] :  ('lbl_empty' | translate) }}</label>
                            </div>
                        </div>
                    }
                }
            </div>
        </div>
        <div class="col p-0">
            <div class="card p-fluid">
                <div class="flex justify-content-between flex-wrap">
                    <div class="flex justify-content-center mb-3">
                        <label class="card-heading uppercase text-lg" translate>lbl_address_information</label>
                    </div>
                    <div appAccessProvider [accessCode]="{ menuCode: 'DRIVERS', subMenuCode: 'ALL_DRIVERS', pageCode: 'ALL', subPageCode: 'EDIT' }">
                        <div (click)="edit()" class="edit flex justify-content-center mb-3"  >
                            <label class="card-action" [ngClass]="{'hidden': isDeleted}" translate>lbl_edit</label>
                            <i class="mi-lg edit_pencil" [ngClass]="{'hidden': isDeleted}"></i>
                        </div>
                    </div>
                </div>
                @for (field of addressInformation?.fields; track field; let i = $index) {
                    @if (i !== 0) {
                        <hr class="hr-line">
                    }
                    <div class="grid">
                        <div class="col-6 pt-1">
                            <label class="detail-heading" translate>{{cs.getLabelValue('driver.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                        </div>
                        <div class="col-6 pb-1">
                            <label class="detail-value">{{ attributeValues[field.attributeCode] ? attributeValues[field.attributeCode] :  ('lbl_empty' | translate) }}</label>
                        </div>
                    </div>
                }
            </div>
        </div>
    </div>
    <div class="col-6 md:col-12 lg:col-6 pr-0">
        <div class="col p-0">
            <div class="card p-fluid">
                <div class="flex justify-content-between flex-wrap">
                    <div class="flex justify-content-center mb-3">
                        <label class="card-heading uppercase text-lg" translate>lbl_geo_location</label>
                    </div>
                </div>
                <!-- Google map integration -->
                <div class="map-div" id="map"></div>
                <div class="grid mt-2">
                    <div class="col-12 pb-1">
                        <label class="detail-value font-semibold">
                            @if(attributeValues['city']) {
                                {{ attributeValues['city'] ? attributeValues['city'] : ('lbl_empty' | translate) }},
                            }
                            @if(attributeValues['state']) {
                                {{ attributeValues['state'] ? attributeValues['state'] : ('lbl_empty' | translate) }}
                            }
                        </label>
                    </div>
                </div>
                <div class="grid">
                    <div class="col-12 pb-1">
                        <label class="detail-heading">
                            @if(attributeValues['address_line_1']) {
                                {{ attributeValues['address_line_1'] ? attributeValues['address_line_1'] : ('lbl_empty' | translate) }},
                            }
                            @if(attributeValues['address_line_2']) {
                                {{ attributeValues['address_line_2'] ? attributeValues['address_line_2'] : ('lbl_empty' | translate) }},
                            }
                            @if(attributeValues['driver_address_landmark']) {
                                {{ attributeValues['driver_address_landmark'] ? attributeValues['driver_address_landmark'] : ('lbl_empty' | translate) }},
                            }
                            @if(attributeValues['city']) {
                                {{ attributeValues['city'] ? attributeValues['city'] : ('lbl_empty' | translate) }},
                            }
                            @if(attributeValues['state']) {
                                {{ attributeValues['state'] ? attributeValues['state'] : ('lbl_empty' | translate) }} -
                            }
                            @if(attributeValues['postal_code']) {
                                {{ attributeValues['postal_code'] ? attributeValues['postal_code'] : ('lbl_empty' | translate) }}
                            }
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="col p-0">
            <div class="card p-fluid">
                <div class="flex justify-content-between flex-wrap">
                    <div class="flex justify-content-center mb-3">
                        <label class="card-heading uppercase text-lg" translate>lbl_other_details</label>
                    </div>
                    <div appAccessProvider [accessCode]="{ menuCode: 'DRIVERS', subMenuCode: 'ALL_DRIVERS', pageCode: 'ALL', subPageCode: 'EDIT' }">
                        <div (click)="edit()" class="edit justify-content-center mb-3"  [ngClass]="{'hidden': isDeleted}">
                            <label class="card-action" translate>lbl_edit</label>
                            <i class="mi-lg edit_pencil"></i>
                        </div>
                    </div>
                </div>
                @for (field of otherDetails?.fields; track field; let i = $index) {
                    @if (i !== 0) {
                        <hr class="hr-line">
                    }
                    @if(field.attributeCode === 'types_of_vehicles_driven'){
                        <div class="grid">
                            <div class="col-6 pt-1">
                                <label class="detail-heading" translate>lbl_types_of_vehicles_driven</label>
                            </div>
                            <div class="col-6 pb-1">
                                <label class="detail-value">{{ attributeValues[field.attributeCode] ? (attributeValues[field.attributeCode] | commaSpace) : ('lbl_empty' | translate) }}</label>
                            </div>
                        </div>
                    }
                    @else {
                        <div class="grid">
                            <div class="col-6 pt-1">
                                <label class="detail-heading" translate>{{cs.getLabelValue('driver.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                            </div>
                            <div class="col-6 pb-1">
                                <label class="detail-value">{{ attributeValues[field.attributeCode] ? attributeValues[field.attributeCode] :  ('lbl_empty' | translate) }}</label>
                            </div>
                        </div>
                    }
                }
            </div>
        </div>
        <div class="col p-0">
            <div class="card p-fluid">
                <div class="flex justify-content-between flex-wrap">
                    <div class="flex justify-content-center mb-3">
                        <label class="card-heading uppercase text-lg" translate>lbl_background_check</label>
                    </div>
                    <div appAccessProvider [accessCode]="{ menuCode: 'DRIVERS', subMenuCode: 'ALL_DRIVERS', pageCode: 'ALL', subPageCode: 'EDIT' }">
                        <div (click)="edit()" class="edit justify-content-center mb-3"  [ngClass]="{'hidden': isDeleted}">
                            <label class="card-action" translate>lbl_edit</label>
                            <i class="mi-lg edit_pencil"></i>
                        </div>
                    </div>
                </div>
                @for (field of backgroundCheckInfo?.fields; track field; let i = $index) {
                    @if (i !== 0) {
                        <hr class="hr-line">
                    }
                    @if (field.attributeCode === 'driver_note') {
                        <div class="grid">
                            <div class="col-6 pt-1">
                                <label class="detail-heading" translate>{{cs.getLabelValue('driver.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                            </div>
                            <div class="col-6 pb-1">
                                <label class="detail-value">{{ attributeValues[field.attributeCode] ? attributeValues[field.attributeCode] :  ('lbl_empty' | translate) }}</label>
                            </div>
                        </div>
                    }
                    @else {
                        <div class="grid">
                            <div class="col-6 pt-1">
                                <label class="detail-heading" translate>{{cs.getLabelValue('driver.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                            </div>
                            <div class="col-6 pb-1">
                                <label class="detail-value">{{ attributeValues[field.attributeCode] ? attributeValues[field.attributeCode] :  ('lbl_empty' | translate) }}</label>
                            </div>
                        </div>
                    }
                }
            </div>
        </div>
        <div class="col p-0">
            <div class="card p-fluid">
                <div class="flex justify-content-between flex-wrap">
                    <div class="flex justify-content-center mb-3">
                        <label class="card-heading uppercase text-lg" translate>lbl_availability_preference</label>
                    </div>
                    <div appAccessProvider [accessCode]="{ menuCode: 'DRIVERS', subMenuCode: 'ALL_DRIVERS', pageCode: 'ALL', subPageCode: 'EDIT' }">
                        <div (click)="edit()" class="edit justify-content-center mb-3"  [ngClass]="{'hidden': isDeleted}">
                            <label class="card-action" translate>lbl_edit</label>
                            <i class="mi-lg edit_pencil"></i>
                        </div>
                    </div>
                </div>
                @for (field of availabilityPreference?.fields; track field; let i = $index) {
                    @if (i !== 0 && i !== 2) {
                        <hr class="hr-line">
                    }
                    @if (field.attributeCode === 'days_of_the_week_available') {
                        <div class="grid">
                            <div class="col-6 pt-1">
                                <label class="detail-heading" translate>{{cs.getLabelValue('driver.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                            </div>
                            <div class="col-6 pb-1">
                                <label class="detail-value">{{ dayNames }}</label>
                            </div>
                        </div>
                    }
                    @else if (field.attributeCode === 'start_time') {
                        <div class="grid">
                            <div class="col-6 pt-1">
                                <label class="detail-heading" translate>lbl_start_and_end_time</label>
                            </div>
                            <div class="col-6 pb-1">
                                @if (startTime && endTime) {
                                    <label class="detail-value">{{ startTime }} - {{ endTime }}</label>
                                }
                                @else {
                                    <label class="detail-value" translate>lbl_empty</label>
                                }
                            </div>
                        </div>
                    }
                    @else if (field.attributeCode === 'end_time') {
                        <div class="hidden">
                        </div>
                    }
                    @else {
                        <div class="grid">
                            <div class="col-6 pt-1">
                                <label class="detail-heading" translate>{{cs.getLabelValue('driver.fields.'+field.attributeCode+'.label',attributeLabels,field.attributeCode)}}</label>
                            </div>
                            <div class="col-6 pb-1">
                                <label class="detail-value">{{ attributeValues[field.attributeCode] ? attributeValues[field.attributeCode] :  ('lbl_empty' | translate) }}</label>
                            </div>
                        </div>
                    }
                }
            </div>
        </div>
        <div class="col p-0">
            <div class="card p-fluid">
                <div class="flex justify-content-between flex-wrap">
                    <div class="flex justify-content-center mb-3">
                        <label class="card-heading uppercase text-lg" translate>lbl_driving_records</label>
                    </div>
                    <div appAccessProvider [accessCode]="{ menuCode: 'DRIVERS', subMenuCode: 'ALL_DRIVERS', pageCode: 'ALL', subPageCode: 'EDIT' }">
                        <div (click)="edit()" class="edit justify-content-center mb-3"  [ngClass]="{'hidden': isDeleted}">
                            <label class="card-action" translate>lbl_edit</label>
                            <i class="mi-lg edit_pencil"></i>
                        </div>
                    </div>
                </div>
                @for (field of formattedDriverDrivingRecords; track field; let i = $index) {
                    <label class="detail-heading">{{ 'lbl_ddr_record' | translate }} {{ i + 1 }}</label>
                    <div class="grid mt-3">
                        <div class="col-6 pt-1">
                          <label class="detail-heading" translate>lbl_ddr_record</label>
                        </div>
                        <div class="col-6 pb-1">
                          <label class="detail-value">{{ field.Record ? field.Record : ('lbl_empty' | translate) }}</label>
                        </div>
                    </div>
                    <hr class="hr-line">
                    <div class="grid">
                        <div class="col-6 pt-1">
                          <label class="detail-heading" translate>lbl_ddr_date</label>
                        </div>
                        <div class="col-6 pb-1">
                          <label class="detail-value">{{ field.Date ? field.Date : ('lbl_empty' | translate) }}</label>
                        </div>
                    </div>
                    <hr class="hr-line">
                    <div class="grid">
                        <div class="col-6 pt-1">
                          <label class="detail-heading" translate>lbl_ddr_note</label>
                        </div>
                        <div class="col-6 pb-1">
                          <label class="detail-value">{{ field.Note ? field.Note : ('lbl_empty' | translate) }}</label>
                        </div>
                    </div>
                    <hr class="hr-line">
                }
            </div>
        </div>
    </div>
</div>
<hr class="ml-3 mr-3">
<div class="col-12 text-center">
    <p-button icon="pi pi-arrow-up" (click)="scrollToTop()" [iconPos]="iconPos" class="mr-2 border-600 bg-primary-reverse text-color" label="Back to Top"></p-button>
</div>
